import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Spinner } from '../../../../components/Spinner/Spinner';
import useBoatHook from '../../../../services/Boat/boatService';
import { currentApi } from '../../../../services/Db/dbInfo';

interface BoatInfoProps {
  show: boolean;
  id: number;
  onClose: any;
}
const BoatInfoModel = (props: BoatInfoProps) => {
  const { t } = useTranslation();

  const { onClose, show, id } = props;
  const { getBoat } = useBoatHook({ id });
  const { data: boatInfo, isLoading } = getBoat;
  const onHide = () => {
    if (onClose) onClose(false);
  };
  const images: ReactImageGalleryItem[] = boatInfo?.boatMedia?.url
    ? [{ original: `${currentApi.fileBasePath}${boatInfo?.boatMedia?.url}` }]
    : [];
  return (
    <>
      <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ width: '100%' }}>
            {t('boat.boatInfo')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '0px', lineHeight: 1.3, minHeight: '200px' }}>
          {!isLoading && boatInfo && (
            <>
              {images && images.length > 0 && (
                <ImageGallery
                  items={images}
                  lazyLoad={true}
                  showThumbnails={false}
                  showPlayButton={false}
                />
              )}
              <div className="modal-body mx-3">
                <div className="md-form">
                  <small>{t('boat.boatType')}:</small>
                  <small className="text-muted">
                    {t(`master_data.boat-type.${boatInfo?.boatType.value}`)}
                  </small>
                  <br />
                  <small>{t('boat.boatLength')}:</small>
                  <small className="text-muted">
                    {boatInfo.boatLength} {t('common.ft')}
                  </small>
                  <br />
                  <small>{t('boat.fields.boatManufacturer.label')}:</small>
                  <small className="text-muted">{boatInfo.boatManufacturer}</small>
                  <br />
                  <small>{t('boat.fields.guestCapacity.label')}:</small>
                  <small className="text-muted">
                    {boatInfo.guestCapacity} {t('common.guests')}
                  </small>
                  <br />
                </div>
                <div className="md-form mt-2">
                  {boatInfo?.typeofEngine && (
                    <>
                      <small>{t('boat.fields.typeofEngine.label')}:</small>
                      <small className="text-muted">
                        {t(`master_data.engine-type.${boatInfo?.typeofEngine?.value}`)}
                      </small>
                      <br />
                    </>
                  )}
                  <small>{t('boat.fields.extraMotor.label')}:</small>
                  <small className="text-muted">
                    {boatInfo.extraMotor ? t('common.yes') : t('common.no')}
                  </small>
                  <br />
                  <small>{t('boat.fields.engineManufacturer.label')}:</small>
                  <small className="text-muted">{boatInfo.engineManufacturer}</small>
                  <br />
                  <small>{t('boat.fields.numberofEngines.label')}:</small>
                  <small className="text-muted">{boatInfo.numberofEngines}</small>
                  <br />
                  <small>{t('boat.fields.horsePowerPerEngine.label')}:</small>
                  <small className="text-muted">{boatInfo.horsePowerPerEngine}</small>
                  <br />
                  <small>{t('boat.fields.maxCruisingSpeed.label')}:</small>
                  <small className="text-muted">
                    {boatInfo.maxCruisingSpeed} {t('common.knot')}
                  </small>
                  <br />
                </div>
                <div className="md-form mt-2">
                  <small>{t('boat.fields.navEquipments.label')}:</small>
                  <small className="text-muted">
                    {boatInfo?.navEquipments
                      ?.map((item: any) => t(`master_data.navigation-equipment.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('boat.fields.safetyEquipments.label')}:</small>
                  <small className="text-muted">
                    {boatInfo?.safetyEquipments
                      ?.map((item: any) => t(`master_data.safety-equipment.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('boat.fields.additionalCrew.label')}:</small>
                  <small className="text-muted">
                    {boatInfo?.additionalCrew
                      ?.map((item: any) => t(`master_data.crew.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('boat.fields.facilities.label')}:</small>
                  <small className="text-muted">
                    {boatInfo?.facilities
                      ?.map((item: any) => t(`master_data.facility.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('boat.fields.fishingGears.label')}:</small>
                  <small className="text-muted">
                    {boatInfo?.fishingGears
                      ?.map((item: any) => t(`master_data.fishing-gear.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                </div>
                <div className="md-form mt-2">
                  <small>{t('boat.fields.wheelchairAccessible.label')}:</small>
                  <small className="text-muted">
                    {`${boatInfo.wheelchairAccessible}` === 'true'
                      ? t('common.yes')
                      : t('common.no')}
                  </small>
                  <br />
                </div>
              </div>{' '}
            </>
          )}

          {isLoading && <Spinner />}
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default BoatInfoModel;
