import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SummaryCard from '../../components/News/SummaryCard';
import NoDataFound from '../../components/NoDataFound';
import { Spinner } from '../../components/Spinner/Spinner';
import { useGetNewsPost } from '../../services/News/newsService';

const tabStyle = {
  padding: '20px 0px 0px 0px',
};

function News() {
  const { t } = useTranslation();

  const [key, setKey] = useState<any>('latestNews');

  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };
  const { data: news, isLoading: isNewsLoading } = useGetNewsPost(fetchDataOptions);

  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="intro-news-tab">
            <div className="intro-news-filter justify-content-end">
              <h6 style={{position: 'absolute', marginTop: 16}}>NEWS</h6>
              <Tabs
                style={{ backgroundColor: 'white',}}
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className=''
              >
                <Tab className="nav-item nav-link" eventKey="latestNews" title={t('news.latestnews')} style={tabStyle}>
                  <div className="row">
                    {isNewsLoading && <Spinner />}
                    {!isNewsLoading && news?.data.length === 0 && <NoDataFound />}
                    {!isNewsLoading && news?.data && (
                      <>
                        {news?.data
                          .sort((a: any, b: any) => {
                            const updatedAtA = new Date(a.updatedAt).getTime();
                            const updatedAtB = new Date(b.updatedAt).getTime();
                            return updatedAtB - updatedAtA;
                          })
                          .map((item: any, index: number) => (
                            <SummaryCard key={item.id} {...item} />
                          ))}
                      </>
                    )}
                  </div>
                </Tab>
                <Tab className="nav-item nav-link" eventKey="chronicles" title={t('news.chronicles')} style={tabStyle}>
                  <div className="row">
                    {isNewsLoading && <Spinner />}
                    {!isNewsLoading &&
                      news?.data?.filter((item: any) => item.type === 'Chronicle').length === 0 && (
                        <NoDataFound />
                      )}
                    {!isNewsLoading && news?.data && (
                      <>
                        {news?.data
                          .filter((item: any) => item.type === 'Chronicle')
                          .map((item: any, index: number) => (
                            <SummaryCard {...item} />
                          ))}
                      </>
                    )}
                  </div>{' '}
                </Tab>
                {/* <Tab eventKey="mostPopular" title={t('news.mostpopular')} style={tabStyle}>
                        <div className="row">
                          {isNewsLoading && <Spinner />}
                          {!isNewsLoading && news?.data.length === 0 && <NoDataFound />}
                          {!isNewsLoading && news?.data && (
                            <>
                              {news?.data.map((item: any, index: number) => (
                                <SummaryCard {...item} />
                              ))}
                            </>
                          )}
                        </div>
                      </Tab> */}
                <Tab className="nav-item nav-link" eventKey="forOperators" title={t('news.operators')} style={tabStyle}>
                  <div className="row">
                    {isNewsLoading && <Spinner />}
                    {!isNewsLoading &&
                      news?.data?.filter((item: any) => item.for_operators).length === 0 && (
                        <NoDataFound />
                      )}
                    {!isNewsLoading && news?.data && (
                      <>
                        {news?.data
                          .filter((item: any) => item.for_operators)
                          .map((item: any, index: number) => (
                            <SummaryCard {...item} />
                          ))}
                      </>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
