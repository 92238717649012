/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorBox, ReviewForm, Reviews, ReviewsProvider } from '../../components/Ratings';
import SearchForm from '../../components/SearchForm';
import { logEvent } from '../../firebase/analytics';
import { currentApi } from '../../services/Db/dbInfo';
import { useGetTempCoverPic, useGetTripById } from '../../services/Search/searchService';
import RelatedTrip from './components/RelatedTrip';
import TripInfo from './components/TripInfo';
import './style.css';
import storage from '../../utils/storage';

function InfoPage() {
  const { t } = useTranslation();
  const { tripId } = useParams();
  const { data: trip, isLoading } = useGetTripById(Number(tripId));
  const { data: tempImage } = useGetTempCoverPic();
  const noOfPassenger = storage.getNoOfPassenger() || 1;

  useEffect(() => {
    logEvent('ViewTrip', { tripId: tripId });
  }, [tripId]);

  return (
    <ReviewsProvider apiURL={currentApi.serverURL}>
      {!isLoading && trip && (
        <div className="info-page" style={{ paddingBottom: '1rem' }}>
          <TripInfo trip={trip} tempCoverUrl={tempImage?.data.tempCoverPicture?.url}/>

          <div className="container" style={{ backgroundColor: '#FFF', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-sm-12 mt-4">
                <h4 className="text-center">{t('rating.ratings_reviews')}</h4>
              </div>
            </div>

            <div className="display_operator_photo shadow-sm m-3">
              <ReviewForm />
              <ErrorBox />
              <Reviews />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h4 className="display_search-header text-center">{t('common.your_search')}</h4>
                <div className="searchpage_yoursearch mx-auto mt-4 search-page">
                  <div className="row packages-form-header search-page__search">
                    <SearchForm
                      wrapperClass="container search-page__search__form"
                      showlabels={true}
                      inputWrapperClass="col-lg-4 col-md-6 col-sm-12"
                      buttonText="Change Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container text-center my-3">
            <h5 className="font-weight-light pb-3">{t('common.other_trip_search')}</h5>
            <RelatedTrip city={trip.city} countryCode={trip.country} noofpassanger={noOfPassenger} />
          </div>
        </div>
      )}
    </ReviewsProvider>
  );
}

export default InfoPage;
