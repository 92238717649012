import { useTranslation } from 'react-i18next';
import { useContainerContext } from '../../context/ContainerContext';
import { FormBoat } from './components/FormBoat';

const CreateUpdateBoat: React.FC<{ edit?: boolean }> = ({ edit = false }) => {
  const { t } = useTranslation();
  const { state } = useContainerContext();

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12" style={{ margin: 'auto' }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{edit ? t('boat.update_boat') : t('boat.create_boat')}</h5>
            </div>
            <div className="card-body">
              <FormBoat edit={edit} guideId={state.guideId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateBoat;
