import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../../lib/client';
import publicClient from '../../lib/publicClient';

import { ApiHandler } from '../Db/apiHandler';
import { currentApi } from '../Db/dbInfo';

const queryKey = 'upload';
function useUploadHook() {
  const queryClient = useQueryClient();

  const uploadFiles = useMutation(async (obj: any) => await ApiHandler.uploadFiles(obj), {
    retry: 0,
    onSuccess: () => queryClient.invalidateQueries([queryKey]),
  });

  const removeFile = useMutation(
    async (id: number) => await axios.delete(`${currentApi.uploadUriValue}/files/${id}`),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  return {
    uploadFiles,
    removeFile,
  };
}

const useGetFilesByFolderPath = (path: string) => {
  return useQuery<any>(
    [queryKey, path],
    async () => {
      return await publicClient.get(`/guides/getFiles/${path}`).then(function (response: any) {
        return response.data;
      });
    },
    { keepPreviousData: true, staleTime: 24 * 60 * 60 * 1000 }
  );
};

export { useGetFilesByFolderPath, useUploadHook };
