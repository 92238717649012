import { NavLink } from 'react-router-dom';
import moment from '../../../lib/moment';

function SummaryCard({ id, image, title, author_name, updatedAt, type }: any) {
  const cardPost = {
    zIndex: ' 1',
    transitionDuration: '500ms',
    overflow: 'hidden',
  };

  return (
    <div className="col-12 col-sm-6">
      <div className="single-blog-post style-2 mb-5" style={cardPost}>
        <div className="blog-thumbnail">
          <NavLink to={type === 'Chronicle' ? `/chronicle/${id}` : `/news/${id}`}>
            <img src={image?.url} alt="news" />
          </NavLink>
        </div>

        <div className="blog-content">
          <span className="post-date">{moment(updatedAt).format('LLLL')}</span>
          <NavLink to={type === 'Chronicle' ? `/chronicle/${id}` : `/news/${id}`} className="post-title text-capitalize">
            {type === 'Chronicle' ? `${type}: ${title}` : title}
          </NavLink>
          <NavLink to={type === 'Chronicle' ? `/chronicle/${id}` : `/news/${id}`} className="post-author text-capitalize">
            {`By ${author_name}`}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default SummaryCard;
