import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import './style.css';

function Footer(props: any) {
  return (
    <footer className={'footer' + (props.default ? ' footer-default' : '')}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul></ul>
          </nav>
          <div className="credits ml-auto">
            <div className="copyright">
              &copy; {new Date().getFullYear()}, {process.env.REACT_APP_SITE_NAME}
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
