import { useTranslation } from 'react-i18next';
import useOptionData from '../../../../lib/optionData';
import { TripDetails } from '../../../../services/Guide/guide';

interface TripInfoProps {
  index: number;
  tripInfo: TripDetails;
  updateHandler: any;
  previewHandler: any;
}

const TripSummary = (props: TripInfoProps) => {
  const { t } = useTranslation();
  const { index, tripInfo, updateHandler, previewHandler } = props;
  const { tripTypesOptions } = useOptionData();
  const tripType = tripTypesOptions.filter((obj) => obj.value === tripInfo.type)[0];

  return (
    <>
      <div key={index} className={index % 2 === 0 ? 'row eventable' : 'row oddtable'}>
        <div className="col-md-10 text-left">
          <p className="mb-0">
            {t('common.trip')} {index + 1} : {tripType?.label} - {tripInfo?.name}
          </p>
        </div>
        <div className="col-md-2 text-right">
          <p className="mb-0">
            <a
              href="#collapseInputBoat"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              title="edit"
              onClick={() => updateHandler(index)}
            >
              <i className="fa fa-edit preview-edit_link"></i>
            </a>

            <a
              href="#preview"
              title="preview"
              className="link-text-white"
              data-toggle="modal"
              data-target="#modalLoginForm"
              onClick={() => previewHandler(index)}
            >
              <i className="fa fa-eye preview-edit_link" aria-hidden="true"></i>
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default TripSummary;
