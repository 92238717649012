export interface baseApiInterface {
  getConversationUriValue: string;
  baseUriValue: string;
  serverURL: string;
  fileBasePath: string;
  loginUriValue: string;
  getUserUriValue: string;
  getGuideUriValue: string;
  createAcountUriValue: string;
  getGuideOptions: string;
  saveGuideDetails: string;
  uploadUriValue: string;
  getUser: string;
  getMe: string;
  user: string;
  boats: string;
  trips: string;
  getLocalization: string;
  forgotPassword: string;
  resetPassword: string;
  changePassword: string;
  tripSearches: {
    city: string;
    country: string;
  };
  conversationSearches: {
    buyer: string;
    seller: string;
    guidetrip: string;
  };
}

export const apiUrls: baseApiInterface = {
  baseUriValue: `${process.env.REACT_APP_SERVER_URL}/api`,
  serverURL: `${process.env.REACT_APP_SERVER_URL}`,
  fileBasePath: `${
    process.env.REACT_APP_FILE_BASE_PATH ? process.env.REACT_APP_FILE_BASE_PATH : ''
  }`,
  getLocalization: '/localizations',
  loginUriValue: '/auth/local',
  getGuideUriValue: '/guides',
  createAcountUriValue: '/auth/local/register',
  getUserUriValue: '/auth/local',
  getGuideOptions: '/guideoptions',
  getConversationUriValue: '/conversations',
  saveGuideDetails: '/guides',
  uploadUriValue: '/upload',
  getUser: '/users',
  getMe: '/users/me',
  user: '/users',
  boats: '/guide-boats',
  trips: '/guidetrips',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  changePassword: '/auth/change-password',
  tripSearches: {
    city: 'guidetrips.city_contains',
    country: 'guidetrips.country_contains',
  },
  conversationSearches: {
    buyer: 'buyer',
    seller: 'seller',
    guidetrip: 'guidetrip',
  },
};

export const currentApi = apiUrls;
