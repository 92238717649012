import { useQuery } from '@tanstack/react-query';
import axios from '../../lib/client';

interface Data {
  fields: string[];
  local: string[];
}

function filterData(data: Data, localization: string): Record<string, boolean> | null {
  if (!data) return null;
  const isMatch = data.local.includes(localization);
  if (isMatch) {
    const filteredFields: Record<string, boolean> = {};
    data.fields.forEach((field) => {
      filteredFields[field] = true;
    });
    return filteredFields;
  }
  return null;
}
const useGetTranslatedBy = (slug: string, fieldName: string, local: string, refId: number) => {
  return useQuery(
    ['TranslatedBy', slug, fieldName, local, refId],
    async () => {
      return await axios
        .get(`/getTranslatedBy/${slug}/${fieldName}/${local}/${refId}`)
        .then(function (response: any) {
          return response.data?.data;
        });
    },
    {
      enabled: !!refId && !!fieldName && !!local && !!slug,
      keepPreviousData: true,
      staleTime: 60 * 60 * 60 * 1000,
    }
  );
};

export { filterData, useGetTranslatedBy };
