import { BoatListContainer } from './boatListContainer';

const Boats = (args: any) => {
  const { fieldName, isVisible, setIsVisible } = args;

  return (
    <BoatListContainer
      key={fieldName}
      name={fieldName}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    />
  );
};

export default Boats;
