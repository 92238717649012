import React from 'react';
import SubscribeComponent from '../Guides/components/SubscribeComponent';

function SubscriptionSelection() {

  return (
    <div className="container">
        
      <section className="multi_step_form mt-5">
        <main>
        {/* <hr className="my-4" /> */}
        <SubscribeComponent />
        </main>
      </section>
    </div>
  );
}

export default SubscriptionSelection;
