import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import {
  useUpdateEmailPreference,
  useVerify,
} from '../../services/EmailPreference/emailPreferenceService';
import InvalidLink from '../Auth/InvalidLink';

const EmailPreferences = () => {
  const { t } = useTranslation();

  const { ivHex, encryptedData } = useParams();

  const { data, isLoading } = useVerify(ivHex, encryptedData);
  const { mutateAsync: mutateAsyncUpdateStatus } = useUpdateEmailPreference(ivHex, encryptedData);

  const onStatusChangeHandler = (event: any, field: string) => {
    const variables: any = {
      [field]: event.target.checked,
    };

    mutateAsyncUpdateStatus(variables, {
      onSuccess: () => {
        NotificationManager.success(t('notification.emailpreference.updated'));
      },
    });
  };

  if (data && !data.isValid) {
    return <InvalidLink />;
  }

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className="col-md-8 col-lg-6" style={{ margin: 'auto' }}>
          <div className="d-flex align-items-top py-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h4 className="text-center">E-mail preferences</h4>
                </div>
              </div>
              {data && data.isValid && (
                <>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-8 pt-4">
                      You're in control of what type of e-mails you would like us to send - simply
                      select the categories below to confirm your preferences.
                    </div>
                  </div>

                  <div className="row justify-content-md-center">
                    <div className="col-sm-8 pt-5">
                      <div className="review-block mx-3 shadow-sm card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-1">
                              <Form.Check
                                name="group1"
                                type={'checkbox'}
                                defaultChecked={data.send_newsletters}
                                onClick={(item) => onStatusChangeHandler(item, 'send_newsletters')}
                              />
                            </div>
                            <div className="col-11">
                              <div className="review-block-title">
                                <h6>Newsletters and promotions</h6>
                              </div>
                              <div className="review-block-description">
                                <p>
                                  On a monthly basis we send out a newsletter with all the latest
                                  news from Fishvoy. Sometimes along with some special offers from
                                  us or our partners. We promise we won't spam you with offers.
                                  Don't miss out on this!
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <Form.Check
                                name="group1"
                                type={'checkbox'}
                                defaultChecked={data.send_message_alerts}
                                onClick={(item) =>
                                  onStatusChangeHandler(item, 'send_message_alerts')
                                }
                              />
                            </div>
                            <div className="col-11">
                              <div className="review-block-title">
                                <h6>Message alerts</h6>
                              </div>
                              <div className="review-block-description">
                                <p>
                                  By default you get alerts when somebody sends you a message
                                  regarding a trip. If you want to turn this feature off you can
                                  simply uncheck the checkbox here.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <Form.Check
                                name="group1"
                                type={'checkbox'}
                                defaultChecked={data.send_system_messages}
                                onClick={(item) =>
                                  onStatusChangeHandler(item, 'send_system_messages')
                                }
                              />
                            </div>
                            <div className="col-11">
                              <div className="review-block-title">
                                <h6>System messages</h6>
                              </div>
                              <div className="review-block-description">
                                <p>
                                  Every now and then we might need to inform you about chenges in
                                  your account. Opt-out here if you don not want to receive them.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isLoading && <ButtonSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPreferences;
