import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';

import { useTranslation } from 'react-i18next';
import MapViewModelDialog from '../../components/MapViewModelDialog';
import SearchForm from '../../components/SearchForm';
import useSticky from '../../lib/useSticky';
import { TripDetails } from '../../services/Guide/guide';
import {
  searchTripQueryParam,
  sortByQueryParam,
  useSearchTrips,
} from '../../services/Search/searchService';
import FilterBox from './FilterBox';
import ListTrip from './ListTrip';
import { preloadImage } from '../../services/utils';
import { currentApi } from '../../services/Db/dbInfo';
import { uniqBy } from 'lodash';

function sortAndRandomize(_trips: any) {
  if (!_trips) {
    return _trips;
  }
  
  // console.log('_trips', _trips.length);
  
  // Step 1: Group guides into paying and non-paying
  const payingGuides = _trips.filter(
    (trip: any) => trip.guide.paying_member !== null && trip.guide.paying_member > 0
  );
  const nonPayingGuides = _trips.filter((trip: any) => !trip.guide.paying_member);

  // Step 2: Sort by ranking within each group
  const sortByRanking = (a: any, b: any) => a.guide.ranking - b.guide.ranking;

  payingGuides.sort(sortByRanking);
  nonPayingGuides.sort(sortByRanking);

  // Step 3: Randomize guides with the same ranking score
  const randomizeSameRank = (guideGroup: any) => {
    let i = 0;
    while (i < guideGroup.length) {
      // Find all guides with the same ranking score
      const sameRankGuides = guideGroup.filter(
        // eslint-disable-next-line no-loop-func
        (_trips: any) => _trips.guide.ranking === guideGroup[i].guide.ranking
      );

      if (sameRankGuides.length > 1) {
        // Shuffle the guides with the same ranking
        const shuffled = [...sameRankGuides].sort(() => Math.random() - 0.5);

        // Replace the section of the original array with the shuffled guides
        const startIndex = guideGroup.indexOf(sameRankGuides[0]);
        guideGroup.splice(startIndex, sameRankGuides.length, ...shuffled);
      }

      // Move index to the next ranking group
      i += sameRankGuides.length;
    }
  };

  randomizeSameRank(payingGuides);
  randomizeSameRank(nonPayingGuides);
  
  // Step 4: Merge paying and non-paying guides back together, paying guides first
  let sortedTrips = [...payingGuides, ...nonPayingGuides];

  // console.log('sortedTrips before uniqBy', sortedTrips.length);
  
  // Step 5: Use uniqBy to remove duplicates
  const uniqueSortedTrips = uniqBy(sortedTrips, 'id');
  
  // console.log('uniqueSortedTrips after uniqBy', uniqueSortedTrips.length);
  
  // Step 6: Ensure the length is maintained by adding any missing trips back
  const missingTripsCount = _trips.length - uniqueSortedTrips.length;
  
  if (missingTripsCount > 0) {
    const missingTrips = _trips.filter(
      (trip: any) => !uniqueSortedTrips.some((uniqueTrip: any) => uniqueTrip.id === trip.id)
    );

    // Randomly add back the missing trips to maintain the same length
    const shuffledMissingTrips = [...missingTrips].sort(() => Math.random() - 0.5).slice(0, missingTripsCount);
    
    sortedTrips = [...uniqueSortedTrips, ...shuffledMissingTrips];
  }

  // console.log('final sortedTrips', sortedTrips.length);

  return sortedTrips;
}

function GuideSearch() {
  const { t } = useTranslation();
  const { city, placeId, noofpassanger, countryCode, long, lat, minLat, minLng, maxLat, maxLng } =
    useParams();
  const [isfilterVisible, setIsFilterVisible] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [sortByTab, setSortByTab] = useState('recommended');
  const [filterTrip, setFilterTrip] = useState<number[]>([]);
  const [searchText, setSearchText] = useState('');
  const [initTrips, setInitTrips] = useState<any>([]);
  const [appliedFiletrs, setAppliedFiletrs] = useState<Array<any>>([]);
  const filterRef = useRef<any>();

  const [queryParam, setQueryParam] = useState(
    searchTripQueryParam({
      city: city,
      noOfPassenger: Number(noofpassanger),
      countryCode: countryCode,
    })
  );

  const [stickyRef, sticky] = useSticky<HTMLDivElement>(200);

  useLayoutEffect(() => {
    window.scrollTo({ top: 180, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setInitTrips([]);
    setQueryParam(
      searchTripQueryParam({
        city: city,
        countryCode: countryCode,
        noOfPassenger: Number(noofpassanger),
      })
    );
    window.scrollTo({ top: 180, behavior: 'smooth' });
  }, [noofpassanger, city, countryCode, placeId]);

  const onFilterChage = (
    search?: string,
    targetedSpecies?: any[],
    fishingTechniques?: any[],
    fishingTypes?: any[],
    sortBy?: string
  ) => {
    // const fishingTypes = fields.fishingTypes?.map((item: any) => Number(item.value)) || [];
    // const fishingTechniques =
    //   fields.fishingTechniques?.map((item: any) => Number(item.value)) || [];
    // const targetedSpecies =
    //   fields.targetedSpecies?.map((item: any) => Number(item.value)) || [];
    // const search = fields.search || '';
    const filters = [] as Array<any>;
    if (search) {
      filters.push({ type: 'search', value: search });
    }
    if (targetedSpecies) {
      targetedSpecies.forEach((species) => {
        filters.push({ type: 'targetedSpecies', ...species });
      });
    }
    if (fishingTechniques) {
      fishingTechniques.forEach((fishingTechnique) => {
        filters.push({ type: 'fishingTechniques', ...fishingTechnique });
      });
    }
    if (fishingTypes) {
      fishingTypes.forEach((fishingType) => {
        filters.push({ type: 'fishingTypes', ...fishingType });
      });
    }
    setAppliedFiletrs(filters);
    setQueryParam(
      searchTripQueryParam({
        city: city,
        countryCode: countryCode,
        noOfPassenger: Number(noofpassanger),
        search,
        fishingTechniques: fishingTechniques?.map((item: any) => Number(item.value)) || [],
        fishingTypes: fishingTypes?.map((item: any) => Number(item.value)) || [],
        targetedSpecies: targetedSpecies?.map((item: any) => Number(item.value)) || [],
        sortBy: sortBy,
      })
    );
  };

  const onSortChange = (sortBy: string) => {
    setSortByTab(sortBy);
    setQueryParam(sortByQueryParam(sortBy, queryParam));
  };

  const { data: trips, isLoading } = useSearchTrips(queryParam);

  useEffect(() => {
    const list =
      trips &&
      trips?.map((trip: TripDetails) => {
        if (trip.tripMedia) {
          trip.tripMedia.forEach((image) =>
            preloadImage(`${currentApi.fileBasePath + image.url}`).catch((e) => {
              // console.log('error', e);
            })
          );
        }
        return Number(trip.id);
      });
    setFilterTrip(list || []);
    if (!initTrips || initTrips.length === 0) {
      setInitTrips(trips);
    }
  }, [initTrips, trips]);

  const onSearchChange = (search: string) => {
    setSearchText(search);
  };

  const handleClearFilter = (filter: any) => {
    filterRef.current?.clearFilter(filter);
  };

  return (
    <div className="container-fluid mt-5">
      {/* <!-- YOUR SEARCH ROW START--> */}
      <div className="row">
        <div className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <h4 className=" text-center">{t('common.your_search')}</h4>

          <div className="searchpage_yoursearch mx-auto mt-4 search-page">
            <div className="row packages-form-header search-page__search">
              <SearchForm
                wrapperClass="container search-page__search__form"
                showlabels={true}
                inputWrapperClass="col-lg-4 col-md-6 col-sm-12 p-0"
                buttonText={t('common.search_change')}
                onSearchChange={onSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- YOUR SEARCH ROW SLUT--> */}
      <div className="row" id="123">
        <div
          className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1"
          style={{ paddingBottom: '0px' }}
        >
          <h2 className=" text-center" style={{ fontSize: 26 }}>
            {t(`common.search_result`)
              .replace('{length}', trips && trips.length > 0 ? trips.length.toString() : '0')
              .replace('{city}', searchText || placeId?.toString() || '')}
          </h2>
          <p id="result-1" className="hide">
            &nbsp;{' '}
          </p>
          <p className=" text-center">
            <a href="#changeSearch" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              &#8593; {t(`common.search_change`)}
            </a>
          </p>
        </div>
      </div>
      {appliedFiletrs.length > 0 && (
        <div className="row search-filter">
          <div className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
            <p className="note note-warning text-center">
              <strong>Note:</strong> You have two filters active. Use the filter panel below to the
              left to manage more filtering.
              <br />
              {appliedFiletrs.map((filter) => (
                <Fragment key={filter.type + filter.value}>
                  <button
                    className="btn btn-gold btn-closing"
                    onClick={() => handleClearFilter(filter)}
                  ></button>{' '}
                  {filter.label ?? filter.value}
                </Fragment>
              ))}
            </p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12 p-0">
          <main ref={stickyRef} className={`cd-main-content mt-4 ${sticky ? 'is-fixed' : ''}`}>
            <div className="cd-tab-filter-wrapper">
              <div className="cd-tab-filter">
                <ul className="cd-filters cd-filters-sort-by">
                  {t(`common.sort_by`)}:
                  <li className="placeholder">
                    <a data-type="all" href="#0">
                      {t('common.all')}
                    </a>
                  </li>
                  <li className="filter">
                    <a
                      className={sortByTab === 'recommended' ? 'selected' : ''}
                      href="#0"
                      data-type="all"
                      onClick={() => onSortChange('recommended')}
                    >
                      {t(`common.recommended`)}
                    </a>
                  </li>
                  <li className="filter" data-filter=".color-1">
                    <a
                      href="#0"
                      data-type="color-1"
                      onClick={() => onSortChange('rating')}
                      className={sortByTab === 'rating' ? 'selected' : ''}
                    >
                      {t(`common.review_score`)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={isfilterVisible ? 'cd-gallery filter-is-visible' : 'cd-gallery'}>
              <ListTrip isLoading={isLoading} trips={sortAndRandomize(trips)} />
            </div>

            <div className={isfilterVisible ? 'cd-filter filter-is-visible' : 'cd-filter'}>
              <FilterBox ref={filterRef} onChage={onFilterChage} searchData={initTrips} />
              <a
                href="#0"
                onClick={() => setIsFilterVisible(!isfilterVisible)}
                className="cd-close"
              >
                X
              </a>
            </div>

            <a
              href="#0"
              onClick={() => setIsFilterVisible(!isfilterVisible)}
              className={
                isfilterVisible ? 'cd-filter-trigger filter-is-visible' : 'cd-filter-trigger'
              }
            >
              <i className="fa fa-sliders-h"></i> {t(`common.filters`)}
            </a>
            <a
              href="#0"
              className="cd-filter-trigger2 text-uppercase"
              onClick={() => setMapView(true)}
            >
              <i className="fa fa-map-marker" aria-hidden="true"></i> {t('common.viewmap')}
            </a>
          </main>
        </div>
      </div>
      {mapView && trips && trips.length > 0 && (
        <MapViewModelDialog
          show={mapView}
          onClose={() => setMapView(false)}
          center={[long, lat]}
          bbox={[minLng, minLat, maxLng, maxLat]}
          filterTrip={filterTrip}
        />
      )}
    </div>
  );
}

export default GuideSearch;
