import { useState } from 'react';

const error = (msg: string): never => {
  throw new Error(msg);
};

interface Step {
  id?: string;
  autoAdvanceDuration?: number;
  [key: string]: any;
}

interface UseStepOptions {
  initialStep?: number | string;
  autoAdvanceDuration?: number;
  steps: Step[] | number;
}

const getIndexById = (arr: Step[], matchId: string | number): number =>
  arr.findIndex(({ id }) => id === matchId);

const useStep = ({
  initialStep = 0,
  autoAdvanceDuration: autoAdvanceDurationProp = 0,
  steps: stepsProp,
}: UseStepOptions) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!Array.isArray(stepsProp) && !Number.isInteger(stepsProp)) {
      error('useStep: You must specify either an array or an integer for `steps`');
    }
  }

  const steps: Step[] = typeof stepsProp === 'number' ? new Array(stepsProp).fill({}) : stepsProp;

  const initialStepIndex =
    typeof initialStep === 'number' ? initialStep : getIndexById(steps, initialStep);

  if (process.env.NODE_ENV !== 'production') {
    if (typeof initialStep === 'string' && initialStepIndex === -1) {
      error(`useStep: id of "${initialStep}" specified in initialStep not found in steps`);
    }
  }

  const [index, setStep] = useState<number>(initialStepIndex);

  const step: Step = steps[index];
  const { autoAdvanceDuration = autoAdvanceDurationProp } = step;

  const deltaSetStep = (delta: number = 1) => {
    setStep((index + steps.length + delta) % steps.length);
  };

  const navigation = {
    next: () => deltaSetStep(1),
    previous: () => deltaSetStep(-1),
    go: (newStep: number | string) => {
      if (typeof newStep === 'number') {
        if (process.env.NODE_ENV !== 'production') {
          if (newStep < 0 || newStep >= steps.length) {
            error(`useStep: Index out of range in go(${newStep})`);
          }
        }
        setStep(newStep);
      } else {
        const newStepId = getIndexById(steps, newStep);
        if (process.env.NODE_ENV !== 'production') {
          if (newStepId === -1) {
            error(`useStep: go("${newStep}") not found in steps`);
          }
        }
        setStep(newStepId);
      }
    },
  };

  return {
    autoAdvanceDuration,
    index,
    step,
    navigation,
  };
};

export default useStep;
