import './style.css';

import { Outlet, useSearchParams } from 'react-router-dom';
import Footer from './components/footer/';
import Header from './components/header';

function Layout() {
  const [searchParams] = useSearchParams();
  const preview = searchParams.get('preview');

  return (
    <div className="wrapper home-layout-container">
      {!preview && <Header />}
      <article>
        <Outlet />
      </article>
      {!preview && <Footer />}
    </div>
  );
}

export default Layout;
