import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../../../../lib/auth';
import './style.css';
import storage from '../../../../../utils/storage';
import { currentApi } from '../../../../../services/Db/dbInfo';
import { useContainerContext } from '../../../../../context/ContainerContext';
import useGuideHook from '../../../../../services/Guide/guideService';

function Sidebar(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userInfo } = useUser();
  const {
    state: { guideId },
  } = useContainerContext();
  const { t } = useTranslation();
  const isUserDisplayMode = storage.isUserDisplayMode();
  const {  getGuideProfile, } = useGuideHook({
    id: guideId,
  });
  const { data: guideDetail, isLoading } = getGuideProfile;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  const switchDisplayMode = () => {
    storage.switchDisplayMode(!isUserDisplayMode);
    if(location.pathname.indexOf('dashboard')>-1){
      window.location.reload();
    } else {
      navigate('/dashboard');
    }
  }

  let isGuide: boolean = false;
  if (userInfo) {
    isGuide = userInfo.isGuide;
    if (isUserDisplayMode) {
      isGuide = false;
    }
  }

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
      style={{ top: '95px' }}
    >
      {(isGuide || isUserDisplayMode) && !isLoading && (
        <div onClick={switchDisplayMode} className="logo" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}>
            <div className="logo-image-small">
              <img
                style={{ maxWidth: 34 }}
                src={`${currentApi.fileBasePath}${guideDetail?.guideProfile?.profilePhoto?.url}`}
                alt="Logo"
              />
            </div>
            <div className="simple-text logo-normal" style={{ paddingLeft: 12, flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {isGuide ? 'GUIDE' : 'YOU'}
              <i className="nc-icon nc-minimal-down"></i>
            </div>
        </div>
      )}
      <div className="sidebar-wrapper">
        <Nav>
          {props.routes.map((prop: any, key: number) => {
            if (!isGuide && prop.isGuide) return null;
            if (isGuide && prop.isUser) return null;
            return (
              <li className={activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')} key={key}>
                <NavLink to={prop.path} className="nav-NavLink">
                  <i
                    className={`${prop.icon} ${
                      activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')
                    }`}
                  />
                  <p
                    style={{ overflowWrap: 'break-word' }}
                    className={`${activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')}`}
                  >
                    {prop.name}
                  </p>
                </NavLink>
              </li>
            );
          })}
          <li className="active-pro">
            <a href="#Advertisement">
              <i className="nc-icon nc-spaceship"></i>
              <p>{t('nav.advertisement')} </p>
            </a>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;

