import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTranslatedBy } from '../../services/Common/service';
import { ButtonSpinner } from '../Spinner/ButtonSpinner';

interface Props {
  id: string;
  model: string;
  fieldName: string;
  className?: string;
}

const TranslatedByInfo: React.FC<Props> = ({
  id,
  fieldName,
  className = 'translatedBy',
  model,
}) => {
  const [translatedFields, setTranslatedFields] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data: getTranaslatedByInfo, isLoading: getTranaslatedByInfoLoading } = useGetTranslatedBy(
    model,
    fieldName,
    i18next.language,
    Number(id)
  );

  useEffect(() => {
    if (!getTranaslatedByInfoLoading) {
      setTranslatedFields(getTranaslatedByInfo ? true : false);
    }
  }, [getTranaslatedByInfo, getTranaslatedByInfoLoading]);

  if (!id) return <></>;

  return (
    <div className={className}>
      {getTranaslatedByInfoLoading && <ButtonSpinner />}
      {!getTranaslatedByInfoLoading && translatedFields && t('common.translated_by_google')}
    </div>
  );
};
export default TranslatedByInfo;
