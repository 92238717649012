// Import the necessary packages
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';
import { currentApi } from '../services/Db/dbInfo';

const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';

// Initialize i18next and configure it
i18next
  .use(ChainedBackend) // Use the i18next-http-backend to fetch translations
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            expirationTime: 24 * 60 * 60 * 1000,
            defaultVersion: { version: 'v1.3' },
          },
          {
            loadPath: `${currentApi.baseUriValue}${currentApi.getLocalization}?locale={{lng}}`,
            parse: function (data: any) {
              let JSONData = JSON.parse(data);
              const master_data: any = {};
              Object.keys(JSONData.master_data_array).forEach((parentObj: any) => {
                master_data[parentObj] = {};
                JSONData.master_data_array[parentObj].forEach((obj: any) => {
                  const key = Object.keys(obj)[0];
                  const value = obj[key];
                  master_data[parentObj][key] = value;
                });
              });
              JSONData.master_data = master_data;
              return JSONData;
            },
          },
        ],
      },
      fallbackLng: selectedLanguage, // Default language
      supportedLngs: ['en', 'sv', 'fr', 'de-DE'],
      interpolation: {
        escapeValue: false,
      },
      detection: {
        lookupLocalStorage: 'i18nextLng',
      },
    },
    function (err, t) {
      //console.log(i18next.language);
    }
  );

export default i18next;
