import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useForgotPassword } from '../../services/User/userService';

type ForgotPasswordProps = { email: string };

function ForgotPassword() {
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [emailSend, setEmailSend] = React.useState(false);
  const refRecaptcha = React.useRef<ReCAPTCHA>(null);
  const { t } = useTranslation();
  const { mutateAsync: mutateAsyncPost, isError } = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ForgotPasswordProps>();

  const onSubmit = handleSubmit((forgotPasswordProps: ForgotPasswordProps) => {
    if (refRecaptcha && refRecaptcha.current) {
      try {
        refRecaptcha.current.execute();
        mutateAsyncPost(forgotPasswordProps, {
          onSuccess: (data: any) => {
            setEmailSend(true);
          },
          onError: (error: any) => {
            if (error?.response?.data?.message) {
              setError('email', {
                type: 'manual',
                message: error.message,
              });
            }
          },
        });
      } catch (ex) {
        refRecaptcha.current.reset();
      }
    }
  });

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className={`d-none d-md-flex col-md-4 col-lg-6 register-bg-image1`}></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                {' '}
                {!emailSend && (
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mt-5 mb-4">{t('forgot_password_page.heading')}</h2>
                    {isError && (
                      <div className="alert alert-danger" role="alert">
                        {t('validation.email_already_register')}
                      </div>
                    )}
                    <form
                      id="msform"
                      onSubmit={onSubmit}
                      className={`needs-validation ${errors.email?.message ? 'was-validated' : ''}`}
                    >
                      <div className="form-label-group">
                        <input
                          type="email"
                          id="inputEmail"
                          {...register('email', {
                            required: t('validators.mixed.required'),
                          })}
                          className={`form-control ${errors.email?.message ? 'is-invalid' : ''}`}
                          placeholder={t('user.fields.email.placeholder')}
                          required
                          autoFocus
                        />
                        <label htmlFor="inputEmail">{t('user.fields.email.label')}</label>
                      </div>
                      <ReCAPTCHA
                        ref={refRecaptcha}
                        sitekey={`${process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}`}
                        onChange={(value) => {
                          if (value === null) setSubmitDisabled(true);
                        }}
                        asyncScriptOnLoad={() => {
                          setSubmitDisabled(false);
                        }}
                        size={
                          process.env.REACT_APP_RECAPTCHA_SIZE === 'normal' ? 'normal' : 'invisible'
                        }
                      />
                      <button
                        disabled={submitDisabled}
                        className="btn mt-2 btn-gold mb-2"
                        type="submit"
                      >
                        {t('common.send_email')}
                      </button>
                      <hr className="my-4" />
                    </form>
                  </div>
                )}
                {emailSend && (
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mt-5 mb-4">
                      {t('forgot_password_page.success_heading')}
                    </h2>
                    <p>{t('forgot_password_page.success_description')}</p>

                    <a className="sc-fvxzrP oyRxl" href="/signin">
                      <span className="sc-dkPtRN cvgrtB">{`${t('common.signin').toString()}`}</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
