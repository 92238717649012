import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReviewsContext from '../ReviewsProvider';
import { StarPicker } from '../StarsRating';
import { Modal } from 'react-bootstrap';
import SignIn from '../../../../screens/SignIn';
import storage from '../../../../utils/storage';

export interface ReviewFormProps {
  label?: string;
}

const ReviewForm = (props: ReviewFormProps) => {
  const { t } = useTranslation();
  const { user, userReview, postReview, canPostReview } = useContext(ReviewsContext);
  const [comment, setComment] = useState('');
  const [title, setTitle] = useState('');
  const [show, setShowDialog] = useState(false);

  const [score, setScore] = useState(5);
  const [sending, setSending] = useState(false);

  const handleInputTitle = (e: any) => {
    setTitle(e.currentTarget.value);
  };
  const handleInputComment = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };
  const handleInputScore = (newScore: any) => {
    setScore(newScore);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      setShowDialog(true);
      storage.addPendingReview({ title, comment, score, redirectUrl: window.location.href });
      return;
    }
    setSending(true);
    await postReview(title, comment, score);
    setSending(false);
  };

  const onHide = () => setShowDialog(false);

  useEffect(() => {
    const post_a_review = async () => {
      const pendingReview = storage.getPendingReview();
      
      if (pendingReview) {
        setSending(true);
        await postReview(pendingReview.title, pendingReview.comment, pendingReview.score);
        storage.clearPendingReview();
        setSending(false);
        // window.location.reload();
      }
    };  
    if (user) {
      post_a_review();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user?.id]);

  // if (!user) {
  //   return <div className="py-2"></div>;
  // }
  if (!canPostReview) {
    return <></>;
  }
  if (userReview !== null) {
    return null;
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="py-3">
          <p className="fs-4">{props.label || t('rating.post_a_review')}</p>
          <div className="d-flex flex-column mb-1">
            <div>
              <p className="fw-bold small mb-1">
                {t('rating.your_score')}: {score}/5
              </p>
            </div>
            <StarPicker
              disabled={false}
              halfStars={false}
              value={score}
              onChange={handleInputScore}
            />
          </div>
          <div className="d-flex flex-column mb-1">
            <label>
              <p className="fw-bold small mb-1"> {t('rating.title')}</p>
              <input
                className="form-control"
                type="text"
                onChange={(e) => handleInputTitle(e)}
                placeholder={t('rating.type_a_title')}
                name="title"
                value={title}
              />
            </label>
            <label>
              <p className="fw-bold small mb-1">{t('rating.comment')}</p>
              <textarea
                className="form-control"
                rows={3}
                onChange={handleInputComment}
                placeholder={t('rating.type_a_comment')}
                name="content"
                value={comment}
              ></textarea>
            </label>
          </div>
          <div className="pt-1">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={score < 1 || sending ? true : undefined}
            >
              {t('common.submit')}
            </button>
          </div>
        </div>
      </form>
      <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ width: '100%' }}>
            {t('common.signin')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '0px', lineHeight: 1.3, minHeight: '150px' }}>
          <SignIn
            isModel={true}
            onSuccess={async () => {
              // console.log('caaled SignIn');
              setShowDialog(false);
              // storage.clearPendingReview();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewForm;
