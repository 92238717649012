import './style.css';

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useLogin, useLogout } from '../../lib/auth';
import { currentApi } from '../../services/Db/dbInfo';
import { isEmpty } from '../../services/utils';

type LoginFormData = { email: string; password: string; rememberMe?: boolean };

function SignIn(props: any) {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const { data: userInfo, isLoading, isError, error, mutate: login } = useLogin();
  const { mutate: logOutUser } = useLogout();
  let [searchParams] = useSearchParams();
  const refRecaptcha = React.useRef<ReCAPTCHA>(null);
  const pathName = searchParams.get('next') || '';
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  const onSubmit = handleSubmit((loginRequest: LoginFormData) => {
    if (refRecaptcha && refRecaptcha.current) {
      try {
        refRecaptcha.current.execute();
        login({
          email: loginRequest.email,
          password: loginRequest.password,
        });
        
        
        if(props.isModel && props.onSuccess){
          console.log('props', props);
          props.onSuccess();
        }
      } catch (ex) {
        refRecaptcha.current.reset();
      }
    }
  });

  const goToRegister = () => {
    if (pathName) {
      navigate('/register?next=' + pathName);
    } else {
      navigate('/register');
    }
  };

  const logout = () => {
    logOutUser({});
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        {!props.isModel && <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image register-bg-image1"></div>}
        <div className={props.isModel ? "col-md-12 col-lg-12" :"col-md-8 col-lg-6"}>
          <div className={`login d-flex align-items-center ${props.isModel? '' :'py-5'}`}>
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <h3 className="login-heading mb-4">{t('login_page.heading')}</h3>
                  {isEmpty(userInfo) && (
                    <form
                      onSubmit={onSubmit}
                      className={`needs-validation ${
                        errors.email?.message || errors.password?.message ? 'was-validated' : ''
                      }`}
                    >
                      <div className="form-label-group has-validation">
                        <input
                          id="inputEmail"
                          type="email"
                          {...register('email', {
                            required: t('validators.mixed.required'),
                          })}
                          className={`form-control ${errors.email?.message ? 'is-invalid' : ''}`}
                          placeholder={t('user.contact.email').toString()}
                        />
                        <label htmlFor="inputEmail">{t('login_page.email_label')}</label>
                        {errors.email && (
                          <div className="invalid-feedback">{errors.email.message}</div>
                        )}
                      </div>
                      <div className="form-label-group has-validation">
                        <input
                          id="inputPassword"
                          type="password"
                          {...register('password', {
                            required: t('validators.mixed.required'),
                          })}
                          className={`form-control ${errors.password?.message ? 'is-invalid' : ''}`}
                          placeholder={t('user.password').toString()}
                        />
                        <label htmlFor="inputPassword">{t('login_page.password_label')}</label>
                        {errors.password && (
                          <div className="invalid-feedback">{errors.password.message}</div>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="rememberMe"
                            />
                            <label className="form-check-label" htmlFor="rememberMe">
                              {t('login_page.remember_password_checkbox')}
                            </label>
                          </div>
                      
                          <a className="sc-fvxzrP oyRxl" href="/forgot-password">
                            <span className="sc-dkPtRN cvgrtB">
                              {t('common.forgot_your_password').toString()}
                            </span>
                          </a>
                      </div>
                      <div>
                        <ReCAPTCHA
                          ref={refRecaptcha}
                          sitekey={`${process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}`}
                          onChange={(value) => {
                            if (value === null) setSubmitDisabled(true);
                          }}
                          asyncScriptOnLoad={() => {
                            setSubmitDisabled(false);
                          }}
                          size={
                            process.env.REACT_APP_RECAPTCHA_SIZE === 'normal'
                              ? 'normal'
                              : 'invisible'
                          }
                        />
                      </div>
                      {isError && (
                        <div
                          style={{
                            display: 'block',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          {error?.message}
                        </div>
                      )}
                      <button
                          className="btn mt-2 btn-gold mb-2"
                          type="submit"
                          disabled={isLoading || submitDisabled}
                        >
                          {isLoading ? (
                            <ButtonSpinner message={`${t('common.signin').toString()}`} />
                          ) : (
                            t('common.signin').toString()
                          )}
                      </button>

                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="btn btn-text mb-2"
                          onClick={goToRegister}
                        >
                         {t('common.register_prefix').toString()} {t('common.register').toString()}
                        </button>
                      </div>
                      <hr className="my-4" />
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-google btn-block "
                          type="button"
                          onClick={() =>
                            (window.location.href = `${currentApi.baseUriValue}/connect/google`)
                          }
                        >
                          <i className="fab fa-google mr-2"></i>{' '}
                          {t('login_page.login_with_google_button')}
                        </button>
                      </div>
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-facebook btn-block "
                          type="button"
                          onClick={() =>
                            (window.location.href = `${currentApi.baseUriValue}/connect/facebook`)
                          }
                        >
                          <i className="fab fa-facebook mr-2"></i>{' '}
                          {t('login_page.login_with_facebook_button')}
                        </button>
                      </div>
                    </form>
                  )}

                  {!isEmpty(props.user) && (
                    <section className="signin_signInSection">
                      <div className="container">
                        <div className="signin_userInfo">
                          <div className="signin_userInfo_avatar"></div>
                          <div className="signin_userInfo_name">{userInfo?.displayName}</div>
                        </div>
                        <button
                          type="button"
                          value={t('common.logout').toString()}
                          className="btn btn-gold"
                          onClick={logout}
                        />
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
