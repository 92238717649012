import { useMutation, useQuery } from '@tanstack/react-query';
import axios from '../../lib/client';
import publicClient from '../../lib/publicClient';
import { queryClient } from '../../lib/react-query';

const queryKey = ['EmailPreference'];

const useVerify = (ivHex: any, encryptedData: any) => {
  return useQuery(queryKey, async () => {
    return await publicClient
      .get(`/email-preference/verify/${ivHex}/${encryptedData}`)
      .then(function (response: any) {
        return response?.data;
      });
  });
};

const useGetEmailPreference = () => {
  return useQuery(queryKey, async () => {
    return await axios.get(`/email-preference/getEmailPreference`).then(function (response: any) {
      return response?.data;
    });
  });
};

const useUpdate = () => {
  return useMutation<any, Error, any>(
    async ({ id, variables }) => {
      await axios
        .put(`/email-preference/${id}`, {
          data: variables,
        })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

const useUpdateEmailPreference = (ivHex: any, encryptedData: any) => {
  return useMutation<any, Error, any>(
    async (variables) => {
      await publicClient
        .put(`/email-preference/updateEmailPreference/${ivHex}/${encryptedData}`, {
          data: variables,
        })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
    }
  );
};

const useSubscribeNewsletter = () => {
  return useMutation<any, Error, any>(
    async (variables) => {
      await publicClient
        .post(`/email-preferences`, {
          data: variables,
        })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
    }
  );
};

export { useGetEmailPreference, useUpdate, useUpdateEmailPreference, useVerify, useSubscribeNewsletter };
