import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { currentApi } from '../../../services/Db/dbInfo';

const FavoriteCardList = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();

  return (
    <>
      {data &&
        data.map((faviroite: any, index: number) => (
          <Col md="4" sm="12" key={faviroite.id}>
            <div className="card ">
              <div className="card-header "><h5>{faviroite?.guidetrip?.name}</h5></div>
              <div className="card-body">
                <div id="image1" className="image">
                  <img
                    onClick={() => {
                      navigate(`/trip-info/${faviroite?.guidetrip?.id}`);
                    }}
                    src={`${currentApi.fileBasePath}${faviroite?.guidetrip?.tripMedia[0]?.url}`}
                    className="img-fluid cursor-pointer"
                    alt="card"
                  />
                </div>
              </div>
            </div>{' '}
          </Col>
        ))}
    </>
  );
};

export default FavoriteCardList;
