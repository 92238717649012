import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import moment from '../../../lib/moment';
import { useGetBookMarkList } from '../../../services/Bookmark/bookmarkService';
import { useGetUserUnreadMessageCount } from '../../../services/Message/messageService';
import FavoriteCardList from '../../Favorites/components/FavoriteCardList';

// core components
function UserDashboard() {
  const { t } = useTranslation();
  const fetchDataOptions = {
    pageIndex: 0,
    pageSize: 5,
  };
  const { data, isLoading: isMessageCountLoading } = useGetUserUnreadMessageCount();
  const { data: favorites, isLoading: isFavoritesLoading } = useGetBookMarkList(fetchDataOptions);

  if (isMessageCountLoading) return <ButtonSpinner />;

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="4" md="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-email-85 text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('conversations.unread_messages')}</p>
                      <Card.Title as="p">
                        {isMessageCountLoading ? <ButtonSpinner /> : data?.count}
                      </Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr />
                {data?.lastMessageDate && (
                  <div className="stats">
                    <i className="far fa-clock" />{' '}
                    {` In the last ${moment(data?.lastMessageDate)
                      .toNow(true)
                      .replace('a few', '')}`}
                  </div>
                )}
              </Card.Footer>
            </Card>
          </Col>

          <Col lg="4" md="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('common.favorites')}</p>
                      <Card.Title as="p">
                        {!isFavoritesLoading &&
                          favorites &&
                          favorites?.meta &&
                          favorites?.meta.pagination.total}
                      </Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr />
                <div className="stats">
                  <i className="far fa-refresh"></i>
                  {t('common.update_now')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row>
          {!isFavoritesLoading && favorites && favorites?.data && (
            <FavoriteCardList data={favorites?.data} />
          )}
          {isFavoritesLoading && <ButtonSpinner />}
        </Row>
      </div>
    </>
  );
}

export default UserDashboard;
