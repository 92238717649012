import moment from '../../../../lib/moment';
import { getEmailInitials } from '../../../../services/utils';
import { IReview } from '../ReviewsProvider/ReviewsProvider';
import StarPicker from '../StarsRating';

export interface ReviewProps {
  data: IReview;
}

const Review = ({ data }: ReviewProps) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-3 my-auto">
          <span
            className="initial-avatar rounded-circle d-flex align-self-start shadow-1-strong"
            style={{ height: '50px', width: '50px' }}
          >
            {data.user ? getEmailInitials(data.user.username) : 'User'}
          </span>
          <div className="review-block-name">
            {data.user ? getEmailInitials(data.user.username) : 'User'}
          </div>
          <div className="review-block-date">
            {moment(data.createdAt).format('LL')}
            <br />
            {moment(data.createdAt).fromNow()}
          </div>
        </div>
        <div className="col-sm-9">
          <div className="review-block-rate">
            <StarPicker disabled={true} halfStars={true} value={data.score} />
          </div>
          <div className="review-block-title">
            <h6>{data.title}</h6>
          </div>
          <div className="review-block-description">
            <p>{data.comment}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
