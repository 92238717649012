import { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchForm from '../../components/SearchForm';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { currentApi } from '../../services/Db/dbInfo';
import { useGetFilesByFolderPath } from '../../services/Upload/uploadService';
import './carousel.min.css'; // requires a loader
import './style.css';
import { preloadImage } from '../../services/utils';

function Home() {
  const { t } = useTranslation();
  const { data: homeImages, isLoading } = useGetFilesByFolderPath('LandingPage');
  const [images, setImages] = useState<any>([
    {
      url: 'https://storage.googleapis.com/fishvoy_bucket_demo/splash1_f9a3f6a065/splash1_f9a3f6a065.jpg',
    },
    {
      url: 'https://storage.googleapis.com/fishvoy_bucket_demo/splash2_b222d0475a/splash2_b222d0475a.jpg',
    },
    {
      url: 'https://storage.googleapis.com/fishvoy_bucket_demo/splash3_accdf3fcd7/splash3_accdf3fcd7.jpg',
    },
  ]);

  function customT(key: string, options: any) {
    let translation = t(key, options);
    if (translation === key) {
      if (key.includes('header')) translation = t('home_page.slide.1.header');
      else if (key.includes('paragraph')) translation = t('home_page.slide.1.paragraph');
      else translation = '';
    }
    return translation.toString();
  }

  useEffect(() => {
    if (homeImages && homeImages.length > 0) {
      homeImages.forEach((image: any) => { // preload images
        preloadImage(`${currentApi.fileBasePath + image.url}`).catch((e)=>{
         // console.log("error", e);
        });
      });
    }
    setImages(homeImages);
  }, [homeImages]);

  return (
    <div className="landing-page">
      {isLoading && <ButtonSpinner />}
      {!isLoading && (
        <section className="landing-page__section-one">
          <Carousel controls={false} indicators={false} className="carousel slide">
            {images &&
              images.length > 0 &&
              images.map((image: any, index: number) => (
                <Carousel.Item
                  key={`Carousel_${index}`}
                  interval={5000}
                  style={{
                    backgroundImage: `url('${currentApi.fileBasePath + image.url}')`,
                  }}
                >
                  <Carousel.Caption className="d-md-block">
                    <h2 className="display-4 carousel-header">
                      {customT(`home_page.slide.${index + 1}.header`, { returnEmptyString: true })}
                    </h2>
                    <p className="lead carousel-sub-header">
                      {customT(`home_page.slide.${index + 1}.paragraph`, {
                        returnEmptyString: true,
                      })}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
          </Carousel>

          <div className="landing-page__search">
            <SearchForm
              wrapperClass="container landing-page__search__form"
              showlabels={true}
              inputWrapperClass="col-lg-4 col-md-6 col-sm-12 p-0"
              buttonText={t('common.search')}
            />
          </div>
        </section>
      )}
    </div>
  );
}

export default Home;
