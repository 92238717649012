import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { confirmBox } from '../../components/ConfirmBox';
import Datatable from '../../components/Datatable';
import { useContainerContext } from '../../context/ContainerContext';
import useBoatHook from '../../services/Boat/boatService';
import BoatInfoModel from '../Guides/components/Boats/boatInfoModel';

interface IData {
  id: number;
  boatManufacturer: string;
  boatType: any;
  boatLength: number;
}
const columnHelper = createColumnHelper<IData>();

function Boats() {
  const { t } = useTranslation();
  const { state } = useContainerContext();
  const navigate = useNavigate();
  const [viewModel, setViewModel] = useState<{
    show: boolean;
    id?: number;
  }>({ show: false });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchDataOptions = {
    pageIndex,
    pageSize,
    guideId: state.guideId,
  };

  const { getBoatsByGuideId, deleteBoat } = useBoatHook(fetchDataOptions);

  const { isSuccess: isSuccessDelete, mutateAsync: mutateAsyncDelete } = deleteBoat;

  useEffect(() => {
    if (isSuccessDelete) {
      getBoatsByGuideId.refetch();
      NotificationManager.success(t('notification.boat.delete'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete]);

  const columns = [
    columnHelper.accessor((row) => row?.boatType?.id, {
      id: 'boatType',
      cell: (info) => <i>{t(`master_data.boat-type.${info.getValue()}`)}</i>,
      header: () => <span>{t('boat.type')}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row?.boatManufacturer, {
      id: 'boatManufacturer',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>{t('boat.manufacturer')}</span>,
    }),

    columnHelper.accessor((row) => row?.boatLength, {
      id: 'boatLength',
      cell: (info) => <i>{`${info.getValue()} ${t('common.feet')}`}</i>,
      header: () => <span>{t('common.length')}</span>,
    }),

    columnHelper.display({
      id: 'actions',
      cell: (props) => (
        <div className="text-right">
          <a
            href="#edit"
            onClick={() => {
              navigate(`/boats/update/${props.row.original.id}`);
            }}
          >
            {t('common.edit')}
          </a>
          {' | '}
          <a
            href="#delete"
            onClick={() => {
              confirmBox({
                title: `${t('boat.deleteBoat')}`,
                message: `${t('boat.deleteBoatMessage')}`,
                buttons: [
                  {
                    label: `${t('common.yes')}`,
                    onClick: () => mutateAsyncDelete(props.row.original.id),
                  },
                  {
                    label: `${t('common.no')}`,
                  },
                ],
              });
            }}
          >
            {t('common.delete')}
          </a>
          {' | '}
          <a
            href="#view"
            onClick={() => setViewModel({ show: true, id: Number(props.row.original.id) })}
          >
            {t('common.view')}
          </a>
        </div>
      ),
      header: () => <div className="text-right"> {t('common.action')}</div>,
    }),
  ];

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-plain">
            <div className="card-header">
              <h5 className="card-title">{t('nav.myBoats')}</h5>
              <div
                className="card-category"
                style={{ float: 'right' }}
                onClick={() => {
                  navigate('/boats/create');
                }}
              >
                <button type="button" className="btn btn-gold btn-circle btn-xl">
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <Datatable
                dataQuery={getBoatsByGuideId}
                tableColumns={columns}
                pagination={pagination}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
      {viewModel.show && viewModel.id && (
        <BoatInfoModel
          show={viewModel.show}
          id={Number(viewModel?.id)}
          onClose={() => setViewModel({ show: false })}
        />
      )}
    </div>
  );
}

export default Boats;
