import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../lib/auth';
import { useGetBookMarkBySlug, useSaveBookMark } from '../../services/Bookmark/bookmarkService';
import { ButtonSpinner } from '../Spinner/ButtonSpinner';

interface BookmarkProps {
  tripId: number;
}
const Bookmark = (props: BookmarkProps) => {
  const { t } = useTranslation();
  const { data: userInfo, isLoading: isLoadingUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  if (userInfo) {
    return <BookmarkInfo tripId={props.tripId} />;
  }

  if (isLoadingUser) {
    return <ButtonSpinner />;
  }
  const handelBookmark = () => {
    navigate(`/signin?next=${location.pathname}/${location?.search}`);
  };

  return (
    <>
      <label htmlFor="save" title="SAVE" className="label entypo-export2" onClick={handelBookmark}>
        <i className="fa fa-heart" aria-hidden="true"></i> {t('common.save')}
      </label>
    </>
  );
};

const BookmarkInfo = (props: BookmarkProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBookMarkBySlug(props.tripId);
  const { mutateAsync: mutateAsyncPost, isLoading: mutateAsyncPostLoading } = useSaveBookMark();

  const onSaved = () => {
    if (!data) {
      const request = { tripId: props.tripId };
      mutateAsyncPost(request, {
        onSuccess: () => {},
      });
    }
  };

  if (isLoading || mutateAsyncPostLoading) {
    return (
      <>
        <label htmlFor="save" title="SAVE" className="label entypo-export2" onClick={onSaved}>
          <ButtonSpinner message="" />
        </label>
      </>
    );
  }

  return (
    <>
      <label htmlFor="save" title="SAVE" className="label entypo-export2" onClick={onSaved}>
        <i className="fa fa-heart" aria-hidden="true"></i>{' '}
        {data === true ? t('common.saved') : t('common.save')}
      </label>
    </>
  );
};
export default Bookmark;
