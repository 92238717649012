import ChangePassword from '../Profile/component/ChangePassword';
import UserSetting from '../Profile/component/UserSetting';

const Setting = () => {
  return (
    <div className="content">
      <div className="row">
        <div className="col-md-4">
          <UserSetting />
        </div>
        <div className="col-md-4">
          <ChangePassword />
        </div>
      </div>
    </div>
  );
};

export default Setting;
