import { useMutation, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import axios from '../../lib/client';

import { queryClient } from '../../lib/react-query';

const queryKey = 'BookMark';

interface IPage {
  pageIndex: number;
  pageSize: number;
}

const useGetBookMarkList = (fetchDataOptions: IPage) => {
  return useQuery(
    [queryKey, fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          populate: {
            guidetrip: {
              fields: ['id', 'name'],
              populate: {
                tripMedia: {
                  fields: ['url'],
                },
              },
            },
          },
          pagination: {
            pageSize: `${fetchDataOptions.pageSize}`,
            page: `${fetchDataOptions.pageIndex}`,
          },
        },
        { encode: false }
      );

      return await axios.get(`/favorites?${queryParams}`).then(function (response: any) {
        return response.data;
      });
    },
    { keepPreviousData: true }
  );
};

const useSaveBookMark = () => {
  return useMutation<any, Error, any>(
    async ({ tripId }) => {
      await axios
        .post(`/favorites`, { data: { guidetrip: tripId } })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['BookMark']);
      },
    }
  );
};

const useGetBookMarkBySlug = (tripId: number) => {
  return useQuery(
    [queryKey, tripId],
    async () => {
      return await axios.get(`/favorites/${tripId}/get`).then(function (response: any) {
        return response.data;
      });
    },
    { enabled: !!tripId }
  );
};

export { useGetBookMarkBySlug, useGetBookMarkList, useSaveBookMark };
