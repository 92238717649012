import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import axios from '../../lib/client';
import { currentApi } from '../Db/dbInfo';

const queryKey = ['UserSubscription'];

export const useGetUserSubscription = (options: any, userEmail?: string) => {
  return useQuery(
    [queryKey, userEmail],
    (): any => {
      return axios.get(`/stripe-callback/search?email=${userEmail}`).then(function (response: any) {
        return response.data;
      });
    },
    options
  );
};

export const productCheckout = (productId: string, userEmail?: string) => {

  localStorage.setItem('strapiStripeUrl', currentApi.serverURL);
  const getRedirectUrl = currentApi.serverURL + '/strapi-stripe/getRedirectUrl/' + productId + '/' + userEmail;

  fetch(getRedirectUrl, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);

      if (response.url) {
        window.location.replace(response.url);
      }
    });
};

export const useGetProducts = (productId?: string) => {
  return useQuery(['Products-Subscriptions', productId], (): any => {
    return axios.get("/stripe-callback/products").then(function (response: any) {
      return response.data;
    });
  });
};

export const checkDuplicateSession = async (id: string) => {
  const queryParams = qs.stringify({
    sort: ['id:desc'],
    fields: ['sessionId','total','created','email','productName', 'expires','paymentStatus', 'currency'],
    filters: {
      sessionId: {
        $eq: id,
      },
    },
  });
  return await axios.get(`/user-subscriptions?${queryParams}`).then(function (response: any) {
    return response.data;
  });
};
