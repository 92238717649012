import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PaymentCancel() {
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

    if (sessionId) {
      setCheckoutSessionId(sessionId);
    }
  }, []);

  return (
    <div className="container p-5">
      <div className="card mt-5">
        <div className="card-header">
          <h4 className="text-center">{t('subscribe.failed.title')}</h4>
          <hr className="my-4" />
        </div>
        <div className="card-body">
          {' '}
          <p className="text-center">{t('subscribe.failed.desc')}</p>
        </div>
        <div className="row mt-1">
          <div className="form-group col-md-6">
            <div className="mt-2 mb-3 text-center">
              <button
                type="button"
                className="next btn btn-gold btn-round text-uppercase"
                onClick={() => navigate('/dashboard')}
              >
                {t('subscribe.failed.btn1')}
              </button>
            </div>
          </div>
          <div className="form-group col-md-6">
            <div className="mt-2 mb-3 text-center">
              <button
                type="button"
                className="next btn btn-gold btn-round text-uppercase"
                onClick={() => navigate('/select-subscription')}
              >
                {t('subscribe.failed.btn2')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCancel;
