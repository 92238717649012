import Modal from 'react-bootstrap/Modal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import './style.css';

interface ImageListProps {
  images: ReactImageGalleryItem[];
  show: boolean;
  onClose: any;
}
const ImageListModel = (props: ImageListProps) => {
  const { images, show, onClose } = props;
  const onHide = () => {
    if (onClose) onClose(false);
  };
  return (
    <>
      <Modal
        scrollable={false}
        animation={true}
        size="lg"
        show={show}
        onHide={() => onHide()}
        contentClassName="image-gallery-content"
        dialogClassName="image-gallery-dialog"
      >
        <Modal.Body>
          {images && images.length > 0 && (
            <ImageGallery
              items={images}
              lazyLoad={true}
              showThumbnails={true}
              showPlayButton={true}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageListModel;
