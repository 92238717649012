import { useTranslation } from 'react-i18next';

import { useGetProducts, useGetUserSubscription } from '../../../services/Subscription/subscriptionService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { find } from 'lodash';
import storage from '../../../utils/storage';

const UserSubscription = (_props: { email: string; isButtonOnly?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email, isButtonOnly } = _props;
  const { isLoading, data: subscriptions } = useGetUserSubscription({ enabled: !!email }, email);
  const {data: products, isLoading: isLoadingProducts} = useGetProducts();
  const isUserDisplayMode = storage.isUserDisplayMode();

  const activeSubscriptions = subscriptions?.data?.filter((s: any) => s.status === 'active');

  const getProductName = (stripeProductId: string) => {
    if(!isLoadingProducts && products?.products){
      const product = find(products.products, {stripeProductId});
      if(product){
        return product.title;
      }
    }
    return '';
  }

  const renderSubscription = (subscription: any) => {
    if (!subscription) {
      return (<div/>);
    }
    const {current_period_end, current_period_start, plan, status} = subscription;
    return ( 
      <div className="table-responsive">
      <table className="table text-center" style={{fontSize: 12}}>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>{!isLoadingProducts && products?.products ? getProductName(plan.product) : ''}</th>
            <th style={{ width: '30%' }}>{t('subscribe.from')}</th>
            <th style={{ width: '30%' }}>{t('subscribe.to')}</th>
            <th style={{ width: '10%' }}>{t('subscribe.status')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{fontSize: 12}}>{plan.amount/100}({plan.currency.toUpperCase()})</td>
            <td style={{fontSize: 12}}>{(moment(current_period_start * 1000)).format("YYYY-MM-DD")}</td>
            <td style={{fontSize: 12}}>{(moment(current_period_end * 1000)).format("YYYY-MM-DD")}</td>
            <td style={{fontSize: 12}}>{status}</td>
          </tr>
        </tbody>
        </table>
        </div>
    );
  };

  if (isButtonOnly) {
    if (!isLoading && activeSubscriptions && activeSubscriptions.length > 0) {
      return null;
    }

    return (
      isUserDisplayMode ? null : <button
        type="button"
        className="next btn btn-gold btn-round text-uppercase"
        disabled={isLoading}
        onClick={() => navigate("/select-subscription")}
      >
        {t("Subscribe.premium")}
      </button>
    );
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{t('subscribe.title')}</h5>
      </div>
      {!isLoading && activeSubscriptions && (
        <div className="card-body">{renderSubscription(activeSubscriptions[0])}</div>
      )}
      {!isLoading && !activeSubscriptions && (
        <div className="card-body">
          <p className="text-center"><label className="form-check-label text-center" htmlFor="flexSwitchCheckDefault">
            {t('Subscribesubscribe.desc')}
          </label>
          </p>
          <div className="row mt-1">
              <div className="form-group col-md-12">
                <div className="mt-2 mb-3 text-center">
                  <button
                    type="button"
                    className="next btn btn-gold btn-round text-uppercase"
                    disabled={isLoading}
                    onClick={() => navigate("/select-subscription")}
                  >
                    {/* {mutateAsyncPostLoading ? (
                      <ButtonSpinner message={t('common.updating')} />
                    ) : (
                      t('change_password_page.change_password_button')
                    )} */}
                    {t("Subscribe.btn")}
                  </button>
                </div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default UserSubscription;
