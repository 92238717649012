import { isArray, isEmpty } from 'lodash';
import axios from '../../lib/client';
import publicAxios from '../../lib/publicClient';
import storage from '../../utils/storage';
import { GuideOptions } from '../Guide/guide';
import { currentApi } from './dbInfo';

import { User } from '../User/user';
import { IApiHandler } from './iRepo';
import { toGuideDetails } from './utils';

export const ApiHandler: IApiHandler = {
  createAccount: (email: string, password: string) => {
    return publicAxios
      .post(`/auth/local/register`, {
        username: email,
        email,
        password,
      })
      .then((response: any) => {
        return response.data;
      });
  },
  connectWithProvider: async (provider: string) => {
    try {
      const response = await publicAxios.get(`/connect/${provider}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      console.log(response);
    } catch (reason) {
      console.log(reason);
    }
  },
  setUserAsGuide: async (user: User | null = null) => {
    try {
      let userRes = await axios.get(`${currentApi.user}/me`);

      let { data } = userRes;

      await axios.put(`${currentApi.user}/${data._id}`, {
        data: { email: data.email, isGuide: true },
      });

      let returnValues: User = {
        displayName: user?.displayName,
        id: user?.id,
      } as User;

      return returnValues;
    } catch (reason) {
      console.log(reason);
      return {} as User;
    }
  },
  authWithProvider: (provider: string, search: string) => {
    const requestURL = `/auth/${provider}/callback${search}`;
    const response = axios.get(requestURL, { method: 'GET' }).then((r: any) => {
      if (r.data.jwt) {
        return r.data;
      } else {
        return {} as User;
      }
    });

    return response;
  },

  getGuideOptions: () => {
    return axios({
      method: 'get',
      url: `${currentApi.getGuideOptions}`,
    })
      .then(function (response: any) {
        let returnValues: GuideOptions = {} as GuideOptions;
        Object.keys(response.data[0]).forEach(
          (e) => ((returnValues as any)[e] = response.data[0][e])
        );
        return returnValues;
      })
      .catch((reason: any) => {
        return {} as GuideOptions;
      });
  },
  saveGuideDetails: async (guideId: number, userId: number) => {
    await axios({
      method: 'put',
      data: { data: { publishedAt: new Date().toISOString() } },
      url: `${currentApi.saveGuideDetails}/${guideId}`,
    });

    await axios({
      method: 'put',
      data: { isGuide: true },
      url: `${currentApi.user}/${userId}`,
    });

    return await ApiHandler.getMe();
  },
  uploadFiles: (files: File[], id?: string, field?: string, ref?: string, path?: string) => {
    let formData = new FormData();
    if (id) formData.append('refId', id);
    if (ref) formData.append('ref', ref);
    if (field) formData.append('field', field);
    if (path) formData.append('path', path);

    Array.from(files).forEach((file) => {
      formData.append('files', file, file.name);
    });

    return axios
      .post(currentApi.uploadUriValue, formData)
      .then((response: any) => {
        if (isArray(response.data)) {
          return response.data as MediaFileDto[];
        } else {
          return response.data as MediaFileDto;
        }
      })
      .catch((erro) => {
        console.log(erro);
        return Promise.reject(erro);
      });
  },
  removeFile: async (fileId: string) => {
    const deleteResult = await axios({
      method: 'delete',
      url: `${currentApi.uploadUriValue}/files/${fileId}`,
    }).then((response: any) => response.data);
    return deleteResult;
  },
  getFiles: async () => {
    const allFiles = await axios({
      method: 'get',
      url: `${currentApi.uploadUriValue}/files/`,
    }).then((response: any) => response.data);
    return allFiles;
  },
  loginWithEmail: (email: string, password: string) => {
    let postRequest = publicAxios({
      method: 'POST',
      data: {
        identifier: email,
        password: password,
      },
      url: `${currentApi.loginUriValue}`,
    }).then((response: any) => {
      return response.data;
    });

    return postRequest;
  },

  deleteTrip: async (tripId: string) => {
    return await axios({
      method: 'DELETE',
      url: `${currentApi.trips}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response: any) {
        return true;
      })
      .catch((reason: any) => {
        console.log(reason);
        return null;
      });
  },
  getMe: async () => {
    return await axios({
      method: 'get',
      url: `${currentApi.getMe}`,
    })
      .then(function (response: any) {
        return response.data;
      })
      .catch(() => {
        storage.clearToken();
      });
  },

  getUser: async (userId: string) => {
    return await axios({
      method: 'get',
      url: `${currentApi.getUser}/${userId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(function (response: any) {
      let returnValues: User = {
        displayName: response.data.username,
        id: response.data._id,
      } as User;

      return returnValues;
    });
  },

  setCurrentGuideFromDto: (guide: GuideDetailsDto) => {
    return publicAxios({
      method: 'get',
      url: `${currentApi.getGuideUriValue}/${guide.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(function (response: any) {
      return toGuideDetails(response.data);
    });
  },
  logout: () => {
    storage.clearToken();
    return 'success';
  },
  getPageFiles: (pageName: string) => {
    if (isEmpty(pageName)) {
      return Promise.reject('no pagename provided');
    }

    return axios.get(`/pageFiles?forPage=${pageName}`).then(function (response: any) {
      return response.data[0];
    });
  },
  getPageTexts: (pageName: string) => {
    if (isEmpty(pageName)) {
      return Promise.reject('no pagename provided');
    }

    return axios.get(`/pagetexts?page=${pageName}`).then(function (response: any) {
      return response.data[0];
    });
  },
};
