import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'validators.mixed.default',
    required: 'validators.mixed.required',
    oneOf: ({ values }) => 'validators.mixed.oneOf',
    notOneOf: ({ values }) => 'validators.mixed.notOneOf',
    notType: ({ type }) => 'validators.mixed.notType',
    defined: 'validators.mixed.defined',
  },
  string: {
    length: ({ length }) => 'validators.string.length',
    min: ({ min }) => 'validators.string.min',
    max: ({ max }) => 'validators.string.max',
    matches: ({ regex }) => 'validators.string.matches',
    email: 'validators.string.email',
    url: 'validators.string.url',
    uuid: 'validators.string.uuid',
    trim: 'validators.string.trim',
    lowercase: 'validators.string.lowercase',
    uppercase: 'validators.string.uppercase',
  },
  number: {
    min: ({ min }) => 'validators.number.min',
    max: ({ max }) => 'validators.number.max',
    lessThan: ({ less }) => 'validators.number.lessThan',
    moreThan: ({ more }) => 'validators.number.moreThan',
    positive: 'validators.number.positive',
    negative: 'validators.number.negative',
    integer: 'validators.number.integer',
  },
  date: {
    min: ({ min }) => 'validators.date.min',
    max: ({ max }) => 'validators.date.max',
  },
  boolean: {
    isValue: ({ value }) => 'validators.boolean.isValue',
  },
  object: {
    noUnknown: ({ unknown }) => 'validators.object.noUnknown',
  },
  array: {
    min: ({ min }) => 'validators.array.min',
    max: ({ max }) => 'validators.array.max',
    length: ({ length }) => 'validators.array.length',
  },
});
export default yup;
