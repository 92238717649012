import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { BroadcastChannel } from 'broadcast-channel';
import i18next from 'i18next';
import qs from 'qs';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import axios from '../lib/client';
import { LoginFormData, RegisterFormData, User, UserResponse } from '../services/User/user';
import { createAccount, getUser, loginWithEmail } from '../services/User/userService';
import storage from '../utils/storage';
import { queryClient } from './react-query';
const broadcastChannel = new BroadcastChannel('auth');

interface ReactQueryAuthConfig<User, LoginCredentials, RegisterCredentials> {
  userFn: QueryFunction<User, QueryKey>;
  loginFn: MutationFunction<User, LoginCredentials>;
  registerFn: MutationFunction<User, RegisterCredentials>;
  logoutFn: MutationFunction<unknown, unknown>;
  userKey?: QueryKey;
}

function configureAuth<User, Error, LoginCredentials, RegisterCredentials>(
  config: ReactQueryAuthConfig<User, LoginCredentials, RegisterCredentials>
) {
  const { userFn, userKey = ['authenticated-user'], loginFn, registerFn, logoutFn } = config;

  const useUser = (
    options?: Omit<UseQueryOptions<User, Error, User, QueryKey>, 'queryKey' | 'queryFn'>
  ) => useQuery(userKey, userFn, options);

  const useLogin = (
    options?: Omit<UseMutationOptions<User, Error, LoginCredentials>, 'mutationFn'>
  ) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useRegister = (
    options?: Omit<UseMutationOptions<User, Error, RegisterCredentials>, 'mutationFn'>
  ) => {
    return useMutation({
      mutationFn: registerFn,
      ...options,
      onSuccess: (user, ...rest) => {
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useLogout = (options?: UseMutationOptions<unknown, Error, unknown>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User | null) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        queryClient.clear();
        options?.onSuccess?.(...args);
      },
    });
  };

  function AuthLoader({
    children,
    renderLoading,
    renderUnauthenticated,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: {
    children: React.ReactNode;
    renderLoading: () => JSX.Element;
    renderUnauthenticated?: () => JSX.Element;
    renderError?: (error: Error) => JSX.Element;
  }) {
    const { isSuccess, isFetched, status, data, error } = useUser();

    if (isSuccess) {
      if (renderUnauthenticated && !data) {
        return renderUnauthenticated();
      }
      return <>{children}</>;
    }

    if (!isFetched) {
      return renderLoading();
    }

    if (status === 'error') {
      return renderError(error);
    }

    return null;
  }

  return {
    useUser,
    useLogin,
    useRegister,
    useLogout,
    AuthLoader,
  };
}

async function handleUserResponse(data: UserResponse) {
  const { jwt, user } = data;
  storage.setToken(jwt);
  return user;
}

async function userFn() {
  if (storage.getToken()) {
    const data = await getUser();
    return data;
  }
  return null;
}

async function loginFn(data: LoginFormData) {
  const response = await loginWithEmail(data);
  const user = await handleUserResponse(response);
  const GuideLastDraftVersionQueryParams = qs.stringify(
    {
      filters: {
        publishedAt: {
          $null: 'true',
        },
      },
      fields: ['id'],
      pagination: {
        pageSize: '1',
        page: '1',
      },
      publicationState: 'preview',
    },
    { encode: false }
  );

  await axios
    .get(`/guides?locale=${i18next.language}&${GuideLastDraftVersionQueryParams}`)
    .then(function (response: any) {
      if (response.data.data.length > 0) {
        sessionStorage.setItem('next', '/guides');
      }
    });
  broadcastChannel.postMessage('login');

  return user;
}

async function registerFn(data: RegisterFormData) {
  const response = await createAccount(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  storage.clearDisplayMode();
  sessionStorage.clear();
  queryClient.invalidateQueries();
  broadcastChannel.postMessage('logout');
  window.location.reload();
}

const authConfig = {
  userFn,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  },
};

export const authBroadcastChannel = () => {
  broadcastChannel.onmessage = async (message) => {
    broadcastChannel.close();
    if (message === 'logout') {
      await logoutFn();
    }

    if (message === 'login') {
      window.location.reload();
    }
  };
};

export const { AuthLoader, useUser, useLogin, useLogout, useRegister } = configureAuth<
  User | null,
  any,
  LoginFormData,
  RegisterFormData
>(authConfig);
