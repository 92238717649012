import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CheckBoxField,
  FileSelectionField,
  MultiSelectField,
  NumberField,
  RadioField,
  SelectField,
  StaticDataSelectField,
  TextField,
} from '../../../../components/Form';
import useBoatHook from '../../../../services/Boat/boatService';
import { dirtyValues, scrollToTop } from '../../../../services/utils';

import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../../../components/Spinner/ButtonSpinner';
import useOptionData from '../../../../lib/optionData';
import useGuideOptionsHook from '../../../../services/guideOptionsHook';
import { isEmpty } from '../../../../services/utils';
interface InputProps {
  index: number;
  name: string;
  edit: boolean;
  onSave: any;
  onCancel: any;
}

export const FormBoat: React.FC<InputProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    useBoatTypes: boatTypes,
    useEngineTypes: typeofEngine,
    useNavigationEquipments: navEquipments,
    useSafetyEquipments: safetyEquipment,
    useFishingGears: fishingGearOnBoard,
    useCrew: additionalCrewOnBoard,
    useFacilities: facilities,
  } = useGuideOptionsHook();

  const {
    boatLength,
    boatCapicity,
    numberOfEngine,
    maxCruisingSpeed,
    wheelchairAccessibleOptions,
  } = useOptionData();
  const fieldName = `${props.name}[${props.index}]`;
  const { trigger, getFieldState, getValues, formState } = useFormContext();
  const { postBoat, updateBoat } = useBoatHook({});
  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingPost } = postBoat;
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = updateBoat;

  const submitHandler = () => {
    trigger(fieldName).then(() => {
      const { error } = getFieldState(fieldName);

      if (!error) {
        const boatDetail = getValues(fieldName);

        if (!isEmpty(formState.dirtyFields)) {
          if (boatDetail.id) {
            let dirtyFields = dirtyValues(
              formState?.dirtyFields?.presentYourBoats[props.index],
              boatDetail
            );
            if (Object.keys(dirtyFields).length) {
              dirtyFields.id = boatDetail.id;
              mutateAsyncUpdate(dirtyFields).then(() => {
                props.onSave();
              });
            } else {
              props.onSave();
            }
          } else {
            const guideDetail = getValues();
            boatDetail.guide = guideDetail.id;
            if (boatDetail.guide) {
              mutateAsyncPost(boatDetail).then(() => {
                props.onSave();
              });
            }
          }
        } else {
          props.onSave();
        }
      }
    });
  };

  const backHandler = () => {
    const boatDetail = getValues(fieldName);
    props.onCancel(props.index, boatDetail.id);
    scrollToTop();
  };

  return (
    <div key={fieldName}>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-2 mb-3">
            <h5>{t('boat.title')}</h5>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <SelectField
            className="product_select"
            name={`${fieldName}.boatType`}
            placeholder={t('boat.fields.boatType.placeholder')}
            options={boatTypes}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.boatLength`}
            placeholder={t('boat.fields.boatLength.placeholder')}
            options={boatLength}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.boatManufacturer`}
            placeholder={t('boat.fields.boatManufacturer.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.guestCapacity`}
            placeholder={t('boat.fields.guestCapacity.placeholder')}
            options={boatCapicity}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-2 mb-3">
            <RadioField
              label={t('boat.fields.wheelchairAccessible.label')}
              name={`${fieldName}.wheelchairAccessible`}
              options={wheelchairAccessibleOptions}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <SelectField
            className="product_select"
            name={`${fieldName}.typeofEngine`}
            placeholder={t('boat.fields.typeofEngine.placeholder')}
            options={typeofEngine}
          />
        </div>
        <div className="form-group col-md-6">
          <div className="mt-2 mb-3">
            <CheckBoxField
              name={`${fieldName}.extraMotor`}
              label={t('boat.fields.extraMotor.label')}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.engineManufacturer`}
            placeholder={t('boat.fields.engineManufacturer.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.numberofEngines`}
            placeholder={t('boat.fields.numberofEngines.placeholder')}
            options={numberOfEngine}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <NumberField
            name={`${fieldName}.horsePowerPerEngine`}
            placeholder={t('boat.fields.horsePowerPerEngine.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.maxCruisingSpeed`}
            placeholder={t('boat.fields.maxCruisingSpeed.placeholder')}
            options={maxCruisingSpeed}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('boat.equipmentFacilitiesSettingTitle')}</h5>
          </div>

          <MultiSelectField
            className="product_select"
            name={`${fieldName}.navEquipments`}
            placeholder={t('boat.fields.navEquipments.placeholder')}
            label={t('boat.fields.navEquipments.label')}
            options={navEquipments}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.safetyEquipments`}
            label={t('boat.fields.safetyEquipments.label')}
            placeholder={t('boat.fields.safetyEquipments.placeholder')}
            options={safetyEquipment}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.facilities`}
            label={t('boat.fields.facilities.label')}
            placeholder={t('boat.fields.facilities.placeholder')}
            options={facilities}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.fishingGears`}
            label={t('boat.fields.fishingGears.label')}
            placeholder={t('boat.fields.fishingGears.placeholder')}
            options={fishingGearOnBoard}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.additionalCrew`}
            label={t('boat.fields.additionalCrew.label')}
            placeholder={t('boat.fields.additionalCrew.placeholder')}
            options={additionalCrewOnBoard}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('boat.boatMediaSettingTitle')}</h5>
          </div>

          <FileSelectionField
            name={`${fieldName}.boatMedia`}
            label={t('boat.fields.boatMedia.label')}
            selectedImagePath={'TempBoat'}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-2 mb-3">
            <button type="button" className="btn previous previous_button" onClick={backHandler}>
              {t('common.cancel')}
            </button>
            <button
              type="button"
              className="next btn btn-gold"
              onClick={submitHandler}
              disabled={isLoadingPost || isLoadingUpdate}
            >
              {isLoadingPost || isLoadingUpdate ? (
                <ButtonSpinner message={t('common.saving')} />
              ) : (
                t('common.save')
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
