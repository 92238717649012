import { useTranslation } from 'react-i18next';

interface SelectOption {
  label: string;
  value: string;
}

const masterDataModels = {
  guideTitles: 'guide-title',
  fishingTechniques: 'fishing-technique',
  engineTypes: 'engine-type',
  navigationEquipments: 'navigation-equipment',
  safetyEquipments: 'safety-equipment',
  facilities: 'facility',
  fishingGears: 'fishing-gear',
  crew: 'crew',
  fishingSpecies: 'fishing-specie',
  catchPolicies: 'catch-policy',
  pickupPolicies: 'pickup-policy',
  foodAndBeverages: 'food-and-beverage',
  cancellationPolicies: 'cancellation-policy',
  boatType: 'boat-type',
  fishingTypes: 'fishing-type',
};

const useGuideOptionsHook = () => {
  const useQueryOptions = (key: string) => {
    const { t } = useTranslation();
    const array: any = t(`master_data_array.${key}`, { returnObjects: true });
    const options: SelectOption[] = array.map((obj: any) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      return { label: value, value: key };
    });

    return options;
  };

  const useBoatTypes = useQueryOptions(masterDataModels.boatType);
  const useCancellationPolicy = useQueryOptions(masterDataModels.cancellationPolicies);
  const useFishingTechniques = useQueryOptions(masterDataModels.fishingTechniques);
  const useFishingTypes = useQueryOptions(masterDataModels.fishingTypes);
  const useFishingSpecies = useQueryOptions(masterDataModels.fishingSpecies);
  const useEngineTypes = useQueryOptions(masterDataModels.engineTypes);
  const useNavigationEquipments = useQueryOptions(masterDataModels.navigationEquipments);
  const useSafetyEquipments = useQueryOptions(masterDataModels.safetyEquipments);
  const useFishingGears = useQueryOptions(masterDataModels.fishingGears);
  const useCrew = useQueryOptions(masterDataModels.crew);
  const useFacilities = useQueryOptions(masterDataModels.facilities);
  const useFoodAndBeverages = useQueryOptions(masterDataModels.foodAndBeverages);
  const useCatchPolicies = useQueryOptions(masterDataModels.catchPolicies);
  const usePickupPolicies = useQueryOptions(masterDataModels.pickupPolicies);
  const useCancellationPolicies = useQueryOptions(masterDataModels.cancellationPolicies);
  const useGuideTitle = useQueryOptions(masterDataModels.guideTitles);

  return {
    useBoatTypes,
    useCancellationPolicy,
    useFishingTechniques,
    useNavigationEquipments,
    useFishingTypes,
    useFishingSpecies,
    useEngineTypes,
    useFishingGears,
    useFacilities,
    useCrew,
    useSafetyEquipments,
    useFoodAndBeverages,
    useCatchPolicies,
    usePickupPolicies,
    useCancellationPolicies,
    useGuideTitle,
  };
};

export default useGuideOptionsHook;
