import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import {
  useGetEmailPreference,
  useUpdate,
} from '../../../services/EmailPreference/emailPreferenceService';

const UserSetting = () => {
  const { t } = useTranslation();

  const { isLoading: isEmailPreferenceLoading, data: emailPreference } = useGetEmailPreference();

  const { mutateAsync: mutateAsyncUpdateStatus } = useUpdate();

  const onEmailPreferenceChangeHandler = (field: string, value: any) => {
    const variables: any = { [field]: value };

    mutateAsyncUpdateStatus(
      { id: emailPreference.id, variables },
      {
        onSuccess: () => {
          NotificationManager.success(t('notification.setting.updated'));
        },
      }
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{t('user.settings')}</h5>
      </div>
      <div className="card-body">
        <ul className="list-unstyled team-members">
          {isEmailPreferenceLoading && <ButtonSpinner />}
          {!isEmailPreferenceLoading && (
            <>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={emailPreference?.send_message_alerts}
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onClick={(e: any) =>
                    onEmailPreferenceChangeHandler('send_message_alerts', e.target.checked)
                  }
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  {t('settings.message_alerts')}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={emailPreference?.send_newsletters}
                  role="switch"
                  id="flexSwitchCheckDefault1"
                  onClick={(e: any) =>
                    onEmailPreferenceChangeHandler('send_newsletters', e.target.checked)
                  }
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">
                  {t('settings.newsletters_promotions')}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={emailPreference?.send_system_messages}
                  role="switch"
                  id="flexSwitchCheckDefault2"
                  onClick={(e: any) =>
                    onEmailPreferenceChangeHandler('send_system_messages', e.target.checked)
                  }
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                  {t('settings.system_messages')}
                </label>
              </div>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default UserSetting;
