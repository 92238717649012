/* eslint-disable react-hooks/exhaustive-deps */
import { CellContext, createColumnHelper, PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Datatable from '../../../components/Datatable';
import TextAreaField from '../../../components/Form/TextAreaField';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import { useUser } from '../../../lib/auth';
import moment from '../../../lib/moment';
import { currentApi } from '../../../services/Db/dbInfo';
import { Conversation } from '../../../services/Guide/guide';
import {
  useGetAllMessage,
  useGetUserConversation,
  useSendMessage,
  useUpdateConversationStatus,
} from '../../../services/Message/messageService';
import { emailUsername, getEmailInitials } from '../../../services/utils';

const columnHelper = createColumnHelper<Conversation>();

function UserMessages() {
  const { t } = useTranslation();
  const { data: userInfo } = useUser();
  const { conversationId } = useParams();
  const [expanded, setExpanded] = useState<any>(
    conversationId ? { id: parseInt(conversationId) } : undefined
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const { mutateAsync: mutateAsyncUpdateConversationStatus } = useUpdateConversationStatus();

  useEffect(() => {
    if (conversationId) {
      mutateAsyncUpdateConversationStatus({ id: parseInt(conversationId) });
    }
  }, [conversationId]);

  const rowExpandHandler = (info: CellContext<any, any>): void => {
    if (expanded) setExpanded(undefined);
    info.table.toggleAllRowsExpanded(false);
    info.cell.row.toggleExpanded(!info.cell.row.getIsExpanded());
    if (info.cell.row.original.buyer_status === 'Unread') {
      mutateAsyncUpdateConversationStatus({ id: info.cell.row.original.id });
    }
  };

  const columns = [
    columnHelper.accessor((row) => row?.last_message.sender?.username, {
      id: 'FROM',
      cell: (info) => (
        <i style={{ cursor: 'pointer' }}>
          {info.cell.row.getCanExpand() ? (
            <span onClick={() => rowExpandHandler(info)}>
              {info.cell.row.getIsExpanded() ? (
                <>
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </>
              ) : (
                <>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </>
              )}
              {userInfo?.id.toString() !==
              info.cell.row?.original?.last_message.sender?.id?.toString()
                ? ` ${emailUsername(info.getValue())}`
                : info.cell.row?.original?.guide?.name}
            </span>
          ) : null}
        </i>
      ),
      header: () => <span>{t('conversations.from')}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row?.last_message.sent_at, {
      id: 'TIME',
      cell: (info) => <i>{moment(info.getValue()).format('LLLL')}</i>,
      header: () => <span>{t('conversations.time')}</span>,
    }),

    columnHelper.accessor((row) => row?.trip?.name, {
      id: 'TRIP',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>{t('conversations.trip')}</span>,
    }),
    columnHelper.accessor((row) => row?.buyer_status, {
      id: 'STATUS',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>{t('conversations.status')}</span>,
    }),
  ];

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{t('conversations.messages')}</h5>
            </div>
            <div className="card-body">
              <Datatable
                dataQuery={useGetUserConversation(fetchDataOptions)}
                tableColumns={columns}
                pagination={pagination}
                onPaginationChange={setPagination}
                RenderRowSubComponent={RenderSubComponent}
                getRowCanExpand={() => true}
                expanded={expanded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const RenderSubComponent = ({ row }: { row: Conversation }) => {
  const conversationId = row.id;
  const { data, isLoading } = useGetAllMessage(conversationId);
  const { data: userInfo } = useUser();

  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingUpdate } = useSendMessage();
  const methods = useForm({
    mode: 'all',
    defaultValues: {},
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const onSubmit = (data: any) => {
    const request = { conversationId: conversationId, message: data.message };
    mutateAsyncPost(request, {
      onSuccess: () => {
        methods.reset();
      },
    });
  };

  const onErrors = (data: any) => {
    console.log(data);
  };
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
  }, [data]);

  return (
    <section
      key={conversationId}
      ref={scrollableDivRef}
      style={{ backgroundColor: '#fff', maxHeight: '600px', overflowY: 'scroll' }}
    >
      <div className="py-5" style={{ width: '100%', margin: '0 auto', padding: '50px' }}>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-12">
            {!isLoading && (
              <ul className="list-unstyled">
                {data &&
                  data?.data?.map((item: any, index: number) => {
                    return (
                      <li className="d-flex mb-4" key={`${conversationId}_${index}`}>
                        {userInfo?.id !== item?.sender?.id && (
                          <>
                            <img
                              src={`${currentApi.fileBasePath}${row?.guide?.profilePhoto}`}
                              alt="avatar"
                              className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong m-3 message-avatar"
                              width="60"
                            />
                            <div
                              className="card w-100"
                              style={{ boxShadow: '5px 6px 10px 4px rgba(0, 0, 0, 0.15)' }}
                            >
                              <div className="card-header d-flex justify-content-between p-3">
                                <p className="fw-bold mb-0">{row?.guide?.name}</p>
                                <p className="text-muted small mb-0">
                                  <i className="far fa-clock"></i> {moment(item.sent_at).fromNow()}
                                </p>
                              </div>
                              <div className="card-body">
                                <p className="mb-0">{item.message}</p>
                              </div>
                            </div>
                          </>
                        )}

                        {userInfo?.id === item?.sender?.id && (
                          <>
                            <div
                              className="card w-100"
                              style={{ boxShadow: '5px 6px 10px 4px rgba(0, 0, 0, 0.15)' }}
                            >
                              <div className="card-header d-flex justify-content-between p-3">
                                <p className="fw-bold mb-0">{emailUsername(item?.sender?.email)}</p>
                                <p className="text-muted small mb-0">
                                  <i className="far fa-clock"></i> {moment(item.sent_at).fromNow()}
                                </p>
                              </div>
                              <div className="card-body">
                                <p className="mb-0">{item.message}</p>
                              </div>
                            </div>
                            <span className="initial-avatar rounded-circle d-flex align-self-start ms-3 shadow-1-strong m-3">
                              {getEmailInitials(item?.sender?.email)}
                            </span>
                          </>
                        )}
                      </li>
                    );
                  })}
              </ul>
            )}
            {isLoading && <ButtonSpinner />}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
                <div className="form-outline">
                  <TextAreaField name={`message`} label="Message:" />
                </div>
                <div className="row mt-1">
                  <div className="form-group col-md-12">
                    <div className="mt-2 mb-3 text-end">
                      <Button
                        variant="warning"
                        type="submit"
                        className="next btn btn-gold text-uppercase"
                        disabled={!isDirty || isLoadingUpdate}
                      >
                        {isLoadingUpdate ? <ButtonSpinner message=" Sending..." /> : 'Send'}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserMessages;
