import React, { memo, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiCheckBoxField, TextField } from '../../components/Form';
import { SelectOption } from '../../components/Form/SelectField';

interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
  callback?: any;
}
function CustomToggle({ children, eventKey, callback }: CustomToggleProps) {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const { activeEventKey } = useContext(AccordionContext);

  let isCurrentEventKey = false;
  if (Array.isArray(activeEventKey)) {
    activeEventKey.forEach(function (element) {
      if (!isCurrentEventKey) {
        isCurrentEventKey = element === eventKey;
      }
    });
  } else {
    isCurrentEventKey = activeEventKey === eventKey;
  }

  return (
    <h4 className={isCurrentEventKey ? '' : 'closed'} onClick={decoratedOnClick}>
      {children}
    </h4>
  );
}

interface InputProps {
  searchData?: any;
  onChage(
    search?: string,
    targetedSpecies?: number[],
    fishingTechniques?: number[],
    fishingTypes?: number[]
  ): void;
}

const FilterBox = (prop: InputProps, ref: React.ForwardedRef<any>) => {
  const { t } = useTranslation();
  const [fishingTechniques, setFishingTechniques] = useState<SelectOption[]>([]);
  const [typesofFishing, setTypesofFishing] = useState<SelectOption[]>([]);
  const [fishingSpecies, setFishingSpecies] = useState<SelectOption[]>([]);

  const { onChage, searchData } = prop;
  useEffect(() => {
    filterDistinctValues(searchData);
  }, [searchData]);

  function filterDistinctValues(data: any): void {
    if (!data || !Array.isArray(data)) {
      return;
    }

    function getDistinctObjects(array: any[], property: string): any[] {
      const map = new Map();
      return array.filter((item) => {
        const key = item[property];
        return map.has(key) ? false : map.set(key, true);
      });
    }

    setTypesofFishing(
      getDistinctObjects(
        data.flatMap((trip) => trip.fishingTypes),
        'value'
      )
    );
    setFishingSpecies(
      getDistinctObjects(
        data.flatMap((trip) => trip.targetedSpecies),
        'value'
      )
    );
    setFishingTechniques(
      getDistinctObjects(
        data.flatMap((trip) => trip.fishingTechniques),
        'value'
      )
    );
  }

  const methods = useForm<any>({
    mode: 'all',
    defaultValues: {},
  });
  const { watch, setValue, resetField, getValues } = methods;

  const fields: any = watch();

  useEffect(() => {
    if (
      fields.search ||
      fields.fishingTypes ||
      fields.fishingTechniques ||
      fields.targetedSpecies
    ) {
      const delay = 500;
      const timerId = setTimeout(() => {
        if (onChage)
          onChage(
            fields.search,
            fields.targetedSpecies,
            fields.fishingTechniques,
            fields.fishingTypes
          );
      }, delay);

      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.search, fields.fishingTypes, fields.fishingTechniques, fields.targetedSpecies]);

  const clearFilter = (filter: any) => {
    //alert('Child Method Triggered! ' + JSON.stringify(filter));
    switch (filter.type) {
      case 'search':
        resetField('search');
        break;
      case 'fishingTypes':
        {
          const values = getValues('fishingTypes');
          setValue(
            'fishingTypes',
            values.filter((fishingType: any) => fishingType.value !== filter.value)
          );
        }
        break;
      case 'fishingTechniques':
        {
          const values = getValues('fishingTechniques');
          setValue(
            'fishingTechniques',
            values.filter((fishingTechnique: any) => fishingTechnique.value !== filter.value)
          );
        }
        break;
      case 'targetedSpecies':
        {
          const values = getValues('targetedSpecies');
          setValue(
            'targetedSpecies',
            values.filter((targetedSpecie: any) => targetedSpecie.value !== filter.value)
          );
        }
        break;
    }
  };

  // Expose the child method via ref
  useImperativeHandle(ref, () => ({
    clearFilter,
  }));

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <Accordion defaultActiveKey="0" flush alwaysOpen={true}>
          <div className="cd-filter-block">
            <CustomToggle eventKey="0"> {t(`common.search`)}</CustomToggle>
            <Accordion.Collapse eventKey="0">
              <div className="cd-filter-content">
                <TextField name="search" placeholder="Search" />
              </div>
            </Accordion.Collapse>
          </div>
          {typesofFishing && (
            <div className="cd-filter-block cd-filters list">
              <CustomToggle eventKey="1">{t('trip.fields.fishingTypes.label')}</CustomToggle>
              <Accordion.Collapse eventKey="1">
                <div className="cd-filter-content">
                  <MultiCheckBoxField
                    className="product_select"
                    name={`fishingTypes`}
                    placeholder={t('trip.fields.fishingTypes.placeholder')}
                    options={typesofFishing}
                    inline={false}
                  />
                </div>
              </Accordion.Collapse>
            </div>
          )}

          {fishingTechniques && (
            <div className="cd-filter-block cd-filters list">
              <CustomToggle eventKey="2">{t('trip.fields.fishingTechniques.label')}</CustomToggle>
              <Accordion.Collapse eventKey="2">
                <div className="cd-filter-content">
                  <MultiCheckBoxField
                    className="product_select"
                    name={`fishingTechniques`}
                    placeholder={t('trip.fields.fishingTechniques.placeholder')}
                    options={fishingTechniques}
                    inline={false}
                  />
                </div>
              </Accordion.Collapse>
            </div>
          )}

          {fishingSpecies && (
            <div className="cd-filter-block cd-filters list">
              <CustomToggle eventKey="3">{t('trip.fields.targetedSpecies.label')}</CustomToggle>
              <Accordion.Collapse eventKey="3">
                <div className="cd-filter-content">
                  <MultiCheckBoxField
                    className="product_select"
                    name={`targetedSpecies`}
                    placeholder={t('trip.fields.targetedSpecies.placeholder')}
                    options={fishingSpecies}
                    inline={false}
                  />
                </div>
              </Accordion.Collapse>
            </div>
          )}
        </Accordion>
      </form>
    </FormProvider>
  );
};

export default memo(React.forwardRef(FilterBox));
