import axios, { AxiosInstance } from 'axios';
import { NotificationManager } from 'react-notifications';
import { currentApi } from '../services/Db/dbInfo';
import storage from '../utils/storage';

// Create a custom Axios instance with the base URL
const api: AxiosInstance = axios.create({
  baseURL: currentApi.baseUriValue,
});

api.interceptors.request.use(
  (config) => {
    const token = storage.getToken();
    if (!token) {
      storage.clearToken();
      window.location.reload();
    } else {
      const headers = {
        Authorization: `bearer ${token}`,
      };
      Reflect.set(config, 'headers', headers);

      Reflect.set(config, 'baseURL', currentApi.baseUriValue);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Set up response interceptor
api.interceptors.response.use(
  (response) => {
    // Process the response data here if needed
    return response;
  },
  (error) => {
    let errorData = { message: '' };

    if (error.response) {
      errorData = error.response.data.error;
      const message = error?.response?.data?.error?.message;
      if (error.response.status !== 401 && error.response.status !== 403) {
        if (message) NotificationManager.error(message);
      }
    } else if (error.request) {
      errorData = error.request;
    } else {
      errorData = error.message;
    }

    return Promise.reject(errorData);
  }
);

export default api;
