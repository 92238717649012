import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from '../../services/User/userService';

type ResetPasswordProps = { password: string; passwordConfirmation: string };

function ResetPassword() {
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [passwordReset, setPasswordReset] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const refRecaptcha = React.useRef<ReCAPTCHA>(null);
  const code = queryParams.get('code');
  const { t } = useTranslation();
  const { mutateAsync: mutateAsyncPost, isError } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<ResetPasswordProps>();

  const onSubmit = handleSubmit((forgotPasswordProps: ResetPasswordProps) => {
    if (refRecaptcha && refRecaptcha.current) {
      try {
        refRecaptcha.current.execute();
        mutateAsyncPost(
          {
            code,
            password: forgotPasswordProps.password,
            passwordConfirmation: forgotPasswordProps.passwordConfirmation,
          },
          {
            onSuccess: (data: any) => {
              setPasswordReset(true);
            },
            onError: (error: any) => {
              if (error?.response?.data?.message) {
                setError('password', {
                  type: 'manual',
                  message: error.message,
                });
              }
            },
          }
        );
      } catch (ex) {
        refRecaptcha.current.reset();
      }
    }
  });

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className={`d-none d-md-flex col-md-4 col-lg-6 register-bg-image1`}></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                {' '}
                {!passwordReset && (
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mt-5 mb-4">{t('reset_password_page.heading')}</h2>
                    {isError && (
                      <div className="alert alert-danger" role="alert">
                        {t('validation.email_already_register')}
                      </div>
                    )}
                    <form
                      id="msform"
                      onSubmit={onSubmit}
                      className={`needs-validation ${
                        errors.password?.message ? 'was-validated' : ''
                      }`}
                    >
                      <div className="form-label-group">
                        <input
                          type="password"
                          id="inputPassword"
                          {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 8,
                              message: 'Password must be at least 8 characters long',
                            },
                          })}
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                          placeholder={t('user.fields.password.placeholder')}
                          required
                          autoFocus
                        />
                        <label htmlFor="inputPassword">{t('user.fields.password.label')}</label>
                      </div>
                      {errors.password && <p className="error">{errors.password.message}</p>}

                      <div className="form-label-group">
                        <input
                          type="password"
                          id="passwordConfirmation"
                          {...register('passwordConfirmation', {
                            validate: (value) =>
                              value === getValues('password') || 'Passwords do not match',
                          })}
                          className={`form-control ${errors.password?.message ? 'is-invalid' : ''}`}
                          placeholder={t('user.fields.passwordConfirmation.placeholder')}
                          required
                          autoFocus
                        />
                        <label htmlFor="passwordConfirmation">
                          {t('user.fields.passwordConfirmation.label')}
                        </label>
                      </div>

                      {errors.passwordConfirmation && (
                        <p className="error">{errors.passwordConfirmation.message}</p>
                      )}

                      <ReCAPTCHA
                        ref={refRecaptcha}
                        sitekey={`${process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}`}
                        onChange={(value) => {
                          if (value === null) setSubmitDisabled(true);
                        }}
                        asyncScriptOnLoad={() => {
                          setSubmitDisabled(false);
                        }}
                        size={
                          process.env.REACT_APP_RECAPTCHA_SIZE === 'normal' ? 'normal' : 'invisible'
                        }
                      />
                      <button
                        disabled={submitDisabled}
                        className="btn mt-2 btn-gold mb-2"
                        type="submit"
                      >
                        {t('common.reset_password')}
                      </button>
                      <hr className="my-4" />
                    </form>
                  </div>
                )}
                {passwordReset && (
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mt-5 mb-4">
                      {t('reset_password_page.success_heading')}
                    </h2>
                    <p>{t('reset_password_page.success_description')}</p>

                    <a className="sc-fvxzrP oyRxl" href="/signin">
                      <span className="sc-dkPtRN cvgrtB">{`${t('common.signin').toString()}`}</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
