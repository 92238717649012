import { Form } from 'react-bootstrap';
import { get, useFormContext } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

interface InputProps {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  className?: string;
  rows?: number;
  cols?: number;
}

const TextAreaField: React.FC<InputProps> = ({ label, placeholder, name, rows = 5, cols = 30 }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  return (
    <>
      <p className="mt-4">{label && <label>{label}</label>}</p>
      <div className="form-label-group">
        <Form.Control
          {...register(name)}
          as="textarea"
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          style={{ lineHeight: '20px', padding: '20px' }}
        />
        <ErrorMessage error={error} name={name} fieldLabel={placeholder} />
      </div>
    </>
  );
};
export default TextAreaField;
