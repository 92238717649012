import qs from 'qs';

export function guideLastDraftVersionQueryParams(): string {
  return qs.stringify(
    {
      filters: {
        publishedAt: {
          $null: 'true',
        },
      },
      populate: {
        title: { fields: ['id', 'name'] },
        businessMedia: {
          fields: ['id', 'name', 'url'],
        },
        profilePhoto: {
          fields: ['id', 'name', 'url'],
        },
        guide_boats: {
          populate: {
            boatMedia: { fields: ['id', 'name', 'url'] },
            typeofEngine: { fields: ['id', 'name'] },
            boatType: { fields: ['id', 'name'] },
            navEquipments: { fields: ['id', 'name'] },
            safetyEquipments: { fields: ['id', 'name'] },
            facilities: { fields: ['id', 'name'] },
            fishingGears: { fields: ['id', 'name'] },
            additionalCrew: { fields: ['id', 'name'] },
          },
        },
        guidetrips: {
          populate: {
            tripMedia: { fields: ['id', 'name', 'url'] },
            targetedSpecies: { fields: ['id', 'name'] },
            fishingTechniques: { fields: ['id', 'name'] },
            fishingTypes: { fields: ['id', 'name'] },
            catchPolicy: { fields: ['id', 'name'] },
            foodAndBeverages: { fields: ['id', 'name'] },
            cancellationPolicy: { fields: ['id', 'name'] },
            pickupPolicy: { fields: ['id', 'name'] },
            localizations: { fields: ['name', 'description', 'locale'] },

            guide_boat: {
              populate: {
                boatMedia: { fields: ['id', 'name', 'url'] },
                typeofEngine: { fields: ['id', 'name'] },
                boatType: { fields: ['id', 'name'] },
                navEquipments: { fields: ['id', 'name'] },
                safetyEquipments: { fields: ['id', 'name'] },
                facilities: { fields: ['id', 'name'] },
                fishingGears: { fields: ['id', 'name'] },
                additionalCrew: { fields: ['id', 'name'] },
              },
            },
          },
        },
      },
      pagination: {
        pageSize: '1',
        page: '1',
      },
      publicationState: 'preview',
    },
    { encode: false }
  );
}
