import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import qs from 'qs';
import { SelectOption } from '../../components/Form/SelectField';
import axios from '../../lib/client';
import axiosPublicClient from '../../lib/publicClient';
import { toBoatDto, toPresentYourBoat } from '../Db/utils';
import { PresentYourBoat } from '../Guide/guide';

const queryKey = ['boats'];
interface IBoatsByGuideIdOptions {
  pageIndex: number;
  pageSize: number;
  guideId: number;
}
export default function useBoatHook(props: any) {
  const { pageIndex = 1, pageSize = 25, id = undefined, guideId } = props;
  const queryClient = useQueryClient();
  const fetchDataOptions: IBoatsByGuideIdOptions = {
    pageIndex,
    pageSize,
    guideId,
  };

  const getBoatsByGuideId = useQuery(
    ['boats', fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          filters: {
            guide: {
              id: {
                $eq: `${fetchDataOptions.guideId}`,
              },
            },
          },
          fields: ['id', 'boatLength', 'boatManufacturer'],
          populate: {
            boatType: { fields: ['id'] },
          },
          pagination: {
            pageSize: `${fetchDataOptions.pageSize}`,
            page: `${fetchDataOptions.pageIndex}`,
          },
          publicationState: 'live',
        },
        { encode: false }
      );

      return await axios
        .get(`/guide-boats?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return response.data;
        });
    },
    { keepPreviousData: true, enabled: !!guideId }
  );

  const getBoatsOptionsByGuideId = useQuery<SelectOption[]>(
    ['Options', 'Boats', guideId],
    async () => {
      if (guideId) {
        const queryParams = qs.stringify(
          {
            filters: {
              guide: {
                id: {
                  $eq: `${guideId}`,
                },
              },
            },
            fields: ['id'],
            populate: {
              boatType: { fields: ['name'] },
            },
            pagination: {
              pageSize: `1000`,
              page: `1`,
            },
            publicationState: 'live',
          },
          { encode: false }
        );

        return await axios
          .get(`/guide-boats?locale=${i18next.language}&${queryParams}`)
          .then(function (response: any) {
            const data = response.data;
            const boats = data?.data?.map((boat: BoatDto) => {
              return {
                value: boat.id,
                label: boat.boatType.name,
              } as SelectOption;
            });
            return boats;
          });
      } else {
        return null;
      }
    },
    { keepPreviousData: true, enabled: !!guideId }
  );

  const getBoat = useQuery(
    ['boats', id],
    async () => {
      const queryParams = qs.stringify(
        {
          filters: {
            guide: {
              id: {
                $eq: `${fetchDataOptions.guideId}`,
              },
            },
          },
          populate: {
            boatMedia: { fields: ['id', 'name', 'url'] },
            typeofEngine: { fields: ['id', 'name'] },
            boatType: { fields: ['id', 'name'] },
            navEquipments: { fields: ['id', 'name'] },
            safetyEquipments: { fields: ['id', 'name'] },
            facilities: { fields: ['id', 'name'] },
            fishingGears: { fields: ['id', 'name'] },
            additionalCrew: { fields: ['id', 'name'] },
          },
          publicationState: 'live',
        },
        { encode: false }
      );

      return await axiosPublicClient
        .get(`/guide-boats/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return toPresentYourBoat(response.data?.data);
        });
    },
    { enabled: !!id }
  );

  const postBoat = useMutation(
    async (boat: PresentYourBoat) => {
      let data: BoatDto = toBoatDto(boat);
      await axios.post(`/guide-boats`, { data: data }).then(function (response: any) {
        let result: any = response.data.data;
        return result;
      });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );

  const updateBoat = useMutation(
    async (boat: PresentYourBoat) => {
      let data: BoatDto = toBoatDto(boat);
      await axios.put(`/guide-boats/${boat.id}`, { data: data }).then(function (response: any) {
        let result: any = response.data.data;
        return result;
      });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );

  const deleteBoat = useMutation(async (id: number) => await axios.delete(`/guide-boats/${id}`), {
    retry: 0,
    onSuccess: () => queryClient.invalidateQueries([queryKey]),
  });
  return {
    getBoat,
    getBoatsByGuideId,
    getBoatsOptionsByGuideId,
    postBoat,
    updateBoat,
    deleteBoat,
  };
}
