import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import qs from 'qs';
import axios from '../../lib/publicClient';

interface IPage {
  pageIndex: number;
  pageSize: number;
}
const queryKey = 'NewsPost';
const useGetNewsPost = (fetchDataOptions: IPage) => {
  return useQuery(
    [queryKey, i18next.language, fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          fields: ['id', 'title', 'author_name', 'for_operators', 'type', 'updatedAt'],
          populate: {
            image: { fields: ['url'] },
          },
          publicationState: 'live',
        },
        { encode: false }
      );
      return await axios
        .get(`/news-posts?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return { data: response.data?.data, meta: response.data?.meta };
        });
    },
    { keepPreviousData: true }
  );
};

const useGetNewsPostById = (id: number) => {
  const queryParams = qs.stringify(
    {
      fields: [
        'id',
        'title',
        'content',
        'author_name',
        'for_operators',
        'type',
        'updatedAt',
        'summary',
      ],
      populate: {
        image: { fields: ['url'] },
      },
      publicationState: 'live',
    },
    { encode: false }
  );
  return useQuery(
    [queryKey, id],
    async () => {
      return await axios
        .get(`/news-posts/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return response?.data?.data;
        });
    },
    { enabled: !!id }
  );
};
export { useGetNewsPost, useGetNewsPostById };
