import { Map } from 'mapbox-gl';
import React, { createContext, useContext, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { currentApi } from '../../../services/Db/dbInfo';
import { TripDetails } from '../../../services/Guide/guide';

// Create a context with default values
const MarkerDialogContext = createContext({
  loading: true,
  object: undefined,
  visualTrips: [] as TripDetails[],
  showMarkerDialog: (trip: any, target: any) => {},
  setMapBoxRef: (map: any) => {},
  setFilter: (
    search?: string,
    targetedSpecies?: number[],
    fishingTechniques?: number[],
    fishingTypes?: number[]
  ) => {},
  update: () => {},
});
let _loading: boolean = true;

// Create a provider component to wrap your app
export const MarkerDialogProvider = React.memo(({ children }: any) => {
  const [visualTrips, setVisualTrips] = useState<TripDetails[]>([]);
  const mapInitRef = useRef<Map | null>(null);
  const [loading, setLoading] = useState(_loading);

  const [object, setObject] = useState<any>(undefined);

  const showMarkerDialog = (trips: any, target: any) => {
    setObject({ trips, target });
  };

  const setMapBoxRef = (map: any) => {
    mapInitRef.current = map;
    setTimeout(() => {
      update();
    }, 200);
  };

  const setFilter = (
    search?: string,
    targetedSpecies?: number[],
    fishingTechniques?: number[],
    fishingTypes?: number[]
  ) => {
    if (!mapInitRef?.current) return;
    setLoading(true);

    let filter: any = ['all'];
    if (search) {
      filter.push(['in', search, ['get', 'names']]);
    }

    if (fishingTypes && fishingTypes?.length > 0) {
      fishingTypes.forEach((element) => {
        filter.push(['in', element, ['get', 'fishingTypes']]);
      });
    }

    if (targetedSpecies && targetedSpecies?.length > 0) {
      targetedSpecies.forEach((element) => {
        filter.push(['in', element, ['get', 'targetedSpecies']]);
      });
    }

    if (fishingTechniques && fishingTechniques?.length > 0) {
      fishingTechniques.forEach((element) => {
        filter.push(['in', element, ['get', 'fishingTechniques']]);
      });
    }

    if (filter.length > 1) {
      mapInitRef?.current?.setFilter('trip', filter);
    } else mapInitRef?.current?.setFilter('trip');

    var features = mapInitRef?.current?.queryRenderedFeatures(undefined, {
      layers: ['trip'], // Specify the layer(s) to query
    });
    let concatenatedArray: TripDetails[] = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        const trips = JSON.parse(feature?.properties?.trips);
        for (const array of trips) {
          concatenatedArray = concatenatedArray.concat(array);
        }
      });
    }

    setVisualTrips(concatenatedArray);
    setLoading(false);
  };

  const throttle = (func: Function, delay: number) => {
    let isThrottled = false;

    return (...args: any) => {
      if (!isThrottled) {
        func(...args);
        isThrottled = true;

        setTimeout(() => {
          isThrottled = false;
        }, delay);
      }
    };
  };

  const handleUpdateThrottled = throttle((value: string) => {
    if (!mapInitRef?.current) return;

    var features = mapInitRef?.current?.queryRenderedFeatures(undefined, {
      layers: ['trip'], // Specify the layer(s) to query
    });
    let concatenatedArray: TripDetails[] = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        const trips = JSON.parse(feature?.properties?.trips);
        for (const array of trips) {
          concatenatedArray = concatenatedArray.concat(array);
        }
      });
    }

    setVisualTrips(concatenatedArray);
    if (_loading) {
      _loading = false;
      setLoading(false);
    }
  }, 1000);

  const update = () => {
    _loading = true;
    if (!_loading) {
      _loading = true;
      setLoading(true);
    }
    handleUpdateThrottled();
  };

  // Use context provider to make the values available to the components
  return (
    <MarkerDialogContext.Provider
      value={{
        loading,
        object,
        visualTrips,
        showMarkerDialog,
        setMapBoxRef,
        update,
        setFilter,
      }}
    >
      {children}
    </MarkerDialogContext.Provider>
  );
});

// Custom hook to easily access the context values
export const useMarkerDialog = () => {
  return useContext(MarkerDialogContext);
};

// MarkerDialog component using the context
export const MarkerDialog = () => {
  const { object } = useMarkerDialog();
  return <>{object && <MapViewModelDialog object={object} />}</>;
};

const TripDetailComponent = (prop: any) => {
  const { trip } = prop;
  const { t } = useTranslation();
  return (
    <a href={`/trip-info/${trip?.id}`}>
      <div className="image-box">
        {trip.tripMedia && (
          <img
            style={{ height: '150px' }}
            src={`${currentApi.fileBasePath}${trip.tripMedia[0]?.url}`}
            alt={trip.name}
          />
        )}
        <div className="cd-gallery-content">
          <h3 className="text-uppercase">{`${trip.name}`}</h3>
          <p className="mb-0">
            {trip?.fishingTypes
              ?.map((item: any) => t(`master_data.fishing-type.${item.value}`))
              ?.join(', ')}
            {trip?.fishingTypes?.length > 0 && trip?.targetedSpecies?.length > 0 ? ', ' : ''}
            {trip?.targetedSpecies
              ?.map((item: any) => t(`master_data.fishing-specie.${item.value}`))
              ?.join(', ')}
            {trip?.targetedSpecies?.length > 0 && trip?.fishingTechniques.length > 0 ? ', ' : ''}
            {trip?.fishingTechniques
              ?.map((item: any) => t(`master_data.fishing-technique.${item.value}`))
              ?.join(', ')}
          </p>
        </div>
      </div>
    </a>
  );
};

interface ModelProps {
  object: any;
}
const MapViewModelDialog = (props: ModelProps) => {
  const { object } = props;
  return (
    <>
      {object && object.trips && (
        <Carousel controls={object.trips.length > 1 ? true : false} fade={true} slide={false}>
          {object.trips?.map((trip: TripDetails) => {
            return (
              <Carousel.Item key={`Popover_Carousel_${trip.id}`}>
                <TripDetailComponent trip={trip} />{' '}
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </>
  );
};
