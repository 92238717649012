import axios, { AxiosInstance } from 'axios';
import { currentApi } from '../services/Db/dbInfo';

// Create a custom Axios instance with the base URL
const api: AxiosInstance = axios.create({
  baseURL: currentApi.baseUriValue,
});

api.interceptors.request.use(
  (config) => {
    Reflect.set(config, 'baseURL', currentApi.baseUriValue);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Set up response interceptor
api.interceptors.response.use(
  (response) => {
    // Process the response data here if needed
    return response;
  },
  (error) => {
    let errorData = { message: '' };

    if (error.response) {
      errorData = error.response.data.error;
    } else if (error.request) {
      errorData = error.request;
    } else {
      errorData = error.message;
    }

    return Promise.reject(errorData);
  }
);

export default api;
