import { useEffect, useRef, useState } from 'react';

export default function useSticky<T extends HTMLElement>(top: number) {
  const stickyRef = useRef<T>(null);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    function observe() {
      if (!stickyRef.current) return;
      const refPageOffset = stickyRef.current.getBoundingClientRect().top;
      const stickyOffset = parseInt(getComputedStyle(stickyRef.current).top);
      const stickyActive = refPageOffset <= stickyOffset + top;

      if (stickyActive && !sticky) setSticky(true);
      else if (!stickyActive && sticky) setSticky(false);
    }
    observe();

    document.addEventListener('scroll', observe);
    window.addEventListener('resize', observe);
    window.addEventListener('orientationchange', observe);

    return () => {
      document.removeEventListener('scroll', observe);
      window.removeEventListener('resize', observe);
      window.removeEventListener('orientationchange', observe);
    };
  }, [sticky, top]);

  return [stickyRef, sticky] as const;
}
