import { lazy } from 'react';

const AsyncSelect = lazy(() => import('./AsyncSelect'));
const CheckBoxField = lazy(() => import('./CheckBoxField'));
const CurrencyInputField = lazy(() => import('./CurrencyInputField'));
const DateField = lazy(() => import('./DateField'));
const EmailField = lazy(() => import('./EmailField'));
const FileSelectionField = lazy(() => import('./FileSelectionField'));
const ImageUploadField = lazy(() => import('./ImageUploadField'));
const MultiCheckBoxField = lazy(() => import('./MultiCheckBoxField'));
const MultiSelectField = lazy(() => import('./MultiSelectField'));
const NumberField = lazy(() => import('./NumberField'));
const PhoneField = lazy(() => import('./PhoneField'));
const RadioField = lazy(() => import('./RadioField'));
const SelectField = lazy(() => import('./SelectField'));
const SelectFieldMapBox = lazy(() => import('./SelectFieldMapBox'));
const StaticDataMultiSelectField = lazy(() => import('./StaticDataMultiSelectField'));
const StaticDataSelectField = lazy(() => import('./StaticDataSelectField'));
const TextAreaField = lazy(() => import('./TextAreaField'));
const TextField = lazy(() => import('./TextField'));
const TimezoneField = lazy(() => import('./TimezoneField'));

export {
  AsyncSelect,
  CheckBoxField,
  CurrencyInputField,
  DateField,
  EmailField,
  FileSelectionField,
  ImageUploadField,
  MultiCheckBoxField,
  MultiSelectField,
  NumberField,
  PhoneField,
  RadioField,
  SelectField,
  SelectFieldMapBox,
  StaticDataMultiSelectField,
  StaticDataSelectField,
  TextAreaField,
  TextField,
  TimezoneField,
};
