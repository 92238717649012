import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../components/Spinner/Spinner';
import { useGetNewsPostById } from '../../services/News/newsService';
import NewsCarousel from './footercarousel';

function NewsView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: newsPost, isLoading: isNewsLoading } = useGetNewsPostById(Number(id));

  return (
    <div id="ledare_page">
      <div className="container">
        {isNewsLoading && <Spinner />}
        {!isNewsLoading && newsPost && (
          <>
            <div className="row text-center pt-sm-5 pt-md-5 pt-lg-5 pt-xl-4 pt-xl-0">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto p-sm-1 p-md-3 p-lg-4 p-xl-4">
                <div className="ledare_linti">
                  <p>{t('NEWS')}</p>
                  <h1>{newsPost?.title}</h1>
                </div>
                <div
                  className="ledare_bild p-xl-3"
                  style={{
                    minHeight: 600,
                    backgroundImage: `url(${newsPost?.image?.url})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                ></div>
              </div>
            </div>
            <div className="container paddding borderliner">
              <div className="row mx-0">
                <div
                  className="col-lg-8 mx-auto animate-box fadeInLeft animated-fast"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="newscontent">{parse(newsPost?.content)}</div>
                </div>
              </div>
            </div>
          </>
        )}
        <NewsCarousel />
      </div>
    </div>
  );
}

export default NewsView;
