import React, { useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModelStatus } from '../../../../services/Guide/guide';
import { scrollToTop } from '../../../../services/utils';
import BoatInfoModel from './boatInfoModel';
import BoatSummary from './boatSummary';
import { FormBoat } from './formBoat';

interface ListContextValue {
  remove: (index: number) => void;
  append: (index: number) => void;
}

export const ListContext = React.createContext<ListContextValue>({
  remove: () => {},
  append: () => {},
});

export const BoatListContainer = (props: any) => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  const { fields, remove, append } = useFieldArray({
    control,
    name: props.name,
  });

  const addHandler = () => {
    setSelectedFieldIndex(fields.length);
    props.setIsVisible(false);
    setEdit(false);
    append({ status: ModelStatus.New, wheelchairAccessible: false });
    scrollToTop();
  };

  const updateHandler = (index: number) => {
    props.setIsVisible(false);
    setSelectedFieldIndex(index);
    setEdit(true);
    scrollToTop();
  };

  const previewHandler = (index: number) => {
    setSelectedFieldIndex(index);
    setShowDialog(true);
  };

  const onSave = () => {
    props.setIsVisible(true);
    scrollToTop();
  };

  const onCancel = (index: number, id: string) => {
    props.setIsVisible(true);
    if (!id) {
      remove(index);
    }
    scrollToTop();
  };

  return (
    <div>
      <ListContext.Provider value={{ remove, append }}>
        {props.isVisible && (
          <div>
            <h5>{t('guide.boats.title')}</h5>
            <p className="mt-3 mb-4">{t('guide.boats.description')}</p>
          </div>
        )}

        {fields.length > 0 && !props.isVisible && (
          <FormBoat
            edit={edit}
            index={selectedFieldIndex}
            name={props.name}
            key={fields[selectedFieldIndex].id}
            onSave={onSave}
            onCancel={onCancel}
          />
        )}

        {fields.length > 0 && showDialog && (
          <BoatInfoModel
            show={showDialog}
            id={watch(`${props.name}[${selectedFieldIndex}].id`)}
            onClose={setShowDialog}
          />
        )}

        {props.isVisible &&
          fields &&
          fields.map((field: any, index: number) => (
            <BoatSummary
              key={`${props.name}[${index}]`}
              boatInfo={watch(`${props.name}[${index}]`)}
              index={index}
              updateHandler={updateHandler}
              previewHandler={previewHandler}
            />
          ))}

        {props.isVisible && (
          <div className="done_text mb-3 mt-3">
            <a
              className="don_icon"
              onClick={addHandler}
              data-toggle="collapse"
              href="#collapseInputBoat"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i className="ion-md-add"></i>
            </a>
          </div>
        )}
      </ListContext.Provider>
    </div>
  );
};
