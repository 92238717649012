import parse from 'html-react-parser';
// import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetAboutPage } from '../../services/User/userService';
import './style.css';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { data, isLoading } = useGetAboutPage();
  const { i18n } = useTranslation();
  const pageTitle = () => {
    switch (i18n.language) {
      case 'sv':
        return (data && data.svPageTitle) || '';
      case 'de':
        return (data && data.dePageTitle) || '';
      case 'fr':
        return (data && data.frPageTitle) || '';
      default:
        return (data && data.pageTitle) || '';
    }
  };

  const pageContent = () => {
    switch (i18n.language) {
      case 'sv':
        return (data && data.svContent) || '';
      case 'de':
        return (data && data.deContent) || '';
      case 'fr':
        return (data && data.frContent) || '';
      default:
        return (data && data.content) || '';
    }
  };

  return (
    <div className="info-page" style={{
      paddingBottom: 20 }}>
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: `url(${data && data.coverPicture ? data.coverPicture.url : "/img/image.jpg"})` }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h2 className="display_operator_name">{pageTitle()}</h2>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: '#FFF', marginTop: '-90px', maxWidth: '960px',
         }}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-5">
            <div className="display_operator_info mb-3">
              {isLoading && <ButtonSpinner />} {data && parse(pageContent())}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

