import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Spinner } from '../../../../components/Spinner/Spinner';
import useOptionData from '../../../../lib/optionData';
import { currentApi } from '../../../../services/Db/dbInfo';
import useTripHook from '../../../../services/Trip/tripService';
import { getSession } from '../../../../services/utils';
interface TripInfoProps {
  show: boolean;
  id: number;
  onClose: any;
}
const TripInfoModel = (props: TripInfoProps) => {
  const { onClose, show, id } = props;
  const { t } = useTranslation();

  const onHide = () => {
    if (onClose) onClose(false);
  };
  const { getTrip } = useTripHook({ id });
  const { data: tripInfo, isLoading } = getTrip;

  const images: ReactImageGalleryItem[] =
    tripInfo?.tripMedia?.map((media: any) => {
      return { original: `${currentApi.fileBasePath}${media.url}` };
    }) || [];

  const { tripTypesOptions } = useOptionData();
  return (
    <>
      <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ width: '100%' }}>
            {t('trip.trip_info')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px', lineHeight: 1.3, minHeight: '200px' }}>
          {!isLoading && tripInfo && (
            <>
              {images && images.length > 0 && (
                <ImageGallery
                  items={images}
                  lazyLoad={true}
                  showThumbnails={true}
                  showPlayButton={true}
                  thumbnailPosition={'bottom'}
                  additionalClass="image-gallery"
                />
              )}
              <div className="modal-body mx-3">
                <div className="md-form mt-2 mb-3">
                  <small>Type of Trip:</small>
                  <small className="text-muted">
                    {tripTypesOptions?.filter((obj: any) => obj.value === tripInfo.type)[0]?.label}
                  </small>
                  <br />
                  <small>{t('trip.fields.name.label')} :</small>
                  <small className="text-muted">{tripInfo?.name}</small>
                  <br />
                  <small>{t('trip.fields.description.label')} :</small>
                  <small className="text-muted">{tripInfo?.description}</small>
                </div>

                <div className="mt-2 mb-3">
                  <h6> {t('common.fishing')}</h6>
                  <small>{t('trip.fields.targetedSpecies.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo?.targetedSpecies
                      ?.map((item: any) => t(`master_data.fishing-specie.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('trip.fields.fishingTechniques.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo?.fishingTechniques
                      ?.map((item: any) => t(`master_data.fishing-technique.${item.value}`))
                      ?.join(', ')}
                  </small>
                </div>
                <div className="mt-2 mb-3">
                  <h6>{t('trip.trip_info')}</h6>
                  <small>{t('trip.fields.fishingTypes.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo?.fishingTypes?.map((item: any) => item.label)?.join(', ')}
                    {tripInfo?.fishingTypes
                      ?.map((item: any) => t(`master_data.fishing-type.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>{t('trip.fields.duration.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo.duration} {t('common.hours')}
                  </small>
                  <br />
                  <small>{t('trip.fields.departure.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo.departure} {t('common.am')}
                  </small>
                  <br />
                  <small>{t('trip.meeting_point')}:</small>
                  <small className="text-muted">{tripInfo.streetAddress}</small> <br />
                  <small>{t('trip.fields.pickupPolicy.label')}:</small>
                  <small className="text-muted">{tripInfo?.pickupPolicy?.label}</small> <br />
                  {tripInfo.maxAmountofPeople && (
                    <>
                      <small>{t('boat.fields.guestCapacity.label')}:</small>
                      <small className="text-muted">
                        {tripInfo.maxAmountofPeople} {t('common.guests')}
                      </small>
                      <br />
                    </>
                  )}
                  <small>{t('trip.fields.minAmountofPeople.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo.minAmountofPeople} {t('common.guests')}{' '}
                  </small>
                  <br />
                  <small>{t('trip.fields.minimumAge.label')}:</small>{' '}
                  <small className="text-muted">
                    {tripInfo.minimumAge} {t('common.years')}
                  </small>
                  <br />
                  <small>{t('trip.fields.isSharedTrip.label')}:</small>
                  <small className="text-muted">
                    {tripInfo?.isSharedTrip ? t('common.yes') : t('common.no')}
                  </small>
                </div>
                <div className="mt-2 mb-3">
                  <h6>{t('common.session_availibility')}</h6>
                  <small className="text-muted">
                    {getSession(
                      t,
                      tripInfo?.operateDays ? tripInfo?.operateDays : [],
                      tripInfo.isSeasonalTrip,
                      tripInfo?.season
                    )}
                  </small>
                </div>
                <div className="mt-2 mb-3">
                  <h6>{t('trip.trip_features')}</h6>
                  <small>All Necessary Fishing Gear:</small>
                  <small className="text-muted">
                    {tripInfo.isNecessaryGearIncluded
                      ? t('common.included')
                      : t('common.not_included')}
                  </small>
                  <br />
                  <small>Fishing License:</small>
                  <small className="text-muted">
                    {tripInfo.isFishingLicenseIncluded
                      ? t('common.included')
                      : t('common.not_included')}
                  </small>
                  <br />
                  <small>Catch Policy:</small>
                  <small className="text-muted">
                    {tripInfo?.catchPolicy
                      ?.map((item: any) => t(`master_data.catch-policy.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>Food & Beverages:</small>
                  <small className="text-muted">
                    {tripInfo?.foodAndBeverages
                      ?.map((item: any) => t(`master_data.food-and-beverage.${item.value}`))
                      ?.join(', ')}
                  </small>
                  <br />
                  <small>Alcohol policy:</small>
                  <small className="text-muted">
                    {tripInfo.alcoholAllowed ? t('common.allowed') : t('common.not_allowed')}
                  </small>
                </div>

                {tripInfo?.guide_boat && (
                  <div className="mt-2 mb-3">
                    <h6>Boat</h6>
                    <small>Boat type:</small>
                    <small className="text-muted">{tripInfo?.guide_boat?.boatType?.label}</small>
                    <br />
                    <small>Length of Boat: </small>{' '}
                    <small className="text-muted">{tripInfo?.guide_boat?.boatLength} ft</small>{' '}
                    <br />
                    <small>Boat Manufacturer: </small>{' '}
                    <small className="text-muted">{tripInfo?.guide_boat.boatManufacturer}</small>{' '}
                    <br />
                  </div>
                )}

                <div className="mt-2 mb-3">
                  <h6>Trip Cancellation Policy</h6>
                  <small>Cancellation Policy:</small>{' '}
                  <small className="text-muted">{tripInfo?.cancellationPolicy?.label}</small> <br />
                  <small>Free Cancelation:</small>{' '}
                  <small className="text-muted">3 Days in Advance</small> <br />
                </div>
              </div>
            </>
          )}
          {isLoading && <Spinner />}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default TripInfoModel;
