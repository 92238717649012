import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useGetNewsPost } from '../../services/News/newsService';
import { PaginationState } from '@tanstack/react-table';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

function NewsCarousel() {
  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 9,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };
  const { data: news, isLoading: isNewsLoading } = useGetNewsPost(fetchDataOptions);

  if (isNewsLoading) {
    return null;
  }

  const chunkArray = (array: Array<any>, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedNews = news?.data ? chunkArray(news.data, window.innerWidth === 1024 ? 2 : window.innerWidth < 1024 ? 1 : 3) : [];
  if (!news?.data || news?.data.length === 0) {
    return null;
  }

  console.log('chunkedNews', window.innerWidth, chunkedNews);

  return (
    <div className="p-1">
      <Carousel indicators={false} wrap={true}>
        {chunkedNews.map((newsItems, index) => {
          return newsItems.length ===
            (window.innerWidth === 1024 ? 2 : window.innerWidth < 1024 ? 1 : 3) ? (
            <Carousel.Item className="carousel-item" key={`ci-${index}`}>
              <div className="cards-wrapper">
                {newsItems.map((item) => {
                  return (
                    <div className="card" key={item.id}>
                      <NavLink
                        to={
                          item.type === 'Chronicle' ? `/chronicle/${item.id}` : `/news/${item.id}`
                        }
                      >
                        <div className="hover-me">
                          <img src={item.image?.url} className="card-img-top" alt="..." />
                          <div className="card-body">
                            <h5 className="card-title">
                              {moment(item?.updatedAt).format('MMM DD, YYYY')}{' '}
                            </h5>
                            <p className="card-text">{item?.title}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </Carousel.Item>
          ) : null;
        })}
      </Carousel>
    </div>
  );
}

export default NewsCarousel;
