import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import qs from 'qs';
import axios from '../../lib/client';
import { queryClient } from '../../lib/react-query';
import { toConversation } from '../Db/utils';

const queryKey = ['Conversation'];
interface IOptions extends IPage {
  guideId: number;
}
interface IPage {
  pageIndex: number;
  pageSize: number;
}

const useGetUserConversation = (fetchDataOptions: IPage) => {
  return useQuery(
    ['UserConversation', fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          fields: ['buyer_status', 'seller_status'],
          populate: {
            guidetrip: {
              fields: ['id', 'name'],
              populate: {
                guide: {
                  fields: ['id', 'businessName'],
                  populate: {
                    profilePhoto: {
                      fields: ['url'],
                    },
                  },
                },
              },
            },
            buyer: {
              fields: ['id', 'username'],
            },
            seller: {
              fields: ['id', 'username'],
            },
            last_message: {
              populate: '*',
            },
          },
          pagination: {
            pageSize: `${fetchDataOptions.pageSize}`,
            page: `${fetchDataOptions.pageIndex}`,
          },
        },
        { encode: false }
      );

      return await axios.get(`/conversations?${queryParams}`).then(function (response: any) {
        const data = response.data?.data?.map((conversation: any) => {
          return toConversation(conversation);
        });
        return { data: data, meta: response.data?.meta };
      });
    },
    { keepPreviousData: true }
  );
};

const useGetUnreadConversation = (isGuide: boolean) => {
  return useQuery(
    ['UserConversationUnread'],
    async () => {
      let filter: any = {};
      if (isGuide) {
        filter.seller_status = 'Unread';
      } else {
        filter.buyer_status = 'Unread';
      }
      const queryParams = qs.stringify(
        {
          fields: ['buyer_status', 'seller_status'],
          populate: {
            guidetrip: {
              fields: ['id', 'name'],
              populate: {
                guide: {
                  fields: ['id', 'businessName'],
                  populate: {
                    profilePhoto: {
                      fields: ['url'],
                    },
                  },
                },
              },
            },
            buyer: {
              fields: ['id', 'username'],
            },
            seller: {
              fields: ['id', 'username'],
            },
            last_message: {
              populate: '*',
            },
          },
          filters: filter,
        },
        { encode: false }
      );

      return await axios.get(`/conversations?${queryParams}`).then(function (response: any) {
        const data = response.data?.data?.map((conversation: any) => {
          return toConversation(conversation);
        });
        return { data: data, meta: response.data?.meta };
      });
    },
    { keepPreviousData: true }
  );
};

const useGetGuideConversation = (fetchDataOptions: IOptions) => {
  return useQuery(
    ['conversation', fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          filters: {
            guidetrip: {
              guide: {
                id: {
                  $eq: `${fetchDataOptions.guideId}`,
                },
              },
            },
          },
          fields: ['buyer_status', 'seller_status'],
          populate: {
            guidetrip: {
              fields: ['id', 'name'],
              populate: {
                guide: {
                  fields: ['id', 'businessName'],
                  populate: {
                    profilePhoto: {
                      fields: ['url'],
                    },
                  },
                },
              },
            },
            buyer: {
              fields: ['id', 'username'],
            },
            seller: {
              fields: ['id', 'username'],
            },
            last_message: {
              populate: '*',
            },
          },
          pagination: {
            pageSize: `${fetchDataOptions.pageSize}`,
            page: `${fetchDataOptions.pageIndex}`,
          },
          sort: ['updatedAt:asc'],
        },
        { encode: false }
      );

      return await axios.get(`/conversations?${queryParams}`).then(function (response: any) {
        const data = response.data?.data?.map((conversation: any) => {
          return toConversation(conversation);
        });
        return { data: data, meta: response.data?.meta };
      });
    },
    { keepPreviousData: true, enabled: !!fetchDataOptions.guideId }
  );
};
const useGetGuideUnreadMessageCount = (guideId: number) => {
  return useQuery(
    ['MessageCount', guideId],
    async () => {
      const queryParams = qs.stringify(
        {
          filters: {
            conversation: {
              guidetrip: {
                guide: {
                  id: {
                    $eq: `${guideId}`,
                  },
                },
              },
            },
          },
        },
        { encode: false }
      );

      return await axios
        .get(`/message-statuses/unread-count?${queryParams}`)
        .then(function (response: any) {
          return response.data;
        });
    },
    { enabled: !!guideId }
  );
};
const useGetUserUnreadMessageCount = () => {
  return useQuery(['MessageCount'], async () => {
    return await axios.get(`/message-statuses/unread-count`).then(function (response: any) {
      return response.data;
    });
  });
};

const useSendBookingRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: any) => {
      await axios.post(`/send_booking_request`, { data: data }).then(function (response: any) {
        let result: any = response.data.data;
        return result;
      });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );
};

const useSendMessage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: any) => {
      await axios.post(`/messages/`, { data: data }).then(function (response: any) {
        let result: any = response.data.data;
        return result;
      });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
        queryClient.invalidateQueries(['MessageCount']);
      },
    }
  );
};

const useGetAllMessage = (conversationId: number) => {
  return useQuery<any>(
    [queryKey, conversationId],
    async () => {
      const queryParams = qs.stringify(
        {
          populate: '*',
        },
        { encode: false }
      );

      return await axios
        .get(`/getAllMessageByConversationId/${conversationId}?${queryParams}`)
        .then(function (response: any) {
          return response.data;
        });
    },
    { keepPreviousData: true }
  );
};

const useUpdateConversationStatus = () => {
  return useMutation<any, Error, any>(
    async ({ id }) => {
      await axios
        .put(`/conversation/update_conversation_status/${id}`, { data: {} })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['MessageCount']);
        queryClient.invalidateQueries(['UserConversationUnread']);
      },
    }
  );
};

export {
  useGetAllMessage,
  useGetGuideConversation,
  useGetGuideUnreadMessageCount,
  useGetUnreadConversation,
  useGetUserConversation,
  useGetUserUnreadMessageCount,
  useSendBookingRequest,
  useSendMessage,
  useUpdateConversationStatus,
};
