const en = {
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  WF: 'Wallis and Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Russia',
  RW: 'Rwanda',
  RS: 'Serbia',
  TL: 'East Timor',
  RE: 'Reunion',
  TM: 'Turkmenistan',
  TJ: 'Tajikistan',
  RO: 'Romania',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia and the South Sandwich Islands',
  GR: 'Greece',
  GQ: 'Equatorial Guinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'French Guiana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'United Kingdom',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Greenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisia',
  JO: 'Jordan',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island and McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard and Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Iraq',
  PA: 'Panama',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  ZM: 'Zambia',
  EH: 'Western Sahara',
  EE: 'Estonia',
  EG: 'Egypt',
  ZA: 'South Africa',
  EC: 'Ecuador',
  IT: 'Italy',
  VN: 'Vietnam',
  SB: 'Solomon Islands',
  ET: 'Ethiopia',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi Arabia',
  ES: 'Spain',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldova',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Morocco',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolia',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Northern Mariana Islands',
  MS: 'Montserrat',
  MR: 'Mauritania',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexico',
  IL: 'Israel',
  FR: 'France',
  IO: 'British Indian Ocean Territory',
  SH: 'Saint Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NZ: 'New Zealand',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cook Islands',
  XK: 'Kosovo',
  CI: 'Ivory Coast',
  CH: 'Switzerland',
  CO: 'Colombia',
  CN: 'China',
  CM: 'Cameroon',
  CL: 'Chile',
  CC: 'Cocos Islands',
  CA: 'Canada',
  CG: 'Republic of the Congo',
  CF: 'Central African Republic',
  CD: 'Democratic Republic of the Congo',
  CZ: 'Czech Republic',
  CY: 'Cyprus',
  CX: 'Christmas Island',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cape Verde',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syria',
  SX: 'Sint Maarten',
  KG: 'Kyrgyzstan',
  KE: 'Kenya',
  SS: 'South Sudan',
  SR: 'Suriname',
  KI: 'Kiribati',
  KH: 'Cambodia',
  KN: 'Saint Kitts and Nevis',
  KM: 'Comoros',
  ST: 'Sao Tome and Principe',
  SK: 'Slovakia',
  KR: 'South Korea',
  SI: 'Slovenia',
  KP: 'North Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Cayman Islands',
  SG: 'Singapore',
  SE: 'Sweden',
  SD: 'Sudan',
  DO: 'Dominican Republic',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Denmark',
  VG: 'British Virgin Islands',
  DE: 'Germany',
  YE: 'Yemen',
  DZ: 'Algeria',
  US: 'United States',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'United States Minor Outlying Islands',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Latvia',
  TO: 'Tonga',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TD: 'Chad',
  TC: 'Turks and Caicos Islands',
  LY: 'Libya',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  AE: 'United Arab Emirates',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'U.S. Virgin Islands',
  IS: 'Iceland',
  IR: 'Iran',
  AM: 'Armenia',
  AL: 'Albania',
  AO: 'Angola',
  AQ: 'Antarctica',
  AS: 'American Samoa',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AW: 'Aruba',
  IN: 'India',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  IE: 'Ireland',
  ID: 'Indonesia',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique',
};

const fr = {
  BD: 'Bangladesh',
  BE: 'Belgique',
  BF: 'Burkina Faso',
  BG: 'Bulgarie',
  BA: 'Bosnie Herzégovine',
  BB: 'Barbade',
  WF: 'Wallis et Futuna',
  BL: 'Saint Barthélemy',
  BM: 'Bermudes',
  BN: 'Brunéi',
  BO: 'Bolivie',
  BH: 'Bahreïn',
  BI: 'Burundi',
  BJ: 'Bénin',
  BT: 'Bhoutan',
  JM: 'Jamaïque',
  BV: 'Île Bouvet',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint-Eustache et Saba ',
  BR: 'Brésil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Biélorussie',
  BZ: 'Bélize',
  RU: 'Russie',
  RW: 'Rwanda',
  RS: 'Serbie',
  TL: 'Timor oriental',
  RE: 'Réunion',
  TM: 'Turkménistan',
  TJ: 'Tadjikistan',
  RO: 'Roumanie',
  TK: 'Tokélaou',
  GW: 'Guinée-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'Géorgie du Sud et îles Sandwich du Sud',
  GR: 'Grèce',
  GQ: 'Guinée Équatoriale',
  GP: 'Guadeloupe',
  JP: 'Japon',
  GY: 'Guyane',
  GG: 'Guernesey',
  GF: 'Guyane Française',
  GE: 'Géorgie',
  GD: 'Grenade',
  GB: 'Royaume-Uni',
  GA: 'Gabon',
  SV: 'Le Salvador',
  GN: 'Guinée',
  GM: 'Gambie',
  GL: 'Groenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisie',
  JO: 'Jordan',
  HR: 'Croatie',
  HT: 'Haïti',
  HU: 'Hongrie',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Île Heard et îles McDonald',
  VE: 'Venezuela',
  PR: 'Porto Rico',
  PS: 'Territoire Palestinien',
  PW: 'Palaos',
  PT: 'le Portugal',
  SJ: 'Svalbard et Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Irak',
  PA: 'Panama',
  PF: 'Polynésie française',
  PG: 'Papouasie Nouvelle Guinée',
  PE: 'Pérou',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Pologne',
  PM: 'Saint-Pierre-et-Miquelon',
  ZM: 'Zambie',
  EH: 'Sahara occidental',
  EE: 'Estonie',
  EG: 'Egypte',
  ZA: 'Afrique du Sud',
  EC: 'Équateur',
  IT: 'Italie',
  VN: 'Viêt Nam',
  SB: 'Les îles Salomon',
  ET: 'Ethiopie',
  SO: 'Somalie',
  ZW: 'Zimbabwe',
  SA: 'Arabie Saoudite',
  ES: 'Espagne',
  ER: 'Érythrée',
  ME: 'Monténégro',
  MD: 'Moldavie',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Maroc',
  MC: 'Monaco',
  UZ: 'Ouzbékistan',
  MM: 'Birmanie',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolie',
  MH: 'Iles Marshall',
  MK: 'Macédoine',
  MU: 'Maurice',
  MT: 'Malte',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Îles Mariannes du Nord',
  MS: 'Montserrat',
  MR: 'Mauritanie',
  IM: 'île de Man',
  UG: 'Ouganda',
  TZ: 'Tanzanie',
  MY: 'Malaisie',
  MX: 'Mexique',
  IL: 'Israël',
  FR: 'France',
  IO: "Territoire britannique de l'océan Indien",
  SH: 'Sainte-Hélène',
  FI: 'Finlande',
  FJ: 'Fidji',
  FK: 'les îles Falkland',
  FM: 'Micronésie',
  FO: 'Îles Féroé',
  NI: 'Nicaragua',
  NL: 'Pays-Bas',
  NO: 'Norvège',
  NA: 'Namibie',
  VU: 'Vanuatu',
  NC: 'Nouvelle Calédonie',
  NE: 'Niger',
  NF: "l'ile de Norfolk",
  NG: 'Nigeria',
  NZ: 'Nouvelle-Zélande',
  NP: 'Népal',
  NR: 'Nauru',
  NU: 'Nioué',
  CK: 'les Îles Cook',
  XK: 'Kosovo',
  CI: "Côte d'Ivoire",
  CH: 'Suisse',
  CO: 'Colombie',
  CN: 'Chine',
  CM: 'Cameroun',
  CL: 'Chili',
  CC: 'Îles Cocos',
  CA: 'Canada',
  CG: 'République du Congo',
  CF: 'République centrafricaine',
  CD: 'République Démocratique du Congo',
  CZ: 'République tchèque',
  CY: 'Chypre',
  CX: "L'île de noël",
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cap-Vert',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syrie',
  SX: 'Saint-Martin',
  KG: 'Kirghizistan',
  KE: 'Kenya',
  SS: 'Soudan du sud',
  SR: 'Surinam',
  KI: 'Kiribati',
  KH: 'Cambodge',
  KN: 'Saint-Christophe-et-Niévès',
  KM: 'Comores',
  ST: 'Sao Tomé et Principe',
  SK: 'Slovaquie',
  KR: 'Corée du Sud',
  SI: 'Slovénie',
  KP: 'Corée du Nord',
  KW: 'Koweit',
  SN: 'Sénégal',
  SM: 'Saint Marin',
  SL: 'Sierra Leone',
  SC: 'les Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Îles Caïmans',
  SG: 'Singapour',
  SE: 'Suède',
  SD: 'Soudan',
  DO: 'République dominicaine',
  DM: 'Dominique',
  DJ: 'Djibouti',
  DK: 'Danemark',
  VG: 'Îles Vierges britanniques',
  DE: 'Allemagne',
  YE: 'Yémen',
  DZ: 'Algérie',
  US: 'États-Unis',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'Îles mineures éloignées des États-Unis',
  LB: 'Liban',
  LC: 'Sainte-Lucie',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taïwan',
  TT: 'Trinité-et-Tobago',
  TR: 'Turquie',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Lettonie',
  TO: 'Tonga',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  LR: 'Libéria',
  LS: 'Lesotho',
  TH: 'Thaïlande',
  TF: 'Terres australes françaises',
  TG: 'Aller',
  TD: 'Tchad',
  TC: 'îles Turques-et-Caïques',
  LY: 'Libye',
  VA: 'Vatican',
  VC: 'Saint-Vincent-et-les-Grenadines',
  AE: 'Emirats Arabes Unis',
  AD: 'Andorre',
  AG: 'Antigua-et-Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'Îles Vierges américaines',
  IS: 'Islande',
  IR: "L'Iran",
  AM: 'Arménie',
  AL: 'Albanie',
  AO: 'Angola',
  AQ: 'Antarctique',
  AS: 'Samoa américaines',
  AR: 'Argentine',
  AU: 'Australie',
  AT: "L'Autriche",
  AW: 'Aruba',
  IN: 'Inde',
  AX: 'Iles Aland',
  AZ: 'Azerbaïdjan',
  IE: 'Irlande',
  ID: 'Indonésie',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique',
};

const sv = {
  BD: 'Bangladesh',
  BE: 'Belgien',
  BF: 'Burkina Faso',
  BG: 'Bulgarien',
  BA: 'Bosnien och Hercegovina',
  BB: 'Barbados',
  WF: 'Wallis och Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvetön',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius och Saba ',
  BR: 'Brasilien',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Ryssland',
  RW: 'Rwanda',
  RS: 'Serbien',
  TL: 'Östtimor',
  RE: 'Återförening',
  TM: 'Turkmenistan',
  TJ: 'Tadzjikistan',
  RO: 'Rumänien',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia och South Sandwich Islands',
  GR: 'Grekland',
  GQ: 'Ekvatorialguinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'Franska Guyana',
  GE: 'Georgien',
  GD: 'Grenada',
  GB: 'Storbritannien',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Grönland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'oman',
  TN: 'Tunisien',
  JO: 'Jordanien',
  HR: 'Kroatien',
  HT: 'Haiti',
  HU: 'Ungern',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island och McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinskt territorium',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard och Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Irak',
  PA: 'Panama',
  PF: 'Franska Polynesien',
  PG: 'Papua Nya Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Filippinerna',
  PN: 'Pitcairn',
  PL: 'Polen',
  PM: 'Saint Pierre och Miquelon',
  ZM: 'Zambia',
  EH: 'Västra Sahara',
  EE: 'Estland',
  EG: 'Egypten',
  ZA: 'Sydafrika',
  EC: 'Ecuador',
  IT: 'Italien',
  VN: 'Vietnam',
  SB: 'Salomonöarna',
  ET: 'Etiopien',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudiarabien',
  ES: 'Spanien',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldavien',
  MG: 'Madagaskar',
  MF: 'Sankt Martin',
  MA: 'Marocko',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'mongoliet',
  MH: 'Marshallöarna',
  MK: 'Makedonien',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldiverna',
  MQ: 'Martinique',
  MP: 'Nordmarianerna',
  MS: 'Montserrat',
  MR: 'Mauretanien',
  IM: 'ö av man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexiko',
  IL: 'Israel',
  FR: 'Frankrike',
  IO: 'Brittiska territoriet i Indiska oceanen',
  SH: 'Sankta Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falklandsöarna',
  FM: 'Mikronesien',
  FO: 'Färöarna',
  NI: 'Nicaragua',
  NL: 'Nederländerna',
  NO: 'Norge',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'Nya Kaledonien',
  NE: 'Niger',
  NF: 'Norfolkön',
  NG: 'Nigeria',
  NZ: 'Nya Zeeland',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cooköarna',
  XK: 'Kosovo',
  CI: 'Elfenbenskusten',
  CH: 'Schweiz',
  CO: 'Colombia',
  CN: 'Kina',
  CM: 'Kamerun',
  CL: 'Chile',
  CC: 'Cocosöarna',
  CA: 'Kanada',
  CG: 'Republiken Kongo',
  CF: 'Centralafrikanska republiken',
  CD: 'Demokratiska republiken Kongo',
  CZ: 'Tjeckien',
  CY: 'Cypern',
  CX: 'Julön',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cap Verde',
  CU: 'Kuba',
  SZ: 'Swaziland',
  SY: 'Syrien',
  SX: 'Sint Maarten',
  KG: 'Kirgizistan',
  KE: 'Kenya',
  SS: 'södra Sudan',
  SR: 'Surinam',
  KI: 'Kiribati',
  KH: 'Kambodja',
  KN: 'Saint Kitts och Nevis',
  KM: 'Komorerna',
  ST: 'Sao Tomé och Principe',
  SK: 'Slovakien',
  KR: 'Sydkorea',
  SI: 'Slovenien',
  KP: 'Nordkorea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychellerna',
  KZ: 'Kazakstan',
  KY: 'Caymanöarna',
  SG: 'Singapore',
  SE: 'Sverige',
  SD: 'Sudan',
  DO: 'Dominikanska republiken',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Danmark',
  VG: 'Brittiska Jungfruöarna',
  DE: 'Tyskland',
  YE: 'Jemen',
  DZ: 'Algeriet',
  US: 'Förenta staterna',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'Förenta staternas mindre avlägsna öar',
  LB: 'Libanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad och Tobago',
  TR: 'Kalkon',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Lettland',
  TO: 'Tonga',
  LT: 'Litauen',
  LU: 'Luxemburg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'franska södra territorierna',
  TG: 'Togo',
  TD: 'Tchad',
  TC: 'Turks- och Caicosöarna',
  LY: 'Libyen',
  VA: 'Vatikanen',
  VC: 'Saint Vincent och Grenadinerna',
  AE: 'Förenade arabemiraten',
  AD: 'Andorra',
  AG: 'Antigua och Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'Amerikanska Jungfruöarna',
  IS: 'Island',
  IR: 'Iran',
  AM: 'Armenien',
  AL: 'Albanien',
  AO: 'Angola',
  AQ: 'Antarktis',
  AS: 'Amerikanska Samoa',
  AR: 'Argentina',
  AU: 'Australien',
  AT: 'Österrike',
  AW: 'Aruba',
  IN: 'Indien',
  AX: 'Ålandöarna',
  AZ: 'Azerbajdzjan',
  IE: 'Irland',
  ID: 'Indonesien',
  UA: 'Ukraina',
  QA: 'Qatar',
  MZ: 'Moçambique',
};

const de = {
  BD: 'Bangladesch',
  BE: 'Belgien',
  BF: 'Burkina Faso',
  BG: 'Bulgarien',
  BA: 'Bosnien und Herzegowina',
  BB: 'Barbados',
  WF: 'Wallis und Futuna',
  BL: 'Sankt Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivien',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaika',
  BV: 'Bouvetinsel',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Sint Eustatius und Saba ',
  BR: 'Brasilien',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Weißrussland',
  BZ: 'Belize',
  RU: 'Russland',
  RW: 'Ruanda',
  RS: 'Serbien',
  TL: 'Osttimor',
  RE: 'Wiedervereinigung',
  TM: 'Turkmenistan',
  TJ: 'Tadschikistan',
  RO: 'Rumänien',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'Süd-Georgien und die südlichen Sandwich-Inseln',
  GR: 'Griechenland',
  GQ: 'Äquatorialguinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'Französisch-Guayana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'Großbritannien',
  GA: 'Gabun',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Grönland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunesien',
  JO: 'Jordanien',
  HR: 'Kroatien',
  HT: 'Haiti',
  HU: 'Ungarn',
  HK: 'Hongkong',
  HN: 'Honduras',
  HM: 'Heard-Insel und McDonald-Inseln',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palästinensisches Gebiet',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Spitzbergen und Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Irak',
  PA: 'Panama',
  PF: 'Französisch Polynesien',
  PG: 'Papua Neu-Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippinen',
  PN: 'Pitcairn',
  PL: 'Polen',
  PM: 'Saint-Pierre und Miquelon',
  ZM: 'Sambia',
  EH: 'Westsahara',
  EE: 'Estland',
  EG: 'Ägypten',
  ZA: 'Südafrika',
  EC: 'Ecuador',
  IT: 'Italien',
  VN: 'Vietnam',
  SB: 'Salomon-Inseln',
  ET: 'Äthiopien',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi-Arabien',
  ES: 'Spanien',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldawien',
  MG: 'Madagaskar',
  MF: 'Sankt Martin',
  MA: 'Marokko',
  MC: 'Monaco',
  UZ: 'Usbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macau',
  MN: 'Mongolei',
  MH: 'Marshallinseln',
  MK: 'Mazedonien',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Malediven',
  MQ: 'Martinique',
  MP: 'Nördliche Marianneninseln',
  MS: 'Montserrat',
  MR: 'Mauretanien',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tansania',
  MY: 'Malaysia',
  MX: 'Mexiko',
  IL: 'Israel',
  FR: 'Frankreich',
  IO: 'Britisches Territorium des Indischen Ozeans',
  SH: 'St. Helena',
  FI: 'Finnland',
  FJ: 'Fidschi',
  FK: 'Falkland Inseln',
  FM: 'Mikronesien',
  FO: 'Färöer Inseln',
  NI: 'Nicaragua',
  NL: 'Niederlande',
  NO: 'Norwegen',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'Neu-Kaledonien',
  NE: 'Niger',
  NF: 'Norfolkinsel',
  NG: 'Nigeria',
  NZ: 'Neuseeland',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cookinseln',
  XK: 'Kosovo',
  CI: 'Elfenbeinküste',
  CH: 'Schweiz',
  CO: 'Kolumbien',
  CN: 'China',
  CM: 'Kamerun',
  CL: 'Chile',
  CC: 'Kokosinseln',
  CA: 'Kanada',
  CG: 'Republik Kongo',
  CF: 'Zentralafrikanische Republik',
  CD: 'Demokratische Republik Kongo',
  CZ: 'Tschechien',
  CY: 'Zypern',
  CX: 'Weihnachtsinsel',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Kap Verde',
  CU: 'Kuba',
  SZ: 'Swasiland',
  SY: 'Syrien',
  SX: 'Sint Maarten',
  KG: 'Kirgisistan',
  KE: 'Kenia',
  SS: 'Südsudan',
  SR: 'Surinam',
  KI: 'Kiribati',
  KH: 'Kambodscha',
  KN: 'St. Kitts und Nevis',
  KM: 'Komoren',
  ST: 'São Tomé und Príncipe',
  SK: 'Slowakei',
  KR: 'Südkorea',
  SI: 'Slowenien',
  KP: 'Nord Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychellen',
  KZ: 'Kasachstan',
  KY: 'Cayman Inseln',
  SG: 'Singapur',
  SE: 'Schweden',
  SD: 'Sudan',
  DO: 'Dominikanische Republik',
  DM: 'Dominica',
  DJ: 'Dschibuti',
  DK: 'Dänemark',
  VG: 'Britische Jungferninseln',
  DE: 'Deutschland',
  YE: 'Jemen',
  DZ: 'Algerien',
  US: 'Vereinigte Staaten',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'Kleinere vorgelagerte Inseln der Vereinigten Staaten',
  LB: 'Libanon',
  LC: 'St. Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad und Tobago',
  TR: 'Truthahn',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Lettland',
  TO: 'Tonga',
  LT: 'Litauen',
  LU: 'Luxemburg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'Südfranzösische Territorien',
  TG: 'Gehen',
  TD: 'Tschad',
  TC: 'Turks- und Caicosinseln',
  LY: 'Libyen',
  VA: 'Vatikan',
  VC: 'St. Vincent und die Grenadinen',
  AE: 'Vereinigte Arabische Emirate',
  AD: 'Andorra',
  AG: 'Antigua und Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'US Jungferninseln',
  IS: 'Island',
  IR: 'Iran',
  AM: 'Armenien',
  AL: 'Albanien',
  AO: 'Angola',
  AQ: 'Antarktis',
  AS: 'Amerikanischen Samoa-Inseln',
  AR: 'Argentinien',
  AU: 'Australien',
  AT: 'Österreich',
  AW: 'Aruba',
  IN: 'Indien',
  AX: 'Aland-Inseln',
  AZ: 'Aserbaidschan',
  IE: 'Irland',
  ID: 'Indonesien',
  UA: 'Ukraine',
  QA: 'Katar',
  MZ: 'Mosambik',
};

export function getCountries(code: string): any {
  let countries: any = en;
  if (code === 'sv') {
    countries = sv;
  }

  if (code === 'de-DE') {
    countries = de;
  }

  if (code === 'fr') {
    countries = fr;
  }
  const resultArray = Object.keys(countries).map((key) => ({
    label: countries[key],
    value: key,
  }));

  return resultArray;
}

export function getCountry(code: string, lang_code: string): any {
  let countries: any = en;
  if (lang_code === 'sv') {
    countries = sv;
  }

  if (lang_code === 'de-DE') {
    countries = de;
  }

  if (lang_code === 'fr') {
    countries = fr;
  }
  return countries[code ? code.toUpperCase() : ''];
}
