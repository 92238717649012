import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Register({ setShowUSPs }: { setShowUSPs: any }) {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);

  const renderStep1 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">{parse(t('register_page.step.1.title'))}</h2>

        <p className="registerfont">
          {t('register_page.step.1.description1')} <br />
          {t('register_page.step.1.description2')}
        </p>

        <button className="btn btn-gold mt-3" type="button" onClick={() => setStep(2)}>
          {t('common.next')}
        </button>
        <div className="text-center"></div>
      </div>
    );
  };

  const renderStep2 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">{t('register_page.step.2.title')}</h2>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading1')}</span>
          <br />
          {t('register_page.step.2.heading1_description')}
        </p>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading2')}</span>
          <br />
          {t('register_page.step.2.heading2_description')}
        </p>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading3')}</span>
          <br />
          {t('register_page.step.2.heading3_description')}
        </p>
        <button className="btn btn-gold mt-3" type="button" onClick={() => setStep(1)}>
          {t('common.previous')}
        </button>
        &nbsp;
        <button className="btn btn-gold mt-3" type="button" onClick={() => setShowUSPs(false)}>
          {t('common.next')}
        </button>
        <div className="text-center"></div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className={`d-none d-md-flex col-md-4 col-lg-6 register-bg-image${step}`}></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
