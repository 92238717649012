import parse from 'html-react-parser';
import { useContext } from 'react';
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import { useGetFAQItemsByCategoryId } from '../../../services/User/userService';

const cardStyle = {
  borderLeft: 'none',
  borderRight: 'none',
  borderRadius: '0px',
  padding: '15px',
};

const FAQList = ({ id, type }: { id: number; type: string }) => {
  const { t } = useTranslation();
  const { data: faqs, isLoading } = useGetFAQItemsByCategoryId(id, type);

  if (isLoading) return <ButtonSpinner />;

  return (
    <>
      {faqs &&
        faqs.map((faq: any) => (
          <Card
            key={faq.id} // Add a unique key prop
            style={cardStyle}
          >
            <Card.Header className="d-flex justify-content-between">
              <ContextAwareToggle eventKey={faq.id}>{faq.Title}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={faq.id}>
              <Card.Body className="faq-body"> {parse(faq.Body)}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}

      {faqs && faqs.length === 0 && (
        <Card style={cardStyle}>
          <Card.Header className="d-flex justify-content-between">
            {t('common.no_data_available')}
          </Card.Header>
        </Card>
      )}
    </>
  );
};

function ContextAwareToggle({ children, eventKey, callback }: any) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{ cursor: 'pointer' }}
      className={isCurrentEventKey ? '' : 'collapsed'}
      onClick={decoratedOnClick}
    >
      {children}
    </a>
  );
}
export default FAQList;
