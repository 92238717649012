import { useUser } from '../../lib/auth';
import GuideMessages from './components/GuideMessages';
import UserMessages from './components/UserMessages';

function MessagesComponent() {
  const { data: userInfo } = useUser();

  const isGuide = userInfo?.isGuide || false;

  return <>{isGuide ? <GuideMessages /> : <UserMessages />}</>;
}

export default MessagesComponent;
