import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetBookMarkList } from '../../services/Bookmark/bookmarkService';
import FavoriteCardList from './components/FavoriteCardList';

const Favorites = () => {
  const fetchDataOptions = {
    pageIndex: 0,
    pageSize: 20,
  };
  const { t } = useTranslation();

  const { data, isLoading } = useGetBookMarkList(fetchDataOptions);

  return (
    <div className="content">
      <Row>
        {!isLoading && data && data?.data?.length > 0 && <FavoriteCardList data={data?.data} />}
        {!isLoading && data && data?.data?.length === 0 && (
          <>
            <div style={{ textAlign: 'center' }}>{t('favorite.no_data_found')}</div>
          </>
        )}

        {isLoading && <ButtonSpinner />}
      </Row>
    </div>
  );
};

export default Favorites;
