export enum Models {
  guide = 'api::guide.guide',
  guideBoats = 'api::guide-boat.guide-boat',
  guideTrips = 'api::guidetrip.guidetrip',
  guideOptions = 'api::guideoptions.guideoptions',
  user = 'api::user.user',
  uspInformation = 'api::uspInformation.uspInformation',
}

export enum Fields {
  profilePhoto,
  businessMedia,
}

export enum NavLinks {
  signin,
  register,
  message,
  guideupdate,
}

export enum GuideStatus {
  unsent,
  evaluating,
  accepted,
  archived,
}
