import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import qs from 'qs';
import axios from '../../lib/client';
import { guideLastDraftVersionQueryParams } from '../../lib/qs';
import { ApiHandler } from '../Db/apiHandler';
import { toGuideDetails, toGuideDetailsDto } from '../Db/utils';
import { GuideStatus } from '../Generic/strapiRefs';
import { GuideDetails } from './guide';

const guideKeys = {
  create: () => ['guide'],
  getListing: () => ['guideList'],
  getListingById: (id: number) => [...guideKeys.getListing(), id],
};

export default function useGuidesHook(props: any) {
  const { id = undefined } = props;

  const queryClient = useQueryClient();

  const createGuideDetails = useMutation(
    async (obj: any) => await ApiHandler.saveGuideDetails(obj.guideId, obj.userId),
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries(guideKeys.getListing());
      },
    }
  );

  const postGuide = useMutation(
    async (guideDetails: GuideDetails) => {
      let data: GuideDetailsDto = toGuideDetailsDto(guideDetails);
      data.status = GuideStatus[GuideStatus.evaluating];
      data.publishedAt = null;
      data.locale = i18next.language;
      await axios
        .post(`/guides?locale=${i18next.language}`, { data: data })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries(guideKeys.getListing());
      },
    }
  );

  const updateGuide = useMutation(
    async (guideDetails: GuideDetails) => {
      let data: GuideDetailsDto = toGuideDetailsDto(guideDetails);
      data.locale = i18next.language;
      await axios
        .put(`/guides/${guideDetails.id}?locale=${i18next.language}`, { data: data })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries(['GuideProfile']);
        queryClient.invalidateQueries(guideKeys.getListing());
      },
    }
  );

  const notifyImageUpdate = useMutation(
    async (data: {id: number | string, imageId?: string }) => {
      await axios
        .post(`/guides/notifyImageUpdate/${id}`, { data: data })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    }
  )

  const updateGuideStatus = useMutation<any, Error, any>(
    async ({ id, status }) => {
      await axios
        .put(`/guides/update_status/${id}`, { data: { status } })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries(['GuideProfile']);
        queryClient.invalidateQueries(guideKeys.getListing());
      },
    }
  );

  const getListing = useQuery(
    guideKeys.getListing(),
    async () => {
      const queryParams = qs.stringify(
        {
          fields: ['businessName', 'status'],
          pagination: {
            pageSize: 1000,
            page: 1,
          },
          publicationState: 'live',
        },
        { indices: false }
      );

      return await axios
        .get(`/guides?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          let result: any = response.data.data.map((dto: any) => {
            return {
              value: dto?.id,
              label: dto?.businessName,
              status: dto?.status,
            };
          });
          return result;
        });
    },

    {
      retry: 0,
      staleTime: 60 * 1000,
    }
  );

  const getGuide = useQuery(
    ['guide', id],
    async () => {
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          populate: {
            title: { fields: ['id', 'name'] },
            businessDescription: {
              fields: ['id', 'text', 'locale'],
              populate: { localizations: { fields: ['id', 'text', 'locale'] } },
            },
            businessMedia: {
              fields: ['id', 'name', 'url', 'formats'],
            },
            profilePhoto: {
              fields: ['id', 'name', 'url'],
            },
            guide_boats: {
              populate: {
                boatMedia: { fields: ['id', 'name', 'url'] },
                typeofEngine: { fields: ['id', 'name'] },
                boatType: { fields: ['id', 'name'] },
                navEquipments: { fields: ['id', 'name'] },
                safetyEquipments: { fields: ['id', 'name'] },
                facilities: { fields: ['id', 'name'] },
                fishingGears: { fields: ['id', 'name'] },
                additionalCrew: { fields: ['id', 'name'] },
              },
            },
            guidetrips: {
              populate: '*',
            },
          },
        },
        { encode: false }
      );

      return await axios
        .get(`/guides/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return toGuideDetails(response.data?.data);
        });
    },
    { enabled: !!id, keepPreviousData: true, staleTime: 60 * 1000 }
  );

  const getGuideProfile = useQuery<GuideDetails>(
    ['GuideProfile', id, i18next.language],
    async () => {
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          populate: {
            title: { fields: ['id', 'name'] },
            businessMedia: {
              fields: ['id', 'name', 'url', 'formats'],
            },
            profilePhoto: {
              fields: ['id', 'name', 'url'],
            },
          },
        },
        { encode: false }
      );

      return await axios
        .get(`/guides/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return toGuideDetails(response.data?.data);
        });
    },
    { enabled: !!id }
  );

  const getLastDraftVersion = useQuery(
    ['getLastDraftVersion'],
    async () =>
      await axios
        .get(`/guides?locale=${i18next.language}&${guideLastDraftVersionQueryParams()}`)
        .then(function (response: any) {
          if (response.data && response.data.data.length > 0) {
            let guideDetails = response.data.data.length
              ? toGuideDetails(response.data.data[0])
              : undefined;
            return guideDetails;
          }

          return null;
        }),
    {
      retry: 0,
    }
  );

  return {
    postGuide,
    getGuide,
    updateGuide,
    updateGuideStatus,
    createGuideDetails,
    getListing,
    getLastDraftVersion,
    getGuideProfile,
    notifyImageUpdate
  };
}
