import { useTranslation } from 'react-i18next';

const NoDataFound: React.FC<React.SVGAttributes<{}>> = (props) => {
  const { t } = useTranslation();

  return (
    <svg
      viewBox="0 0 251 199"
      width="200"
      height="150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="100%" height="100%" fill="#f0f0f0" />
      <text
        x="50%"
        y="50%"
        alignment-baseline="middle"
        text-anchor="middle"
        font-family="Arial"
        font-size="25"
        fill="#333333"
      >
        {t('common.no_data_available')}
      </text>
    </svg>
  );
};
export default NoDataFound;
