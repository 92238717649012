import { lazy } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { useSearchTripsByDistance } from '../../services/Search/searchService';
import { MarkerDialogProvider } from '../TripSearchMapView/context/MarkerDialogProvider';
const TripSearchMapView = lazy(() => import('../TripSearchMapView'));

interface ModelProps {
  show: boolean;
  onClose: any;
  center: any;
  bbox: any;
  filterTrip: number[];
}
const MapViewModelDialog = (props: ModelProps) => {
  const { onClose, show, center, bbox, filterTrip } = props;
  const { data: tripsByDistance } = useSearchTripsByDistance();
  const { city } = useParams();

  const onHide = () => {
    if (onClose) onClose(false);
  };

  return (
    <>
      <MarkerDialogProvider>
        <Modal
          fullscreen={true}
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={() => onHide()}
        >
          <Modal.Header
            closeButton
            className="map_full_overlay__close"
            style={{ position: 'absolute', top: '-30px', right: '-30px', border: '0px' }}
          ></Modal.Header>
          <Modal.Body
            style={{
              lineHeight: 1.3,
              minHeight: '200px',
              padding: '0px',
              backgroundColor: '#eee',
            }}
          >
            {tripsByDistance && tripsByDistance.length > 0 && (
              <TripSearchMapView
                mapLocation={center}
                containerStyle={{
                  height: '90vh',
                  width: '100%',
                }}
                trips={tripsByDistance}
                isCityBasedSearch={city ? true : false}
                bbox={bbox}
                filterTrip={filterTrip}
              />
            )}
          </Modal.Body>
        </Modal>
      </MarkerDialogProvider>
    </>
  );
};

export default MapViewModelDialog;
