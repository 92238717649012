import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { currentApi } from '../../services/Db/dbInfo';
import { TripDetails } from '../../services/Guide/guide';
import { memo } from 'react';

export default memo((props: any) => {
    const { t } = useTranslation();
    const { isLoading, trips } = props;
      
    return (
      <>
        {isLoading && (
          <div className="cd-fail-message mt-5 d-block">
            <ButtonSpinner />
          </div>
        )}
        {!isLoading && trips && trips.length > 0 ? (
          <>
            <div className="image-container">
              <div className="image-grid">
                {trips?.map((trip: TripDetails) => {
                  return (
                    <a
                      href={`/trip-info/${trip?.id}`}
                      key={`TripDetails1_${trip.id}`}
                      className="image-box"
                    >
                      {trip.tripMedia && (
                        <img
                          style={{ height: '150px' }}
                          src={`${currentApi.fileBasePath}${trip.tripMedia[0]?.url}`}
                          alt={trip.name}
                        />
                      )}
                      <div className="cd-gallery-content">
                        <h3 className="text-uppercase">{`${trip.name}`}</h3>
                        <p className="mb-0">
                          {`${trip?.guide.firstName} ${trip?.guide.lastName}`}
                          {trip?.targetedSpecies?.length > 0 ? ', ' : ''}
                          {trip?.targetedSpecies
                            ?.map((item: any) => t(`master_data.fishing-specie.${item.value}`))
                            ?.join(', ')}
                          {`,  ${t(`trip_type.${trip?.type?.toLowerCase()}`)}`}
                        </p>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          !isLoading && (
            <div className="cd-fail-message mt-5 d-block">{t(`common.no_result_found`)}</div>
          )
        )}
      </>
    );
  });
