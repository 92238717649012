import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <>
      <div className="row no-gutter">
        <div className="col-md-12 col-lg-12">
          <div className="d-flex align-items-center py-5" style={{ minHeight: '60vh' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8" style={{ margin: 'auto', textAlign: 'center' }}>
                  <h2 className="login-heading mt-5 mb-4">Sorry you do not access to this page </h2>

                  <Link to="/"> Go Home</Link>

                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
