/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import {
  AsyncSelect,
  CheckBoxField,
  EmailField,
  FileSelectionField,
  PhoneField,
  TextAreaField,
  TextField,
} from '../../components/Form';
import { useContainerContext } from '../../context/ContainerContext';
import { BusinessProfile, businessProfileSchema } from '../../services/Guide/guide';
import useGuideHook from '../../services/Guide/guideService';
import { dirtyValues } from '../../services/utils';

import i18next from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../../components/Form/props';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { Spinner } from '../../components/Spinner/Spinner';
import TranslatedByInfo from '../../components/TranslatedByInfo';
import { currentApi } from '../../services/Db/dbInfo';
import { genericApi } from '../../services/genericApi';

const MyListing = () => {
  const { state } = useContainerContext();
  const { t } = useTranslation();
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const { getGuideProfile, updateGuide, notifyImageUpdate } = useGuideHook({ id: state.guideId });
  const { data: guideDetail, isLoading } = getGuideProfile;
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = updateGuide;
  const { mutateAsync: mutateNotifyImageUpdate } = notifyImageUpdate;

  const methods = useForm<BusinessProfile>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver<BusinessProfile>(businessProfileSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty },
    setValue,
  } = methods;
  const { control } = methods;

  const businessDescription = useWatch({
    control,
    name: 'businessDescription',
  });

  useEffect(() => {
    if (businessDescription) {
      const plainText = businessDescription
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, ' ')
    setCharCount(plainText.length);
    setWordCount(plainText.trim().split(/\s+/).filter(Boolean).length);
  } else {
      setCharCount(0);
      setWordCount(0);
    }
  }, [businessDescription]);

  const onCountryChange = (option: any) => {
    setValue(`city`, '');
  };

  const loadCountryOptions = React.useCallback(
    (value: string) => {
      return new Promise<Option[]>((resolve) => {
        const data = genericApi.getCountryMapBoxLocation(value).then((result) => {
          return _.map(result, (obj: any) => ({
            label: obj[`place_name_` + i18next.language],
            value: obj?.properties?.short_code,
            obj: obj,
          }));
        });
        resolve(data);
      });
    },
    [i18next.language]
  );

  useEffect(() => {
    if (guideDetail) {
      reset(guideDetail.businessProfile);
    }
  }, [guideDetail, reset]);

  const onSubmit = (data: BusinessProfile) => {
    const notifiyImageUpdate = data.businessMedia?.id !== guideDetail?.businessProfile.businessMedia?.id;
    const guideId = state.guideId;
    if (guideId) {
      let fields = dirtyValues(dirtyFields, data);
      if (Object.keys(fields).length) {
        const variables: any = {
          id: guideId.toString(),
          businessProfile: fields,
        };

        mutateAsyncUpdate(variables, {
          onSuccess: () => {
            NotificationManager.success(t('notification.listing.saved'));
            if (notifiyImageUpdate) {
              mutateNotifyImageUpdate({id: guideId, imageId: data.businessMedia?.id})
            }
          },
        });
      }
    }
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  if (isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!guideDetail) return null;
  const businessMedia = guideDetail.businessProfile?.businessMedia;
  // console.log("guideDetail", !isSubscriptionLoading , activesubscriptions && activesubscriptions.length > 0 ? true : false, activesubscriptions);
  
  return (
    <div className="content">
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{t('common.images')}</h5>
            </div>
            <div className="card-body">
              <ul className="list-unstyled team-members">
                <li>
                  <div className="row mb-2">
                    <div className="col-md-9 col-9">
                      {businessMedia?.name}
                      <br />
                      <span className="text-muted" style={{ display: 'none' }}>
                        <span className="text-danger">
                          <small>{t('common.delete')}</small>
                        </span>
                      </span>
                    </div>
                    <div className="col-md-3 col-3 text-right">
                      <img
                        src={`${currentApi.fileBasePath}${businessMedia?.url}`}
                        alt="Fishingrod"
                        width="100%"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div className="row" style={{ display: 'none' }}>
                <div className="update ml-auto mr-auto mt-2">
                  <button type="submit" className="btn btn-gold btn-round">
                    {t('common.upload_image')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">{t('common.edit_listing')}</h5>
            </div>
            <div className="card-body">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <TextField
                        name={`businessName`}
                        placeholder={t('guide.fields.businessName.placeholder')}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <EmailField
                        name={`businessEmail`}
                        placeholder={t('guide.fields.email.placeholder')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <PhoneField
                        name={`businessPhone`}
                        placeholder={t('guide.fields.phoneNumber.placeholder')}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <AsyncSelect
                        className="product_select"
                        name={`country`}
                        placeholder={t('guide.fields.country.placeholder')}
                        loadOptions={loadCountryOptions}
                        onChange={onCountryChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <TextField
                        name={`streetAddress`}
                        placeholder={t('guide.fields.streetAddress.placeholder')}
                        label={t('guide.fields.streetAddress.label')}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group col-md-12">
                      <TextAreaField
                        name={`businessDescription`}
                        placeholder={t('guide.fields.businessDescription.placeholder')}
                        label={t('guide.fields.businessDescription.label')}
                      />
                      <p style={{ textAlign: 'end' }}>
                        Characters: {charCount} | Words: {wordCount}
                      </p>
                      {state?.guideId && (
                        <TranslatedByInfo
                          id={state.guideId.toString()}
                          fieldName="businessDescription"
                          model="guide"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <CheckBoxField
                        name={`certificates`}
                        label={t('guide.fields.certificates.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <CheckBoxField
                        name={`licensesCertificates`}
                        label={t('guide.fields.licensesCertificates.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <FileSelectionField
                      name={`businessMedia`}
                      label={t('guide.fields.businessMedia.label')}
                      selectedImagePath={'GenGuideBkgnd'}
                      disableUploadOption={guideDetail.businessProfile?.enableBusinessMediaUpload ? false : true}
                      captionText={t('guide.fields.image.label')}
                    />
                  </div>
                  <div className="row mt-1">
                    <div className="form-group col-md-12">
                      <div className="mt-2 mb-3 text-center">
                        <button
                          type="submit"
                          className="next btn btn-gold text-uppercase"
                          disabled={!isDirty}
                        >
                          {isLoadingUpdate ? (
                            <ButtonSpinner message={t('common.updating')} />
                          ) : (
                            t('guide.profile.update_profile')
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyListing;
