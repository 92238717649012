import PropTypes from 'prop-types';
import React, { Component, ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
interface Button {
  label: string;
  onClick?: () => void;
  className?: string | null;
}

interface ConfirmBoxProps {
  targetId?: string;
  title?: string;
  message?: string;
  buttons: Button[];
  childrenElement?: () => ReactNode;
  closeOnClickOutside?: boolean;
  closeOnEscape?: boolean;
  keyCodeForClose?: number[];
  willUnmount?: () => void;
  afterClose?: () => void;
  onClickOutside?: () => void;
  onKeypressEscape?: (event: KeyboardEvent) => void;
  onKeyPress?: () => void; // Make it optional
  overlayClassName?: string;
}

export default class ConfirmBox extends Component<ConfirmBoxProps> {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    childrenElement: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    keyCodeForClose: PropTypes.arrayOf(PropTypes.number),
    willUnmount: PropTypes.func,
    afterClose: PropTypes.func,
    onClickOutside: PropTypes.func,
    onKeypressEscape: PropTypes.func,
    onKeyPress: PropTypes.func,
    overlayClassName: PropTypes.string,
  };

  static defaultProps = {
    buttons: [
      {
        label: 'Cancel',
        onClick: () => null,
        className: null,
      },
      {
        label: 'Confirm',
        onClick: () => null,
        className: null,
      },
    ],
    childrenElement: () => null,
    closeOnClickOutside: true,
    closeOnEscape: true,
    keyCodeForClose: [],
    willUnmount: () => null,
    afterClose: () => null,
    onClickOutside: () => null,
    onKeypressEscape: () => null,
    onKeyPress: () => null,
  };

  private overlay: HTMLDivElement | null = null;

  handleClickButton = (button: Button) => {
    if (button.onClick) button.onClick();
    this.close();
  };

  handleClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    const { closeOnClickOutside, onClickOutside } = this.props;
    const isClickOutside = e.target === this.overlay;

    if (closeOnClickOutside && isClickOutside) {
      onClickOutside?.();
      this.close();
    }

    e.stopPropagation();
  };

  close = () => {
    const { afterClose } = this.props;
    removeBodyClass();
    removeElementReconfirm(this.props);
    if (afterClose) removeSVGBlurReconfirm(afterClose);
  };

  keyboard = (event: KeyboardEvent) => {
    const { closeOnEscape, onKeypressEscape, onKeyPress, keyCodeForClose } = this.props;
    const keyCode = event.keyCode;
    const isKeyCodeEscape = keyCode === 27;

    if (keyCodeForClose?.includes(keyCode)) {
      this.close();
    }

    if (closeOnEscape && isKeyCodeEscape) {
      onKeypressEscape?.(event);
      this.close();
    }

    if (onKeyPress) {
      onKeyPress();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.keyboard, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboard, false);
    this.props.willUnmount?.();
  }

  render() {
    const { title, message, buttons, childrenElement, overlayClassName } = this.props;

    return (
      <div
        className={`confirm-box-overlay ${overlayClassName}`}
        ref={(dom) => (this.overlay = dom)}
        onClick={this.handleClickOverlay}
      >
        <div className="confirm-box">
          <div className="confirm-box-body">
            {title && <h1>{title}</h1>}
            {message}
            {childrenElement?.()}
            <div className="confirm-box-button-group">
              {buttons.map((button, i) => (
                <button
                  key={i}
                  className={button.className || ''}
                  onClick={() => this.handleClickButton(button)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let root: any = null;
const targetId = 'confirm-box';

function createSVGBlurReconfirm() {
  // If has svg ignore to create the svg
  const svg = document.getElementById('confirm-box-firm-svg');
  if (svg) return;
  const svgNS = 'http://www.w3.org/2000/svg';
  const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur');
  feGaussianBlur.setAttribute('stdDeviation', '0.3');

  const filter = document.createElementNS(svgNS, 'filter');
  filter.setAttribute('id', 'gaussian-blur');
  filter.appendChild(feGaussianBlur);

  const svgElem = document.createElementNS(svgNS, 'svg');
  svgElem.setAttribute('id', 'confirm-box-firm-svg');
  svgElem.setAttribute('class', 'confirm-box-svg');
  svgElem.appendChild(filter);

  document.body.appendChild(svgElem);
}

function removeSVGBlurReconfirm(afterClose: () => void) {
  const svg = document.getElementById('confirm-box-firm-svg');
  if (svg) {
    svg.parentNode?.removeChild(svg);
  }
  document.body.children[0].classList.remove('confirm-box-blur');
  if (afterClose) afterClose();
}

function createElementReconfirm(properties: ConfirmBoxProps) {
  let divTarget = document.getElementById(properties.targetId || targetId);

  if (properties.targetId && !divTarget) {
    console.error('React Confirm Alert:', `Can not get element id (#${properties.targetId})`);
  }

  if (divTarget) {
    root = createRoot(divTarget);
    root.render(<ConfirmBox {...properties} />);
  } else {
    document.body.children[0].classList.add('confirm-box-blur');
    divTarget = document.createElement('div');
    divTarget.id = targetId;
    document.body.appendChild(divTarget);
    root = createRoot(divTarget);
    root.render(<ConfirmBox {...properties} />);
  }
}

function removeElementReconfirm(properties: ConfirmBoxProps) {
  const target = document.getElementById(properties.targetId || targetId);
  if (target) {
    root?.unmount(target);
  }
}

function addBodyClass() {
  document.body.classList.add('confirm-box-body-element');
}

function removeBodyClass() {
  document.body.classList.remove('confirm-box-body-element');
}

export function confirmBox(properties: ConfirmBoxProps) {
  addBodyClass();
  createSVGBlurReconfirm();
  createElementReconfirm(properties);
}
