/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarPicker } from '../StarsRating';

interface IStats {
  averageScore: number;
  reviewsCount: number | null;
  ratingBreakdown: any;
}
const defaultStatsState: IStats = {
  averageScore: 5,
  reviewsCount: null,
  ratingBreakdown: [],
};

interface ReviewStatsProps {
  slug: string;
  apiURL: string;
}
const ReviewStats = (props: ReviewStatsProps) => {
  const { t } = useTranslation();
  const { apiURL, slug } = props;
  const [stats, setStats] = useState<IStats>(defaultStatsState);
  useEffect(() => {
    const fetchStats = async () => {
      const url = `${apiURL}/api/ratings/reviews/${slug}/stats`;
      try {
        const data_res = await fetch(url);
        const data = await data_res.json();
        if (!data_res.ok) {
          throw data;
        }
        setStats(data);
      } catch (err) {
        console.log('Error fetching reviews stats');
        console.log(err);
        setStats({
          averageScore: 5,
          reviewsCount: 0,
          ratingBreakdown: [],
        });
      }
    };
    fetchStats();
  }, []);
  return (
    <div>
      <div>
        <StarPicker disabled={true} value={stats.averageScore} />
        <span> ({stats.averageScore} out of 5)</span>
      </div>
      <p className="m-0 p-0 label" style={{ margin: 0 }}>
        {stats.reviewsCount === null
          ? 'loading reviews'
          : '(' +
            stats.reviewsCount +
            ' ' +
            (stats.reviewsCount !== 1 ? t('rating.reviews') : t('rating.review')) +
            ')'}
      </p>
    </div>
  );
};

export default ReviewStats;
