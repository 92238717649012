import { useTranslation } from 'react-i18next';
import { PresentYourBoat } from '../../../../services/Guide/guide';

interface BoatInfoProps {
  index: number;
  boatInfo: PresentYourBoat;
  updateHandler: any;
  previewHandler: any;
}

const BoatSummary = (props: BoatInfoProps) => {
  const { t } = useTranslation();
  const { index, boatInfo, updateHandler, previewHandler } = props;

  return (
    <>
      <div key={`boat_[${index}]`} className={index % 2 === 0 ? 'row eventable' : 'row oddtable'}>
        <div className="col-md-10 text-left">
          <p className="mb-0">
            {t('common.boat')} {index + 1} :{' '}
            {t(`master_data.boat-type.${boatInfo?.boatType.value}`)},{' '}
            {boatInfo?.typeofEngine &&
              t(`master_data.engine-type.${boatInfo?.typeofEngine?.value}`)}{' '}
            {boatInfo?.boatLength} {t('common.ft')}
          </p>
        </div>
        <div className="col-md-2 text-right">
          <p className="mb-0">
            <a
              href="#collapseInputBoat"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              title="edit"
              onClick={() => updateHandler(index)}
            >
              <i className="fa fa-edit preview-edit_link"></i>
            </a>

            <a
              href="#preview"
              title="preview"
              className="link-text-white"
              data-toggle="modal"
              data-target="#modalLoginForm"
              onClick={() => previewHandler(index)}
            >
              <i className="fa fa-eye preview-edit_link" aria-hidden="true"></i>
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default BoatSummary;
