import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { DefaultOptions, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { compress, decompress } from 'lz-string';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
    serialize: (data) => compress(JSON.stringify(data)),
    deserialize: (data) => {
      return JSON.parse(decompress(data));
    },
  }),
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey, state }) => {
      const key = queryKey[0];
      if (key === 'Options' || key === 'TranslatedBy' || key === 'upload') return true;
      return false;
    },
  },
});
