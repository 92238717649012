const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;
const mapBoxBase = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const languageCodes = 'en,fr,sv,de-DE';

export const genericApi = {
  getAnyMapboxLocation: async (input: string, countrylist: string) => {
    let features = await fetch(
      `${mapBoxBase}${input}.json?language=${languageCodes}&types=place,country,district&country=${countrylist}&access_token=${mapBoxToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },

  getCityMapBoxLocation: async (input: string) => {
    let features = await fetch(
      `${mapBoxBase}${input}.json?types=place&language=${languageCodes}&access_token=${mapBoxToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },
  getCityByCountryMapBoxLocation: async (input: string, country: string) => {
    let features = await fetch(
      `${mapBoxBase}${input}.json?types=place&country=${country}&language=${languageCodes}&access_token=${mapBoxToken}`
    )
      .then((res) => res.json())
      .then((result) => result.features);
    return features;
  },
  getAddressbyCityCountryMapBoxLocation: async (
    input: string,
    country: string,
    bbBox: number[],
    proximity: number[]
  ) => {
    let URL = `${mapBoxBase}${input}.json?types=poi,address&country=${country}&language=${languageCodes}&access_token=${mapBoxToken}`;
    if (input.length < 20) {
      if (bbBox && bbBox.length > 0) URL = URL + `&bbox=${bbBox.join(', ')}`;
      if (proximity && proximity.length > 0) URL = URL + `&proximity=${proximity.join(', ')}`;
    }
    let features = await fetch(URL)
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },
  getAddressbyLatAndLong: async (longitude: string, latitude: string) => {
    let URL = `${mapBoxBase}${longitude},${latitude}.json?types=poi,address&language=${languageCodes}&access_token=${mapBoxToken}`;
    let features = await fetch(URL)
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },
  getPlaceByLatAndLong: async (longitude: string, latitude: string) => {
    let URL = `${mapBoxBase}${longitude},${latitude}.json?types=place,country,district&language=${languageCodes}&access_token=${mapBoxToken}`;
    let features = await fetch(URL)
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },

  getCountryMapBoxLocation: async (input: string) => {
    let features = await fetch(
      `${mapBoxBase}${input}.json?types=country&language=${languageCodes}&access_token=${mapBoxToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });
    return features;
  },
  getLocationByAddressMapboxLocation: async (city: string, country: string, address: string) => {
    let features = await fetch(
      `${mapBoxBase}${address} ${city}.json?types=address&country=${country}&language=${languageCodes}&access_token=${mapBoxToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        return result.features;
      });

    return features;
  },
};
