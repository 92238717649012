import { TripListContainer } from './tripListContainer';

function Trips(args: any) {
  const { fieldName, isVisible, setIsVisible } = args;
  return (
    <>
      <TripListContainer name={fieldName} isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
}
export default Trips;
