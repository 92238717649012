import React, { createContext, useContext, useReducer } from 'react';
import storage from '../utils/storage';

interface State {
  guideId: number;
  businessName: string;
}

interface Action {
  type: string;
  payload: any;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setSelectedGuide': {
      const { guideId, businessName } = action.payload;

      const guide = { guideId: guideId, businessName: businessName };
      storage.setGuide(guide);

      return guide;
    }
    default:
      throw new Error();
  }
};

const setSelectedGuide = (payload: any): Action => ({ type: 'setSelectedGuide', payload });

interface ReducerActions {
  setGuide: (payload: any) => void;
}

const actions = (dispatch: React.Dispatch<Action>): ReducerActions => ({
  setGuide: (payload) => dispatch(setSelectedGuide(payload)),
});

interface ContextType {
  state: State;
  actions: ReducerActions;
}

const ContainerContext = createContext<ContextType>({
  state: storage.getGuide() || {
    guideId: 0,
    businessName: '',
  },
  actions: { setGuide: (payload) => {} },
});

export const ContainerContextProvider = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [reducerState, dispatch] = useReducer(
    reducer,
    storage.getGuide() || {
      guideId: 0,
      businessName: '',
    }
  );
  const reducerActions = actions(dispatch);
  const context: ContextType = {
    state: { ...reducerState },
    actions: { ...reducerActions },
  };

  return <ContainerContext.Provider value={context}>{props.children}</ContainerContext.Provider>;
};

export const useContainerContext = (): ContextType => useContext(ContainerContext);
