import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useUser } from '../lib/auth';

interface AuthProps {
  allowedRoles: string[];
}

export const Auth: React.FC<AuthProps> = ({ allowedRoles }: AuthProps) => {
  const { data: userInfo } = useUser();
  let [searchParams] = useSearchParams();
  let pathName = searchParams.get('next') || sessionStorage.getItem('next') || '/dashboard';
  let roles: string[] = [];
  let isAuthenticated = false;
  if (userInfo) {
    isAuthenticated = true;
    if (userInfo.isGuide) roles.push('guide');
    else roles.push('user');
  }
  const isAllowed = allowedRoles.find((role) => roles && roles.includes(role));

  const location = useLocation();

  if (isAllowed && isAuthenticated) {
    return <Outlet />;
  } else if (isAuthenticated) {
    if (
      [
        '/signin',
        '/register',
        '/connect/google/redirect',
        '/connect/facebook/redirect',
        '/reset-password',
        '/forgot-password',
      ].includes(location.pathname.toLowerCase())
    ) {
      return <Navigate to={pathName} state={{ from: location }} replace />;
    }
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  } else {
    if (
      [
        '/signin',
        '/register',
        '/connect/google/redirect',
        '/connect/facebook/redirect',
        '/reset-password',
        '/forgot-password',
      ].includes(location.pathname.toLowerCase())
    ) {
      return <Outlet />;
    }
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
};
