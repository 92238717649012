const en_languages = {
  ab: {
    name: 'Abkhaz',
    nativeName: 'аҧсуа',
  },
  aa: {
    name: 'Afar',
    nativeName: 'Afaraf',
  },
  af: {
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  ak: {
    name: 'Akan',
    nativeName: 'Akan',
  },
  sq: {
    name: 'Albanian',
    nativeName: 'Shqip',
  },
  am: {
    name: 'Amharic',
    nativeName: 'አማርኛ',
  },
  ar: {
    name: 'Arabic',
    nativeName: 'العربية',
  },
  an: {
    name: 'Aragonese',
    nativeName: 'Aragonés',
  },
  hy: {
    name: 'Armenian',
    nativeName: 'Հայերեն',
  },
  as: {
    name: 'Assamese',
    nativeName: 'অসমীয়া',
  },
  av: {
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
  },
  ae: {
    name: 'Avestan',
    nativeName: 'avesta',
  },
  ay: {
    name: 'Aymara',
    nativeName: 'aymar aru',
  },
  az: {
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  bm: {
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  ba: {
    name: 'Bashkir',
    nativeName: 'башҡорт теле',
  },
  eu: {
    name: 'Basque',
    nativeName: 'euskara, euskera',
  },
  be: {
    name: 'Belarusian',
    nativeName: 'Беларуская',
  },
  bn: {
    name: 'Bengali',
    nativeName: 'বাংলা',
  },
  bh: {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  bi: {
    name: 'Bislama',
    nativeName: 'Bislama',
  },
  bs: {
    name: 'Bosnian',
    nativeName: 'bosanski jezik',
  },
  br: {
    name: 'Breton',
    nativeName: 'brezhoneg',
  },
  bg: {
    name: 'Bulgarian',
    nativeName: 'български език',
  },
  my: {
    name: 'Burmese',
    nativeName: 'ဗမာစာ',
  },
  ca: {
    name: 'Catalan; Valencian',
    nativeName: 'Català',
  },
  ch: {
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  ce: {
    name: 'Chechen',
    nativeName: 'нохчийн мотт',
  },
  ny: {
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  zh: {
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  cv: {
    name: 'Chuvash',
    nativeName: 'чӑваш чӗлхи',
  },
  kw: {
    name: 'Cornish',
    nativeName: 'Kernewek',
  },
  co: {
    name: 'Corsican',
    nativeName: 'corsu, lingua corsa',
  },
  cr: {
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  hr: {
    name: 'Croatian',
    nativeName: 'hrvatski',
  },
  cs: {
    name: 'Czech',
    nativeName: 'česky, čeština',
  },
  da: {
    name: 'Danish',
    nativeName: 'dansk',
  },
  dv: {
    name: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި',
  },
  nl: {
    name: 'Dutch',
    nativeName: 'Nederlands, Vlaams',
  },
  en: {
    name: 'English',
    nativeName: 'english',
  },
  eo: {
    name: 'Esperanto',
    nativeName: 'Esperanto',
  },
  et: {
    name: 'Estonian',
    nativeName: 'eesti, eesti keel',
  },
  ee: {
    name: 'Ewe',
    nativeName: 'eʋegbe',
  },
  fo: {
    name: 'Faroese',
    nativeName: 'føroyskt',
  },
  fj: {
    name: 'Fijian',
    nativeName: 'vosa Vakaviti',
  },
  fi: {
    name: 'Finnish',
    nativeName: 'suomi, suomen kieli',
  },
  fr: {
    name: 'French',
    nativeName: 'français, langue française',
  },
  ff: {
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  gl: {
    name: 'Galician',
    nativeName: 'Galego',
  },
  ka: {
    name: 'Georgian',
    nativeName: 'ქართული',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
  },
  el: {
    name: 'Greek, Modern',
    nativeName: 'Ελληνικά',
  },
  gn: {
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
  },
  gu: {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  ht: {
    name: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  ha: {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  he: {
    name: 'Hebrew (modern)',
    nativeName: 'עברית',
  },
  hz: {
    name: 'Herero',
    nativeName: 'Otjiherero',
  },
  hi: {
    name: 'Hindi',
    nativeName: 'हिन्दी, हिंदी',
  },
  ho: {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  hu: {
    name: 'Hungarian',
    nativeName: 'Magyar',
  },
  ia: {
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  id: {
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  ie: {
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  ga: {
    name: 'Irish',
    nativeName: 'Gaeilge',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  ik: {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  io: {
    name: 'Ido',
    nativeName: 'Ido',
  },
  is: {
    name: 'Icelandic',
    nativeName: 'Íslenska',
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano',
  },
  iu: {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  ja: {
    name: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  jv: {
    name: 'Javanese',
    nativeName: 'basa Jawa',
  },
  kl: {
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  kr: {
    name: 'Kanuri',
    nativeName: 'Kanuri',
  },
  ks: {
    name: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  kk: {
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  rw: {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  ky: {
    name: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили',
  },
  kv: {
    name: 'Komi',
    nativeName: 'коми кыв',
  },
  kg: {
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  ko: {
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  ku: {
    name: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  la: {
    name: 'Latin',
    nativeName: 'latine, lingua latina',
  },
  lb: {
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  lg: {
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  li: {
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  ln: {
    name: 'Lingala',
    nativeName: 'Lingála',
  },
  lo: {
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
  },
  lt: {
    name: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  lu: {
    name: 'Luba-Katanga',
    nativeName: '',
  },
  lv: {
    name: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  gv: {
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
  },
  mk: {
    name: 'Macedonian',
    nativeName: 'македонски јазик',
  },
  mg: {
    name: 'Malagasy',
    nativeName: 'Malagasy fiteny',
  },
  ms: {
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  mt: {
    name: 'Maltese',
    nativeName: 'Malti',
  },
  mi: {
    name: 'Māori',
    nativeName: 'te reo Māori',
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी',
  },
  mh: {
    name: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ',
  },
  mn: {
    name: 'Mongolian',
    nativeName: 'монгол',
  },
  na: {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  nv: {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  nb: {
    name: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål',
  },
  nd: {
    name: 'North Ndebele',
    nativeName: 'isiNdebele',
  },
  ne: {
    name: 'Nepali',
    nativeName: 'नेपाली',
  },
  ng: {
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  nn: {
    name: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk',
  },
  no: {
    name: 'Norwegian',
    nativeName: 'Norsk',
  },
  ii: {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  nr: {
    name: 'South Ndebele',
    nativeName: 'isiNdebele',
  },
  oc: {
    name: 'Occitan',
    nativeName: 'Occitan',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  cu: {
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  om: {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  or: {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  os: {
    name: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг',
  },
  pa: {
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  pi: {
    name: 'Pāli',
    nativeName: 'पाऴि',
  },
  fa: {
    name: 'Persian',
    nativeName: 'فارسی',
  },
  pl: {
    name: 'Polish',
    nativeName: 'polski',
  },
  ps: {
    name: 'Pashto, Pushto',
    nativeName: 'پښتو',
  },
  pt: {
    name: 'Portuguese',
    nativeName: 'Português',
  },
  qu: {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  rm: {
    name: 'Romansh',
    nativeName: 'rumantsch grischun',
  },
  rn: {
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  ro: {
    name: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
  },
  ru: {
    name: 'Russian',
    nativeName: 'русский язык',
  },
  sa: {
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  sc: {
    name: 'Sardinian',
    nativeName: 'sardu',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  se: {
    name: 'Northern Sami',
    nativeName: 'Davvisámegiella',
  },
  sm: {
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
  },
  sg: {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  sr: {
    name: 'Serbian',
    nativeName: 'српски језик',
  },
  gd: {
    name: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig',
  },
  sn: {
    name: 'Shona',
    nativeName: 'chiShona',
  },
  si: {
    name: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල',
  },
  sk: {
    name: 'Slovak',
    nativeName: 'slovenčina',
  },
  sl: {
    name: 'Slovene',
    nativeName: 'slovenščina',
  },
  so: {
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  st: {
    name: 'Southern Sotho',
    nativeName: 'Sesotho',
  },
  es: {
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
  },
  su: {
    name: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  sw: {
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  ss: {
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  sv: {
    name: 'Swedish',
    nativeName: 'svenska',
  },
  ta: {
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  te: {
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  tg: {
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  th: {
    name: 'Thai',
    nativeName: 'ไทย',
  },
  ti: {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  bo: {
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  tk: {
    name: 'Turkmen',
    nativeName: 'Türkmen, Түркмен',
  },
  tl: {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  tn: {
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  to: {
    name: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga',
  },
  tr: {
    name: 'Turkish',
    nativeName: 'Türkçe',
  },
  ts: {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  tt: {
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  tw: {
    name: 'Twi',
    nativeName: 'Twi',
  },
  ty: {
    name: 'Tahitian',
    nativeName: 'Reo Tahiti',
  },
  ug: {
    name: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  uk: {
    name: 'Ukrainian',
    nativeName: 'українська',
  },
  ur: {
    name: 'Urdu',
    nativeName: 'اردو',
  },
  uz: {
    name: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  ve: {
    name: 'Venda',
    nativeName: 'Tshivenḓa',
  },
  vi: {
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  vo: {
    name: 'Volapük',
    nativeName: 'Volapük',
  },
  wa: {
    name: 'Walloon',
    nativeName: 'Walon',
  },
  cy: {
    name: 'Welsh',
    nativeName: 'Cymraeg',
  },
  wo: {
    name: 'Wolof',
    nativeName: 'Wollof',
  },
  fy: {
    name: 'Western Frisian',
    nativeName: 'Frysk',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  yi: {
    name: 'Yiddish',
    nativeName: 'ייִדיש',
  },
  yo: {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  za: {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
};

const fr_languages = {
  ab: {
    name: 'Abkhaze',
    nativeName: 'аҧсуа',
  },
  aa: {
    name: 'Au loin',
    nativeName: 'Afaraf',
  },
  af: {
    name: 'afrikaans',
    nativeName: 'afrikaans',
  },
  ak: {
    name: 'Akan',
    nativeName: 'Akan',
  },
  sq: {
    name: 'albanais',
    nativeName: 'Shqip',
  },
  am: {
    name: 'Amharique',
    nativeName: 'bien',
  },
  ar: {
    name: 'arabe',
    nativeName: 'العربية',
  },
  an: {
    name: 'Aragonais',
    nativeName: 'Aragonés',
  },
  hy: {
    name: 'arménien',
    nativeName: 'et',
  },
  as: {
    name: 'Assamais',
    nativeName: 'অসমীয়া',
  },
  av: {
    name: 'Avarique',
    nativeName: 'авар мацEET, магеарул мацEET',
  },
  ae: {
    name: 'Avestan',
    nativeName: 'avesta',
  },
  ay: {
    name: 'Aymara',
    nativeName: 'Aymar Aru',
  },
  az: {
    name: 'azerbaïdjanais',
    nativeName: 'azərbaycan dili',
  },
  bm: {
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  ba: {
    name: 'Bachkir',
    nativeName: 'башҡort TÉLÉ',
  },
  eu: {
    name: 'Basque',
    nativeName: 'euskara, euskera',
  },
  be: {
    name: 'biélorusse',
    nativeName: 'Biélorussie',
  },
  bn: {
    name: 'bengali',
    nativeName: 'বাংলা',
  },
  bh: {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  bi: {
    name: 'bichlama',
    nativeName: 'bichlama',
  },
  bs: {
    name: 'bosniaque',
    nativeName: 'Bosanski Jezik',
  },
  br: {
    name: 'Breton',
    nativeName: 'brejoneg',
  },
  bg: {
    name: 'bulgare',
    nativeName: 'български език',
  },
  my: {
    name: 'birman',
    nativeName: 'ဗမာစာ',
  },
  ca: {
    name: 'Catalan; ',
    nativeName: 'Catalogne',
  },
  ch: {
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  ce: {
    name: 'Tchétchène',
    nativeName: 'mot de passe',
  },
  ny: {
    name: 'Chichewa; ',
    nativeName: 'chiCheŵa, chinyanja',
  },
  zh: {
    name: 'Chinois',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  cv: {
    name: 'Tchouvache',
    nativeName: 'чеваш челхи',
  },
  kw: {
    name: 'cornouaillais',
    nativeName: 'Kernewek',
  },
  co: {
    name: 'Corse',
    nativeName: 'corsu, langue corsa',
  },
  cr: {
    name: 'Cri',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  hr: {
    name: 'croate',
    nativeName: 'Hrvatski',
  },
  cs: {
    name: 'tchèque',
    nativeName: 'česky, čeština',
  },
  da: {
    name: 'danois',
    nativeName: 'danser',
  },
  dv: {
    name: 'Divéhi ; ',
    nativeName: '××××××××××.',
  },
  nl: {
    name: 'Néerlandais',
    nativeName: 'Pays-Bas, Flandre',
  },
  en: {
    name: 'Anglais',
    nativeName: 'Anglais',
  },
  eo: {
    name: 'espéranto',
    nativeName: 'espéranto',
  },
  et: {
    name: 'estonien',
    nativeName: 'eesti, eesti quille',
  },
  ee: {
    name: 'Éwé',
    nativeName: 'Eʋegbé',
  },
  fo: {
    name: 'Féroïen',
    nativeName: 'forêt',
  },
  fj: {
    name: 'Fidjien',
    nativeName: 'vosa Vakaviti',
  },
  fi: {
    name: 'finlandais',
    nativeName: 'Suomi, Suomen Kieli',
  },
  fr: {
    name: 'Français',
    nativeName: 'français, langue française',
  },
  ff: {
    name: 'Peul; ',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  gl: {
    name: 'Galicien',
    nativeName: 'Galégo',
  },
  ka: {
    name: 'géorgien',
    nativeName: 'ქართული',
  },
  de: {
    name: 'Allemand',
    nativeName: 'Allemand',
  },
  el: {
    name: 'Grec, Moderne',
    nativeName: 'Ελληνικά',
  },
  gn: {
    name: 'Guarani',
    nativeName: 'Avaneẽ',
  },
  gu: {
    name: 'gujarati',
    nativeName: 'ગુજરાતી',
  },
  ht: {
    name: 'Haïtien; ',
    nativeName: 'Kreyòl ayisyen',
  },
  ha: {
    name: 'Haoussa',
    nativeName: 'Haoussa, هَوُسَ',
  },
  he: {
    name: 'Hébreu (moderne)',
    nativeName: 'עברית',
  },
  hz: {
    name: 'Héréro',
    nativeName: 'Otjiherero',
  },
  hi: {
    name: 'hindi',
    nativeName: 'bien, bien sûr',
  },
  ho: {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  hu: {
    name: 'hongrois',
    nativeName: 'magyar',
  },
  ia: {
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  id: {
    name: 'indonésien',
    nativeName: 'bahasa indonesia',
  },
  ie: {
    name: 'Interlingue',
    nativeName: 'Initialement appelé Occidental ; ',
  },
  ga: {
    name: 'irlandais',
    nativeName: 'Gaeilge',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  ik: {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  io: {
    name: 'Je fais',
    nativeName: 'Je fais',
  },
  is: {
    name: 'islandais',
    nativeName: 'Îleslenska',
  },
  it: {
    name: 'italien',
    nativeName: 'Italien',
  },
  iu: {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  ja: {
    name: 'Japonais',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  jv: {
    name: 'Javanais',
    nativeName: 'basa Jawa',
  },
  kl: {
    name: 'Kalaallisut, Groenlandais',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  kr: {
    name: 'Kanouri',
    nativeName: 'Kanouri',
  },
  ks: {
    name: 'Cachemire',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  kk: {
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  rw: {
    name: 'kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  ky: {
    name: 'Kirghize, Kirghize',
    nativeName: 'кыргыз тили',
  },
  kv: {
    name: 'Komis',
    nativeName: 'коми кыв',
  },
  kg: {
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  ko: {
    name: 'coréen',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  ku: {
    name: 'kurde',
    nativeName: 'Kurdî, كوردی‎',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  la: {
    name: 'Latin',
    nativeName: 'latine, lingua latina',
  },
  lb: {
    name: 'luxembourgeois, luxembourgeois',
    nativeName: 'Letzebuergesch',
  },
  lg: {
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  li: {
    name: 'Limbourgeois, Limbourgeois, Limbourgeois',
    nativeName: 'Limbourg',
  },
  ln: {
    name: 'Lingala',
    nativeName: 'Lingala',
  },
  lo: {
    name: 'Laotien',
    nativeName: 'ພາສາລາວ',
  },
  lt: {
    name: 'lituanien',
    nativeName: 'lietuvių kalba',
  },
  lu: {
    name: 'Luba-Katanga',
    nativeName: '',
  },
  lv: {
    name: 'letton',
    nativeName: 'lettonie valoda',
  },
  gv: {
    name: 'Mannois',
    nativeName: 'Gaelg, Gailck',
  },
  mk: {
    name: 'Macédonien',
    nativeName: 'македонски јазик',
  },
  mg: {
    name: 'malgache',
    nativeName: 'Fiteny malgache',
  },
  ms: {
    name: 'malais',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  mt: {
    name: 'maltais',
    nativeName: 'Malte',
  },
  mi: {
    name: 'Maori',
    nativeName: 'te reo maori',
  },
  mr: {
    name: 'Marathi (Marāṭhi)',
    nativeName: 'मराठी',
  },
  mh: {
    name: 'Marshallais',
    nativeName: 'Kajin Majeļ',
  },
  mn: {
    name: 'mongol',
    nativeName: 'mongol',
  },
  na: {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  nv: {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  nb: {
    name: 'bokmål norvégien',
    nativeName: 'Norsk bokmål',
  },
  nd: {
    name: 'Ndébélé Nord',
    nativeName: 'isiNdebele',
  },
  ne: {
    name: 'Népalais',
    nativeName: 'नेपाली',
  },
  ng: {
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  nn: {
    name: 'Norvégien nynorsk',
    nativeName: 'Norsk Nynorsk',
  },
  no: {
    name: 'norvégien',
    nativeName: 'Norsk',
  },
  ii: {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  nr: {
    name: 'Ndébélé Sud',
    nativeName: 'isiNdebele',
  },
  oc: {
    name: 'Occitan',
    nativeName: 'Occitan',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  cu: {
    name: 'Vieille église slave, église slave, église slave, vieux bulgare, vieux slave',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  om: {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  or: {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  os: {
    name: 'Ossète, Ossète',
    nativeName: 'fer à repasser',
  },
  pa: {
    name: 'Panjabi, Pendjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  pi: {
    name: 'Pali',
    nativeName: 'पाऴि',
  },
  fa: {
    name: 'persan',
    nativeName: 'فارسی',
  },
  pl: {
    name: 'polonais',
    nativeName: 'polonais',
  },
  ps: {
    name: 'Pachtou, Pushto',
    nativeName: 'پښتو',
  },
  pt: {
    name: 'Portugais',
    nativeName: 'Portugais',
  },
  qu: {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  rm: {
    name: 'romanche',
    nativeName: 'rumantsch grischun',
  },
  rn: {
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  ro: {
    name: 'roumain, moldave, moldave',
    nativeName: 'romana',
  },
  ru: {
    name: 'russe',
    nativeName: 'russe язык',
  },
  sa: {
    name: 'Sanscrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  sc: {
    name: 'Sarde',
    nativeName: 'Sardu',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  se: {
    name: 'Sami du Nord',
    nativeName: 'Davvisámegiella',
  },
  sm: {
    name: 'Samoan',
    nativeName: 'Gagana Faa Samoa',
  },
  sg: {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  sr: {
    name: 'serbe',
    nativeName: 'српски језик',
  },
  gd: {
    name: 'gaélique écossais; ',
    nativeName: 'Gàidhlig',
  },
  sn: {
    name: 'Shona',
    nativeName: 'ChiShona',
  },
  si: {
    name: 'Cinghalais, Cinghalais',
    nativeName: 'සිංහල',
  },
  sk: {
    name: 'slovaque',
    nativeName: 'Slovénie',
  },
  sl: {
    name: 'slovène',
    nativeName: 'slovène',
  },
  so: {
    name: 'somali',
    nativeName: 'Soomaaliga, de Soomaali',
  },
  st: {
    name: 'Sotho du Sud',
    nativeName: 'Sésotho',
  },
  es: {
    name: 'Espagnol; ',
    nativeName: 'espagnol, castellano',
  },
  su: {
    name: 'Soundanais',
    nativeName: 'Basa Sundae',
  },
  sw: {
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  ss: {
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  sv: {
    name: 'suédois',
    nativeName: 'suédoise',
  },
  ta: {
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  te: {
    name: 'Télougou',
    nativeName: 'తెలుగు',
  },
  tg: {
    name: 'Tadjik',
    nativeName: 'тоҷикӣ, toğiki, تاجیکی‎',
  },
  th: {
    name: 'thaïlandais',
    nativeName: 'ไทย',
  },
  ti: {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  bo: {
    name: 'Norme tibétaine, tibétaine, centrale',
    nativeName: 'བོད་ཡིག',
  },
  tk: {
    name: 'Turkmènes',
    nativeName: 'Turkmène, Түркмен',
  },
  tl: {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  tn: {
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  to: {
    name: 'Tonga (Îles Tonga)',
    nativeName: 'faka Tonga',
  },
  tr: {
    name: 'turc',
    nativeName: 'Turc',
  },
  ts: {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  tt: {
    name: 'tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  tw: {
    name: 'Twi',
    nativeName: 'Twi',
  },
  ty: {
    name: 'tahitien',
    nativeName: 'Réo Tahiti',
  },
  ug: {
    name: 'Ouïghour, Ouïghour',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  uk: {
    name: 'ukrainien',
    nativeName: 'українська',
  },
  ur: {
    name: 'Ourdou',
    nativeName: 'اردو',
  },
  uz: {
    name: 'Ouzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  ve: {
    name: 'Vente',
    nativeName: 'Tshivenḓa',
  },
  vi: {
    name: 'vietnamien',
    nativeName: 'Tiếng Việt',
  },
  vo: {
    name: 'Volapuk',
    nativeName: 'Volapuk',
  },
  wa: {
    name: 'Wallon',
    nativeName: 'Walon',
  },
  cy: {
    name: 'gallois',
    nativeName: 'Cymraeg',
  },
  wo: {
    name: 'wolof',
    nativeName: 'Wolof',
  },
  fy: {
    name: 'Frison occidental',
    nativeName: 'Frisk',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  yi: {
    name: 'yiddish',
    nativeName: 'ייִדיש',
  },
  yo: {
    name: 'Yorouba',
    nativeName: 'Yorùba',
  },
  za: {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
};

const sv_languages = {
  ab: {
    name: 'Abkhaziska',
    nativeName: 'аҧсуа',
  },
  aa: {
    name: 'Fjärran',
    nativeName: 'Afaraf',
  },
  af: {
    name: 'afrikaans',
    nativeName: 'afrikaans',
  },
  ak: {
    name: 'Akan',
    nativeName: 'Akan',
  },
  sq: {
    name: 'albanska',
    nativeName: 'Shqip',
  },
  am: {
    name: 'amhariska',
    nativeName: 'አማርኛ',
  },
  ar: {
    name: 'arabiska',
    nativeName: 'العربية',
  },
  an: {
    name: 'Aragonese',
    nativeName: 'Aragonés',
  },
  hy: {
    name: 'armeniska',
    nativeName: 'Հայերեն',
  },
  as: {
    name: 'assamiska',
    nativeName: 'অসমীয়া',
  },
  av: {
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
  },
  ae: {
    name: 'Avestan',
    nativeName: 'avesta',
  },
  ay: {
    name: 'Aymara',
    nativeName: 'aymar aru',
  },
  az: {
    name: 'azerbajdzjanska',
    nativeName: 'azərbaycan dili',
  },
  bm: {
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  ba: {
    name: 'Bashkir',
    nativeName: 'башҡорт теле',
  },
  eu: {
    name: 'baskiska',
    nativeName: 'euskara, euskera',
  },
  be: {
    name: 'vitryska',
    nativeName: 'Беларуская',
  },
  bn: {
    name: 'bengaliska',
    nativeName: 'বাংলা',
  },
  bh: {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  bi: {
    name: 'Bislama',
    nativeName: 'Bislama',
  },
  bs: {
    name: 'bosniska',
    nativeName: 'bosanski jezik',
  },
  br: {
    name: 'Breton',
    nativeName: 'brezhoneg',
  },
  bg: {
    name: 'bulgariska',
    nativeName: 'български език',
  },
  my: {
    name: 'burmesiska',
    nativeName: 'ဗမာစာ',
  },
  ca: {
    name: 'katalanska; ',
    nativeName: 'Català',
  },
  ch: {
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  ce: {
    name: 'Tjetjenien',
    nativeName: 'нохчийн мотт',
  },
  ny: {
    name: 'Chichewa; ',
    nativeName: 'chiCheŵa, chinyanja',
  },
  zh: {
    name: 'kinesiska',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  cv: {
    name: 'Chuvash',
    nativeName: 'чӑваш чӗлхи',
  },
  kw: {
    name: 'Cornish',
    nativeName: 'Kernewek',
  },
  co: {
    name: 'korsikanska',
    nativeName: 'corsu, lingua corsa',
  },
  cr: {
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  hr: {
    name: 'Kroatisk',
    nativeName: 'hrvatski',
  },
  cs: {
    name: 'tjeckiska',
    nativeName: 'česky, čeština',
  },
  da: {
    name: 'danska',
    nativeName: 'dansk',
  },
  dv: {
    name: 'Divehi; ',
    nativeName: 'ދިވެހި',
  },
  nl: {
    name: 'holländska',
    nativeName: 'Nederlands, Vlaams',
  },
  en: {
    name: 'Engelska',
    nativeName: 'Engelska',
  },
  eo: {
    name: 'esperanto',
    nativeName: 'esperanto',
  },
  et: {
    name: 'estniska',
    nativeName: 'eesti, eesti köl',
  },
  ee: {
    name: 'Tacka',
    nativeName: 'Eʋegbe',
  },
  fo: {
    name: 'färöiska',
    nativeName: 'föreoyskt',
  },
  fj: {
    name: 'fijianska',
    nativeName: 'vosa Vakaviti',
  },
  fi: {
    name: 'finska',
    nativeName: 'suomi, suomen kieli',
  },
  fr: {
    name: 'franska',
    nativeName: 'français, langue française',
  },
  ff: {
    name: 'Fula; ',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  gl: {
    name: 'galiciska',
    nativeName: 'Galego',
  },
  ka: {
    name: 'georgiska',
    nativeName: 'ქართული',
  },
  de: {
    name: 'tysk',
    nativeName: 'Deutsch',
  },
  el: {
    name: 'Grekiskt, modernt',
    nativeName: 'Ελληνικά',
  },
  gn: {
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
  },
  gu: {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  ht: {
    name: 'haitisk; ',
    nativeName: 'Kreyòl ayisyen',
  },
  ha: {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  he: {
    name: 'hebreiska (moderna)',
    nativeName: 'עברית',
  },
  hz: {
    name: 'Herero',
    nativeName: 'Otjiherero',
  },
  hi: {
    name: 'hindi',
    nativeName: 'हिन्दी, हिंदी',
  },
  ho: {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  hu: {
    name: 'ungerska',
    nativeName: 'Magyar',
  },
  ia: {
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  id: {
    name: 'indonesiska',
    nativeName: 'Bahasa Indonesien',
  },
  ie: {
    name: 'Interlingue',
    nativeName: 'Ursprungligen kallad Occidental; ',
  },
  ga: {
    name: 'irländska',
    nativeName: 'Gaeilge',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  ik: {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  io: {
    name: 'Jag gör',
    nativeName: 'Jag gör',
  },
  is: {
    name: 'isländska',
    nativeName: 'Íslenska',
  },
  it: {
    name: 'italienska',
    nativeName: 'Italiano',
  },
  iu: {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  ja: {
    name: 'japanska',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  jv: {
    name: 'javanesiska',
    nativeName: 'basa Jawa',
  },
  kl: {
    name: 'Kalaallisut, grönländska',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  kr: {
    name: 'Kanuri',
    nativeName: 'Kanuri',
  },
  ks: {
    name: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  kk: {
    name: 'Kazakiska',
    nativeName: 'Қазақ тілі',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  rw: {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  ky: {
    name: 'Kirgiz, Kirgiz',
    nativeName: 'кыргыз тили',
  },
  kv: {
    name: 'Komi',
    nativeName: 'коми кыв',
  },
  kg: {
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  ko: {
    name: 'koreanska',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  ku: {
    name: 'kurdiska',
    nativeName: 'Kurdî, كوردی‎',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  la: {
    name: 'latin',
    nativeName: 'latine, lingua latina',
  },
  lb: {
    name: 'Luxemburgiska, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  lg: {
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  li: {
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  ln: {
    name: 'Lingala',
    nativeName: 'Lingála',
  },
  lo: {
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
  },
  lt: {
    name: 'litauiska',
    nativeName: 'lietuvių kalba',
  },
  lu: {
    name: 'Luba-Katanga',
    nativeName: '',
  },
  lv: {
    name: 'lettiska',
    nativeName: 'latviešu valoda',
  },
  gv: {
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
  },
  mk: {
    name: 'makedonska',
    nativeName: 'македонски јазик',
  },
  mg: {
    name: 'Madagaskar',
    nativeName: 'Madagaskar fiteny',
  },
  ms: {
    name: 'malajiska',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  mt: {
    name: 'maltesiska',
    nativeName: 'Malti',
  },
  mi: {
    name: 'Māori',
    nativeName: 'te reo Māori',
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी',
  },
  mh: {
    name: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ',
  },
  mn: {
    name: 'mongoliska',
    nativeName: 'монгол',
  },
  na: {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  nv: {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  nb: {
    name: 'norskt bokmål',
    nativeName: 'Norsk bokmål',
  },
  nd: {
    name: 'Norra Ndebele',
    nativeName: 'isiNdebele',
  },
  ne: {
    name: 'nepalesiska',
    nativeName: 'नेपाली',
  },
  ng: {
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  nn: {
    name: 'norsk nynorsk',
    nativeName: 'Norsk nynorsk',
  },
  no: {
    name: 'norska',
    nativeName: 'Norsk',
  },
  ii: {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  nr: {
    name: 'Södra Ndebele',
    nativeName: 'isiNdebele',
  },
  oc: {
    name: 'occitanska',
    nativeName: 'occitanska',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  cu: {
    name: 'Gammal kyrkoslavisk, kyrkoslavisk, kyrkoslavisk, gammalbulgarisk, gammalslavisk',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  om: {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  or: {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  os: {
    name: 'ossetiskt, ossetiskt',
    nativeName: 'ирон æвзаг',
  },
  pa: {
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  pi: {
    name: 'Pāli',
    nativeName: 'पाऴि',
  },
  fa: {
    name: 'persiska',
    nativeName: 'فارسی',
  },
  pl: {
    name: 'putsa',
    nativeName: 'polski',
  },
  ps: {
    name: 'Pashto, Pushto',
    nativeName: 'پښتو',
  },
  pt: {
    name: 'portugisiska',
    nativeName: 'Português',
  },
  qu: {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  rm: {
    name: 'romanska',
    nativeName: 'rumantsch grischun',
  },
  rn: {
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  ro: {
    name: 'rumänska, moldaviska, moldaviska',
    nativeName: 'română',
  },
  ru: {
    name: 'ryska',
    nativeName: 'русский язык',
  },
  sa: {
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  sc: {
    name: 'sardiska',
    nativeName: 'sardu',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  se: {
    name: 'nordsamiska',
    nativeName: 'Davvisámegiella',
  },
  sm: {
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
  },
  sg: {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  sr: {
    name: 'serbiska',
    nativeName: 'српски језик',
  },
  gd: {
    name: 'skotsk gaeliska; ',
    nativeName: 'Gàidhlig',
  },
  sn: {
    name: 'Shona',
    nativeName: 'chiShona',
  },
  si: {
    name: 'singalesiska, singalesiska',
    nativeName: 'සිංහල',
  },
  sk: {
    name: 'slovakiska',
    nativeName: 'slovenčina',
  },
  sl: {
    name: 'slovenska',
    nativeName: 'slovenščina',
  },
  so: {
    name: 'somaliska',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  st: {
    name: 'södra Sotho',
    nativeName: 'Sesotho',
  },
  es: {
    name: 'spanska; ',
    nativeName: 'español, castellano',
  },
  su: {
    name: 'Sundanesiska',
    nativeName: 'Basa Sunda',
  },
  sw: {
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  ss: {
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  sv: {
    name: 'svenska',
    nativeName: 'svenska',
  },
  ta: {
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  te: {
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  tg: {
    name: 'tadzjikiska',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  th: {
    name: 'Thai',
    nativeName: 'ไทย',
  },
  ti: {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  bo: {
    name: 'Tibetansk standard, tibetansk, central',
    nativeName: 'བོད་ཡིག',
  },
  tk: {
    name: 'turkmeniska',
    nativeName: 'Turkmen, Түркмен',
  },
  tl: {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  tn: {
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  to: {
    name: 'Tonga (Tongaöarna)',
    nativeName: 'faka Tonga',
  },
  tr: {
    name: 'turkiska',
    nativeName: 'Türkçe',
  },
  ts: {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  tt: {
    name: 'tatariska',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  tw: {
    name: 'Twi',
    nativeName: 'Twi',
  },
  ty: {
    name: 'Tahitiska',
    nativeName: 'Reo Tahiti',
  },
  ug: {
    name: 'Uigur, Uigur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  uk: {
    name: 'ukrainska',
    nativeName: 'українська',
  },
  ur: {
    name: 'Urdu',
    nativeName: 'اردو',
  },
  uz: {
    name: 'uzbekiska',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  ve: {
    name: 'Venda',
    nativeName: 'Tshivenḓa',
  },
  vi: {
    name: 'vietnamesiska',
    nativeName: 'Tiếng Việt',
  },
  vo: {
    name: 'Volapük',
    nativeName: 'Volapük',
  },
  wa: {
    name: 'vallon',
    nativeName: 'Walon',
  },
  cy: {
    name: 'walesiska',
    nativeName: 'Cymraeg',
  },
  wo: {
    name: 'Wolof',
    nativeName: 'Wollof',
  },
  fy: {
    name: 'västfrisiska',
    nativeName: 'Frysk',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  yi: {
    name: 'jiddisch',
    nativeName: 'ייִדיש',
  },
  yo: {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  za: {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
};

const de_languages = {
  ab: {
    name: 'Abchasisch',
    nativeName: 'аҧсуа',
  },
  aa: {
    name: 'In der Ferne',
    nativeName: 'Afaraf',
  },
  af: {
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  ak: {
    name: 'Akan',
    nativeName: 'Akan',
  },
  sq: {
    name: 'albanisch',
    nativeName: 'Shqip',
  },
  am: {
    name: 'Amharisch',
    nativeName: 'አማርኛ',
  },
  ar: {
    name: 'Arabisch',
    nativeName: 'العربية',
  },
  an: {
    name: 'Aragonesisch',
    nativeName: 'Aragones',
  },
  hy: {
    name: 'Armenisch',
    nativeName: 'և',
  },
  as: {
    name: 'Assamesisch',
    nativeName: 'অসমীয়া',
  },
  av: {
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
  },
  ae: {
    name: 'Avestan',
    nativeName: 'Avesta',
  },
  ay: {
    name: 'Aymara',
    nativeName: 'aymar aru',
  },
  az: {
    name: 'Aserbaidschanisch',
    nativeName: 'azərbaycan dili',
  },
  bm: {
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  ba: {
    name: 'Baschkirisch',
    nativeName: 'башҡорт теле',
  },
  eu: {
    name: 'baskisch',
    nativeName: 'euskara, euskera',
  },
  be: {
    name: 'Belarussisch',
    nativeName: 'Weißrussland',
  },
  bn: {
    name: 'Bengali',
    nativeName: 'বাংলা',
  },
  bh: {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  bi: {
    name: 'Bislama',
    nativeName: 'Bislama',
  },
  bs: {
    name: 'bosnisch',
    nativeName: 'bosanski jezik',
  },
  br: {
    name: 'Bretonisch',
    nativeName: 'Breschoneg',
  },
  bg: {
    name: 'bulgarisch',
    nativeName: 'български език',
  },
  my: {
    name: 'birmanisch',
    nativeName: 'ဗမာစာ',
  },
  ca: {
    name: 'Katalanisch; ',
    nativeName: 'Katalonien',
  },
  ch: {
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  ce: {
    name: 'Tschetschenisch',
    nativeName: 'nächtlicher Tag',
  },
  ny: {
    name: 'Chichewa; ',
    nativeName: 'chiCheŵa, chinyanja',
  },
  zh: {
    name: 'Chinesisch',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  cv: {
    name: 'Tschuwaschisch',
    nativeName: 'чӑваш чӗлхи',
  },
  kw: {
    name: 'kornisch',
    nativeName: 'Kernewek',
  },
  co: {
    name: 'Korsisch',
    nativeName: 'Korsu, Lingua Korsa',
  },
  cr: {
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  hr: {
    name: 'kroatisch',
    nativeName: 'hrvatski',
  },
  cs: {
    name: 'Tschechisch',
    nativeName: 'česky, čeština',
  },
  da: {
    name: 'dänisch',
    nativeName: 'Danke',
  },
  dv: {
    name: 'Divehi; ',
    nativeName: 'ccidentalis',
  },
  nl: {
    name: 'Niederländisch',
    nativeName: 'Niederlande, Vlaams',
  },
  en: {
    name: 'Englisch',
    nativeName: 'Englisch',
  },
  eo: {
    name: 'Esperanto',
    nativeName: 'Esperanto',
  },
  et: {
    name: 'estnisch',
    nativeName: 'eesti, eesti Kiel',
  },
  ee: {
    name: 'Mutterschaf',
    nativeName: 'Eʋegbe',
  },
  fo: {
    name: 'Färöisch',
    nativeName: 'føroyskt',
  },
  fj: {
    name: 'Fidschi',
    nativeName: 'vosa Vakaviti',
  },
  fi: {
    name: 'finnisch',
    nativeName: 'Suomi, Suomen Kieli',
  },
  fr: {
    name: 'Französisch',
    nativeName: 'français, langue française',
  },
  ff: {
    name: 'Fula; ',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  gl: {
    name: 'galizisch',
    nativeName: 'Galego',
  },
  ka: {
    name: 'georgisch',
    nativeName: 'ქართული',
  },
  de: {
    name: 'Deutsch',
    nativeName: 'Deutsch',
  },
  el: {
    name: 'Griechisch, Modern',
    nativeName: 'Ελληνικά',
  },
  gn: {
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
  },
  gu: {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  ht: {
    name: 'Haitianisch; ',
    nativeName: 'Kreyòl Ayisyen',
  },
  ha: {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  he: {
    name: 'Hebräisch (modern)',
    nativeName: 'עברית',
  },
  hz: {
    name: 'Herero',
    nativeName: 'Otjiherero',
  },
  hi: {
    name: 'Hindi',
    nativeName: 'Ja, ja',
  },
  ho: {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  hu: {
    name: 'ungarisch',
    nativeName: 'Magyar',
  },
  ia: {
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  id: {
    name: 'Indonesisch',
    nativeName: 'Bahasa Indonesien',
  },
  ie: {
    name: 'Zwischensprache',
    nativeName: 'Ursprünglich Occidental genannt; ',
  },
  ga: {
    name: 'irisch',
    nativeName: 'Gaeilge',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  ik: {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  io: {
    name: 'Ich tue',
    nativeName: 'Ich tue',
  },
  is: {
    name: 'isländisch',
    nativeName: 'Islenska',
  },
  it: {
    name: 'Italienisch',
    nativeName: 'Italienisch',
  },
  iu: {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  ja: {
    name: 'japanisch',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  jv: {
    name: 'Javanisch',
    nativeName: 'Basa Jawa',
  },
  kl: {
    name: 'Kalaallisut, Grönländisch',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  kr: {
    name: 'Kanuri',
    nativeName: 'Kanuri',
  },
  ks: {
    name: 'Kaschmir',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  kk: {
    name: 'Kasachisch',
    nativeName: 'Қазақ тілі',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  rw: {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  ky: {
    name: 'Kirgisisch, Kirgisisch',
    nativeName: 'кыргыз тили',
  },
  kv: {
    name: 'Komi',
    nativeName: 'коми кыв',
  },
  kg: {
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  ko: {
    name: 'Koreanisch',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  ku: {
    name: 'kurdisch',
    nativeName: 'Kurdî, كوردی‎',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  la: {
    name: 'Latein',
    nativeName: 'Latein, Lingua Latina',
  },
  lb: {
    name: 'Luxemburgisch, Letzeburgisch',
    nativeName: 'Lëtzebuergesch',
  },
  lg: {
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  li: {
    name: 'Limburgisch, Limburgisch, Limburger',
    nativeName: 'Limburger',
  },
  ln: {
    name: 'Lingala',
    nativeName: 'Lingála',
  },
  lo: {
    name: 'Laotisch',
    nativeName: 'ພາສາລາວ',
  },
  lt: {
    name: 'litauisch',
    nativeName: 'lietuvių kalba',
  },
  lu: {
    name: 'Luba-Katanga',
    nativeName: '',
  },
  lv: {
    name: 'lettisch',
    nativeName: 'latviešu valoda',
  },
  gv: {
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
  },
  mk: {
    name: 'mazedonisch',
    nativeName: 'македонский јазик',
  },
  mg: {
    name: 'Madagassisch',
    nativeName: 'Madagassische Fitness',
  },
  ms: {
    name: 'malaiisch',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  mt: {
    name: 'maltesisch',
    nativeName: 'Malti',
  },
  mi: {
    name: 'Maori',
    nativeName: 'te reo Māori',
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
    nativeName: 'Ja',
  },
  mh: {
    name: 'Marshallesisch',
    nativeName: 'Kajin M̧ajeļ',
  },
  mn: {
    name: 'mongolisch',
    nativeName: 'mongol',
  },
  na: {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  nv: {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  nb: {
    name: 'Norwegisches Bokmål',
    nativeName: 'Norsk bokmål',
  },
  nd: {
    name: 'Nord-Ndebele',
    nativeName: 'isiNdebele',
  },
  ne: {
    name: 'Nepali',
    nativeName: 'Ja',
  },
  ng: {
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  nn: {
    name: 'Norwegisches Nynorsk',
    nativeName: 'Norsk Nynorsk',
  },
  no: {
    name: 'norwegisch',
    nativeName: 'Norsk',
  },
  ii: {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  nr: {
    name: 'Süd-Ndebele',
    nativeName: 'isiNdebele',
  },
  oc: {
    name: 'Okzitanisch',
    nativeName: 'Okzitanisch',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  cu: {
    name: 'Altkirchenslawisch, Kirchenslawisch, Kirchenslawisch, Altbulgarisch, Altslawisch',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  om: {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  or: {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  os: {
    name: 'Ossetisch, Ossetisch',
    nativeName: 'иRON æвзаг',
  },
  pa: {
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  pi: {
    name: 'Pali',
    nativeName: 'Ja',
  },
  fa: {
    name: 'persisch',
    nativeName: 'فارسی',
  },
  pl: {
    name: 'Polieren',
    nativeName: 'polnisch',
  },
  ps: {
    name: 'Paschtu, Paschtu',
    nativeName: 'پښتو',
  },
  pt: {
    name: 'Portugiesisch',
    nativeName: 'Portugiesisch',
  },
  qu: {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  rm: {
    name: 'Rätoromanisch',
    nativeName: 'Rumantsch Grischun',
  },
  rn: {
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  ro: {
    name: 'Rumänisch, Moldauisch, Moldauisch',
    nativeName: 'romanisch',
  },
  ru: {
    name: 'Russisch',
    nativeName: 'russisch язык',
  },
  sa: {
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  sc: {
    name: 'Sardisch',
    nativeName: 'Sardu',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  se: {
    name: 'Nordsamisch',
    nativeName: 'Davvisámegiella',
  },
  sm: {
    name: 'Samoaner',
    nativeName: 'gagana faa Samoa',
  },
  sg: {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  sr: {
    name: 'serbisch',
    nativeName: 'српски језик',
  },
  gd: {
    name: 'Schottisch Gälisch; ',
    nativeName: 'Gàidhlig',
  },
  sn: {
    name: 'Shona',
    nativeName: 'chiShona',
  },
  si: {
    name: 'Singhalesisch, Singhalesisch',
    nativeName: 'සිංහල',
  },
  sk: {
    name: 'slowakisch',
    nativeName: 'Slowenien',
  },
  sl: {
    name: 'Slowenisch',
    nativeName: 'slowenisch',
  },
  so: {
    name: 'somali',
    nativeName: 'Soomaaliga, von Soomaali',
  },
  st: {
    name: 'Süd-Sotho',
    nativeName: 'Sesotho',
  },
  es: {
    name: 'Spanisch; ',
    nativeName: 'Spanisch, Castellano',
  },
  su: {
    name: 'Sundanesisch',
    nativeName: 'Basa Sunda',
  },
  sw: {
    name: 'Suaheli',
    nativeName: 'Kisuaheli',
  },
  ss: {
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  sv: {
    name: 'Schwedisch',
    nativeName: 'svenska',
  },
  ta: {
    name: 'Tamilisch',
    nativeName: 'தமிழ்',
  },
  te: {
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  tg: {
    name: 'Tadschikisch',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  th: {
    name: 'Thailändisch',
    nativeName: 'ไทย',
  },
  ti: {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  bo: {
    name: 'Tibetischer Standard, Tibetisch, Zentral',
    nativeName: 'བོད་ཡིག',
  },
  tk: {
    name: 'Turkmenisch',
    nativeName: 'Türkmen, Turkmen',
  },
  tl: {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  tn: {
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  to: {
    name: 'Tonga (Tonga-Inseln)',
    nativeName: 'faka Tonga',
  },
  tr: {
    name: 'Türkisch',
    nativeName: 'Türkçe',
  },
  ts: {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  tt: {
    name: 'Tatarisch',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  tw: {
    name: 'Twi',
    nativeName: 'Twi',
  },
  ty: {
    name: 'Tahitianisch',
    nativeName: 'Reo Tahiti',
  },
  ug: {
    name: 'Uigur, Uigur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  uk: {
    name: 'ukrainisch',
    nativeName: 'ukrainisch',
  },
  ur: {
    name: 'Urdu',
    nativeName: 'اردو',
  },
  uz: {
    name: 'Usbekisch',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  ve: {
    name: 'Venda',
    nativeName: 'Tshivenḓa',
  },
  vi: {
    name: 'Vietnamesisch',
    nativeName: 'Tiếng Việt',
  },
  vo: {
    name: 'Volapük',
    nativeName: 'Volapük',
  },
  wa: {
    name: 'wallonisch',
    nativeName: 'Walon',
  },
  cy: {
    name: 'Walisisch',
    nativeName: 'Cymraeg',
  },
  wo: {
    name: 'Wolof',
    nativeName: 'Wollof',
  },
  fy: {
    name: 'Westfriesisch',
    nativeName: 'Frysk',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  yi: {
    name: 'Jiddisch',
    nativeName: 'ייִדיש',
  },
  yo: {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  za: {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
};

export function getLanguages(language: string): any {
  let languages: any = en_languages;
  if (language === 'sv') {
    languages = sv_languages;
  }

  if (language === 'de-DE') {
    languages = de_languages;
  }

  if (language === 'fr') {
    languages = fr_languages;
  }
  const resultArray = Object.keys(languages).map((key) => ({
    label: languages[key].name?.toLowerCase(),
    value: key,
  }));

  return resultArray;
}

export function getLanguageSpoken(code: string[], language: string): any {
  if (code && code.length === 0) {
    return '';
  }

  let languages: any = en_languages;
  if (language === 'sv') {
    languages = sv_languages;
  }

  if (language === 'de-DE') {
    languages = de_languages;
  }

  if (language === 'fr') {
    languages = fr_languages;
  }
  let languagesSpoken: string[] = [];

  code.forEach((lang) => {
    languagesSpoken.push(languages[lang]?.name || '');
  });

  return languagesSpoken && languagesSpoken.length ? languagesSpoken.join(', ') : '';
}
