import { useTranslation } from 'react-i18next';

interface InputProps {
  error: any;
  name: string;
  fieldLabel?: string;
}

export const ErrorMessage: React.FC<InputProps> = ({ error, name, fieldLabel }) => {
  const { t } = useTranslation();
  return (
    <>
      {error && error.message && (
        <span className="text-danger text-start">{t(error.message).replace('{min}', '8')}</span>
      )}
    </>
  );
};
