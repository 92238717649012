import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useContainerContext } from '../../context/ContainerContext';
import { FormTrip } from './components/FormTrip';

const CreateUpdateTrip: React.FC<{ edit?: boolean }> = ({ edit = false }) => {
  const { t } = useTranslation();
  const { state } = useContainerContext();
  const { id } = useParams();

  const action = edit ? 'edit' : id ? 'copy' : 'create';

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12" style={{ margin: 'auto' }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                {action === 'edit'
                  ? t('trip.update_trip')
                  : action === 'create'
                  ? t('trip.create_trip')
                  : t('trip.copy_trip')}
              </h5>
            </div>
            <div className="card-body">
              <FormTrip action={action} guideId={state.guideId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateTrip;
