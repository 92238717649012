import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Review from '../Review';
import ReviewsContext from '../ReviewsProvider';
import StarPicker from '../StarsRating';

const Reviews = () => {
  const { t } = useTranslation();
  const {
    reviewsCount,
    ratingBreakdown,
    reviews,
    userReview,
    loadMore,
    loadingReviews,
    averageScore,
  } = useContext(ReviewsContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [reviewsJSX, setReviewsJSX] = useState<React.ReactNode[] | null>(null);
  const [userReviewJSX, setUserReviewJSX] = useState<React.ReactNode>(null);
  useEffect(() => {
    setReviewsJSX(
      reviews.map((review) => {
        return <Review data={review} key={review.id} />;
      })
    );
  }, [reviews]);
  useEffect(() => {
    if (userReview) {
      setUserReviewJSX(
        <>
          <p className="fw-bold mb-1">{t('rating.your_review')}:</p>
          <Review data={userReview} />
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReview]);

  const loadMoreReviews = async () => {
    setLoadingMore(true);
    await loadMore();
    setLoadingMore(false);
  };
  
  return (
    <div>
      {loadingReviews ? (
        <p className="small fs-6">{t('rating.loading_review')}</p>
      ) : reviews.length > 0 || userReview ? (
        <>
          <div className="row justify-content-md-center">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="rating-block mx-3">
                <h6>{t('rating.average_user_rating')}</h6>
                <h2 className="bold padding-bottom-7">
                  {parseFloat(averageScore.toString()).toFixed(2).replace(/\.00$/, '')}{' '}
                  <small>/ 5</small>
                </h2>
                <StarPicker disabled={true} halfStars={true} value={averageScore} key="strapicker" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="rating-block">
                <h6>{t('rating.rating_breakdown')}</h6>
                <br />

                {ratingBreakdown.map((rating: any,index: number) => (
                  <div className="pull-left" key={'ratingBreakdown-'+index}>
                    <div className="pull-left" style={{ width: '35px', lineHeight: '1' }}>
                      <div style={{ height: '9px', margin: '5px 0' }}>
                        {rating.score}
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="pull-left" style={{ width: '180px' }}>
                      <div className="progress" style={{ height: '9px', margin: '8px 0' }}>
                        <div
                          className="progress-bar bg-secondary"
                          role="progressbar"
                          style={{
                            width: `${(parseInt(rating.count) / reviewsCount) * 100}%`,
                          }}
                        >
                          <span className="sr-only">80% Complete (danger)</span>
                        </div>
                      </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px' }}>
                      {rating.count}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="row">
              <div className="col-sm-12 p-5">
                <div className="review-block">
                  {userReviewJSX}
                  {reviewsJSX}
                </div>
              </div>
            </div>
            {reviews.length > 0 &&
              reviews.length < (userReview ? reviewsCount - 1 : reviewsCount) && (
                <button
                  className="btn btn-primary"
                  onClick={loadMoreReviews}
                  disabled={loadingMore ? true : undefined}
                >
                  {t('rating.load_more_review')}
                </button>
              )}
          </div>
        </>
      ) : (
        <p className="small fs-6">{t('rating.no_review')}</p>
      )}
    </div>
  );
};

export default Reviews;
