import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/sv';

const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';
if (selectedLanguage === 'en') moment.locale('en-gb');
else if (selectedLanguage === 'de-DE') moment.locale('de');
else moment.locale(selectedLanguage);

export default moment;
