import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import * as _ from 'lodash';
import { range } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import i18n from '../../i18n';
import { useGetAvailableTripCountry } from '../../services/Search/searchService';
import { getCountry } from '../../services/countries';
import { genericApi } from '../../services/genericApi';
import { IDropDownOptions } from '../../services/utils';
import { AsyncSelect } from '../Form';
import StaticDataSelectField from '../Form/StaticDataSelectField';

interface pageProps {
  wrapperClass: string;
  showlabels: boolean;
  buttonText: string;
  inputWrapperClass: string;
  onSearchChange?: any;
}
interface Option {
  value: string;
  label: string;
  obj?: any;
}

const schema = yup.object().shape({
  search: yup.string().required(),
  noOfGuest: yup.mixed().required(),
});

function SearchForm(props: pageProps) {
  const { t } = useTranslation();
  const param = useParams();
  const [city, setCity] = React.useState<string>(param.city || '');
  const [countryCode, setcountryCode] = React.useState<string>(param.countryCode || '');
  const [passenger, setpassenger] = React.useState<number>(Number(param.noofpassanger) || 1);
  const [placeId, setPlaceId] = React.useState<string>(param.placeId || '');
  const [center, setCenter] = React.useState<[number, number]>([
    17.6754094331351, 64.9648751621697,
  ]);
  const [bbox, setBbox] = React.useState<[number, number, number, number]>([
    10.8364783, 55.2807139, 24.1933675, 69.059926,
  ]);
  const { data: getAvailableTripCountry, isLoading: isGetAvailableTripCountryLoading } =
    useGetAvailableTripCountry();

  const navigate = useNavigate();

  let passengerOptions: IDropDownOptions[] = range(1, 50, 1).map((option: number, i: number) => {
    return {
      value: option.toString(),
      label: option <= 1 ? `${option} ${t('common.guest')}` : `${option} ${t('common.guests')}`,
    };
  });

  const loadCityOptions = React.useCallback(
    (value: string) => {
      return new Promise<Option[]>((resolve) => {
        if (getAvailableTripCountry) {
          const countrylist = getAvailableTripCountry
            ? getAvailableTripCountry
                .map(function (obj: any) {
                  return obj.country;
                })
                .join(',')
            : 'se';
          const data = genericApi.getAnyMapboxLocation(value, countrylist).then((result) => {
            return _.map(result, (obj: any) => ({
              label: obj[`place_name_` + i18next.language],
              value: obj.place_name,
              obj: obj,
            }));
          });
          resolve(data);
        } else {
          resolve([]);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18next.language, getAvailableTripCountry]
  );

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      search: placeId,
      noOfGuest: passenger,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = methods;

  const searchValue = watch('search');
  React.useEffect(() => {
    const fetchData = async () => {
      if (searchValue && getAvailableTripCountry) {
        if (props.onSearchChange) {
          try {
            const result = await genericApi.getAnyMapboxLocation(
              searchValue,
              getAvailableTripCountry
                .map(function (obj: any) {
                  return obj.country;
                })
                .join(',')
            );
            props.onSearchChange(result[0][`place_name_` + i18next.language]);
          } catch (error) {
            props.onSearchChange(searchValue);
          }
        }
      }
    };

    fetchData(); // Call the fetchData function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, i18next.language, getAvailableTripCountry]);

  function handlePlace(data: any) {
    const place = data.obj;

    methods.trigger().then(() => {
      const obj = parsePlace(place);
      setCenter(place.center);
      setBbox(place.bbox);
      setPlaceId(place.place_name);
      setCity(obj?.city?.text);
      setcountryCode(obj?.country?.short_code || obj?.country?.properties.short_code);
    });
  }

  function parsePlace(place: Place) {
    let country: any | undefined = undefined;
    let city: any | undefined = undefined;
    function getContextData(context: any, type: string) {
      return context?.find((item: any) => item.id.startsWith(type));
    }

    if (place?.context) {
      city = getContextData(place.context, 'district');
      country = getContextData(place.context, 'country');
    }

    if (place?.id?.startsWith('place')) {
      city = place;
    }
    if (place?.id?.startsWith('country')) {
      country = place;
    }

    return {
      city,
      country,
    };
  }

  const onSubmit = (data: any) => {
    reset({ search: placeId, noOfGuest: passenger });

    if (city)
      navigate(
        `/search/${placeId}/${center[0]}/${center[1]}/${bbox[0]}/${bbox[1]}/${bbox[2]}/${bbox[3]}/${passenger}/${countryCode}/${city}`
      );
    else
      navigate(
        `/search/${placeId}/${center[0]}/${center[1]}/${bbox[0]}/${bbox[1]}/${bbox[2]}/${bbox[3]}/${passenger}/${countryCode}`
      );
  };

  const onErrors = (data: any) => {
    //console.log(data);
  };

  const onNoOfGuestChange = (data: any) => {
    setpassenger(data.value);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
        <div className={props.wrapperClass}>
          <div className="row">
            <div className={props.inputWrapperClass}>
              <div className="form-group">
                <AsyncSelect
                  className="form-control"
                  label={t('search_trips.fields.location.label').toString()}
                  name={`search`}
                  placeholder={t('search_trips.fields.location.placeholder')}
                  loadOptions={loadCityOptions}
                  onChange={handlePlace}
                  menuPortalTarget={null}
                  showErrorLabel={false}
                />
              </div>
            </div>
            <div className={props.inputWrapperClass}>
              <div className="form-group cd-select cd-select cd-filters">
                <StaticDataSelectField
                  name={'noOfGuest'}
                  className="form-control"
                  placeholder="No of guest"
                  label={t('search_trips.fields.passenger.label').toString()}
                  options={passengerOptions}
                  onChange={onNoOfGuestChange}
                  menuPortalTarget={null}
                  showErrorLabel={false}
                />

                <span className="select-arrow" />
              </div>
            </div>
            <div className={props.inputWrapperClass}>
              <div className="form-group">
                {props.showlabels && <span className="form-label">&nbsp;</span>}
                <div style={{ marginTop: '8.5px' }}>
                  <button
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || !isDirty}
                    className={`fbkr-button btn-gold }`}
                  >
                    {props.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isGetAvailableTripCountryLoading && (
            <>
              {t('home_page.available_trip')}{' '}
              {getAvailableTripCountry &&
                getAvailableTripCountry
                  .map(function (obj: any) {
                    return getCountry(obj.country, i18n.language);
                  })
                  .join(', ')}
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

export default SearchForm;
