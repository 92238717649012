export const ButtonSpinner = (props: any) => {
  const { message = ' Loading...' } = props;

  return (
    <div className="text-center">
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {` ${message}`}
    </div>
  );
};
