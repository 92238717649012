import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { queryClient } from './/lib/react-query';
import App from './App';
import './components/Button/style.css';
import './components/ConfirmBox/style.css';
import { Spinner } from './components/Spinner/Spinner';
import './components/Upload/style.css';
import './i18n';
import './index.css';
import { AuthLoader } from './lib/auth';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './services/registerServiceWorker';
import './styles/main.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// save records in cache until they are stale (Infinity)

root.render(
  <>
    <React.StrictMode>
      <Suspense fallback={<Spinner className="loader-absolute" />}>
        <QueryClientProvider client={queryClient}>
          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
          )}
          <AuthLoader renderLoading={() => <Spinner className="loader-absolute" />}>
            <Router>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </Router>
          </AuthLoader>
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>
    <NotificationContainer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
