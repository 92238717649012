import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { TextField } from '../../../components/Form';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import { ChangePassword, changePasswordSchema } from '../../../services/Guide/guide';
import { useChangePassword } from '../../../services/User/userService';

const ChangePasswordScreen = () => {
  const { t } = useTranslation();

  const { mutateAsync: mutateAsyncPost, isLoading: mutateAsyncPostLoading } = useChangePassword();

  const methods = useForm<ChangePassword>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver<ChangePassword>(changePasswordSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmitData = (data: ChangePassword) => {
    mutateAsyncPost(data, {
      onSuccess: () => {
        reset();
        NotificationManager.success(t('notification.password.updated'));
      },
    });
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  return (
    <div className="card card-user">
      <div className="card-header">
        <h5 className="card-title">{t('change_password_page.title')}</h5>
      </div>
      <div className="card-body">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitData, onErrors)} noValidate>
            <div className="row">
              <div className="form-group col-md-12">
                <TextField
                  name={`currentPassword`}
                  placeholder={t('change_password_page.fields.old_password.label')}
                  label={t('change_password_page.fields.old_password.label')}
                  type="password"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <TextField
                  name={`newPassword`}
                  placeholder={t('change_password_page.fields.new_password.label')}
                  label={t('change_password_page.fields.new_password.label')}
                  type="password"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <TextField
                  name={`confirmPassword`}
                  placeholder={t('change_password_page.fields.confirm_password.label')}
                  label={t('change_password_page.fields.confirm_password.label')}
                  type="password"
                />
              </div>
            </div>

            <div className="row mt-1">
              <div className="form-group col-md-12">
                <div className="mt-2 mb-3 text-center">
                  <button
                    type="submit"
                    className="next btn btn-gold btn-round text-uppercase"
                    disabled={mutateAsyncPostLoading}
                  >
                    {mutateAsyncPostLoading ? (
                      <ButtonSpinner message={t('common.updating')} />
                    ) : (
                      t('change_password_page.change_password_button')
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
