import classnames from 'classnames';
import * as React from 'react';

import './defaultStarRenderer.css';

const defaultStarRenderer = (StarRendererProps: any): React.ReactNode => {
  const { index, hoverIndex, selectedIndex } = StarRendererProps;
  const selected = index <= selectedIndex;
  const hoverSelected = hoverIndex ? index <= hoverIndex : false;

  if (selected || hoverSelected) {
    return <div className={classnames('DefaultStarComponent')}>{String.fromCharCode(9733)}</div>;
  }
  return <div className={classnames('DefaultStarComponent')}>{String.fromCharCode(9734)}</div>;
};

export default defaultStarRenderer;
